// import EventEmitter from 'events';
import similarity from 'cosine-similarity';
// import skmeans from 'skmeans';

import { ServerStore } from 'utils/ServerStore.js';

// Copied because I'm lazy
const getRandomTime = () => {
	const times = [ 200, 300, 5000, 400, 500, 600, 250, 333, 444, 555, Math.random() * 750 + 50 ];
	return times[Math.floor(Math.random() * times.length)];
}

// Versioning for comparing old memories to new formatted inputs
const INPUT_FMT_VERSION = 1.0;

// Engagement - GA reports avg session roughly 35sec over 12/7-12/8/19
// So count this as one session...
const AVG_SESSION_LENGTH = 30 * 1000;

// At this number, the internal memory store will be pruned
const MAX_LOCAL_MEMORY_LENGTH = 100;

// At this number, the local batch buffer will be sent to the server over websocket
const SERVER_BATCH_SIZE = 10;

/**
 * Utility class to average numbers with a rolling window
 */
class RollingAvg {
	static DEFAULT_SIZE = 10;
	constructor(size = RollingAvg.DEFAULT_SIZE, debugKey) {
		this.data = new Array(size || RollingAvg.DEFAULT_SIZE).fill(0);
		this.debugKey = debugKey || '[RollingAvg]';
	}

	add(value) {
		this.data.push(value);
		this.data.shift();
		this.avg = this.average();
		// console.log(`[RollingAvg.add] [${this.debugKey}] added '${value}', new avg=${this.avg}`);
		return this.avg;
	}

	average() {
		const  sum = this.data.reduce((sum, d) => sum += d, 0);
		return sum / this.data.length;
	}
}

/**
 * Extends the `RollingAvg` class with a timer
 */
class RollingAvgTimer extends RollingAvg {
	start() {
		this.last = Date.now();
	}

	stop() {
		if(!this.last)
			return null;
		this.now    = Date.now();
		const delta = this.now - this.last;
		this.last   = null;
		return this.add(delta);
	}

	tick() {
		if(this.last) {
			this.stop();
		}
		this.start();
	}
}

/**
 * Adapter to connect the dirty details of the game to the "pristine" brain
 */
export class BrainBoxEnvAdapter {
	constructor(sassyBox) {
		this.box = sassyBox;
		this.brain = null;

		const avgLength = 30; // e.g number of interactions to avg

		this.timeOff         = new RollingAvgTimer(avgLength, 'timeOff');
		this.timeOn          = new RollingAvgTimer(avgLength, 'timeOn');
		this.userTouchTimer  = new RollingAvgTimer(avgLength, 'userTouchTimer');
		this.avgUserTurnoffs = new RollingAvg(avgLength, 'avgUserTurnoffs');
		this.avgMalletHits   = new RollingAvg(avgLength, 'avgMalletHits');
		
		// Measure Engagement as # of avg sessions
		this.sessionTime  = { start: Date.now(), length: 0 };
		setInterval(() => this.sessionTime.length = Date.now() - this.sessionTime.start, 1000);
	}

	setBrain(brain) {
		this.brain = brain;
	}

	static ENV_LEVER_ON = 'lever_on';
	static ENV_LEVER_OFF = 'lever_off';
	static ENV_MALLET_HIT = 'mallet_hit';
	static ENV_LEVER_TOUCHED = 'lever_touched';
	static ENV_LEVER_USER_TURNOFF = 'user_turnoff';

	changeNotify(key, value=null) {
		// console.log(`[BrainBoxEnvAdapter.changeNotify] key=${key}, value=${value}`);
		switch(key) {
			case BrainBoxEnvAdapter.ENV_LEVER_OFF:
				this.timeOff.start();
				this.timeOn.stop();
				break;
			
			case BrainBoxEnvAdapter.ENV_LEVER_ON:
				this.timeOn.start();
				this.timeOff.stop();
				break;

			case BrainBoxEnvAdapter.ENV_MALLET_HIT:
				this.avgMalletHits.add(value ? 1 :0);
				break;

			case BrainBoxEnvAdapter.ENV_LEVER_TOUCHED:
				this.userTouchTimer.tick();
				if (this.brain)
					this.brain.endInputChangeAttribution();
				break;
			
			case BrainBoxEnvAdapter.ENV_LEVER_USER_TURNOFF:
				this.avgUserTurnoffs.add(value ? 1 : 0);
				break;			

			default:
				throw new Error("Uknown env key: " + key);
		}

		if (this.brain)
			this.brain.inputsChanged();	
	}

	getInputs() {
		/*
			Possible inputs/states:
			- Lever on/off, animating?/direction
			- avg time between user touches
			- avg user turn offs 
			- avg mallet hits
		*/

		const avgUserTouch    = Math.min(1, this.userTouchTimer.avg  / 1000);
		const avgUserTurnoffs = Math.min(1, this.avgUserTurnoffs.avg / 1000);
		const avgMalletHits   = Math.min(1, this.avgMalletHits.avg   / 1000);
		const sessionCounter  = Math.min(1, this.sessionTime.length  / AVG_SESSION_LENGTH);

		const leverState  = this.box.sprites.lever.isActive,
			anim          = this.box.sprites.mallet.animator,
			running       = anim.isRunning(),
			timeRange     = running ? anim.timeRange : { start: 0, end: 1 },
			delta         = timeRange.end - timeRange.start,
			leverProgress = running ? delta * anim.currentTime : 0;

		const inputs = [
			INPUT_FMT_VERSION,
			leverState ? 1 : 0,
			leverProgress,
			avgUserTouch,
			avgUserTurnoffs,
			avgMalletHits,
			sessionCounter,
		].map(x => isNaN(x) ? 0 :x);

		// console.warn(`[BrainBoxEnvAdapter.getInputs] inputs=`, inputs);
		return inputs;
	}

	getReward() {
		/*
			Reward tentatively defined as:
			(t1 - t2)/(t1 + t2) = avg time on per second
			Where:
				t1 = avg lever off time
				t2 = avg lever on time
			So if t1 = 230 sec and t1 = 38 sec
				(t1 - t2)/(t1 + t2) = 192 / 238 = 0.716417910447761
		*/

		const { timeOff: { avg: t1 }, timeOn: { avg: t2 } } = this;
		return Math.sqrt(Math.pow( t1 - t2, 2)) / ( t1 + t2 );
	}

	static SPEC_MALLET = 'mallet';
	static SPEC_NO_MALLET = 'no_mallet';
	static SPEC_WAVE_FLAG = 'wave_flag';
	static SPEC_SHAKE = 'shake';
	static SPEC_PARTIAL = 'partial_mallet';

	static actionSpecs() {
		const list =  [
			// {
			// 	id: BrainBoxEnvAdapter.SPEC_NO_MALLET,
			// 	parameters: [],
			// },
			// {
			// 	id: BrainBoxEnvAdapter.SPEC_PARTIAL,
			// 	weight: 1,
			// 	params: [
			// 		{
			// 			name: 'length',
			// 			range: [ 100, 10000 ],
			// 		},
			// 		{
			// 			name: 'dist',
			// 			range: [ 0.1, 1 ],
			// 		},
			// 	],
			// },
			{
				id: BrainBoxEnvAdapter.SPEC_MALLET,
				weight: 97,
				params: [
					{
						name: 'length',
						range: getRandomTime
					}
				]
			},
			{
				id: BrainBoxEnvAdapter.SPEC_WAVE_FLAG,
				weight: 3,
				params: [
					{
						name: 'length',
						range: [ 700, 3000 ],
					}
				]
			},
			// {
			// 	id: BrainBoxEnvAdapter.SPEC_SHAKE,
			// 	weight: 1,
			// 	params: [
			// 		{
			// 			name: 'length',
			// 			range: [ 100, 3000 ],
			// 		}
			// 	]
			// },
		];
		list.lookup = {};
		list.forEach(spec => list.lookup[spec.id] = spec);
		return list;
	}

	applyAction(action) {

		// console.log(`[BrainBoxEnvAdapter.applyAction] action=`, action);
		
		const { id, ...params } = action;

		switch (id) {
			case BrainBoxEnvAdapter.SPEC_NO_MALLET:
				// NOTHING
				break;
			case BrainBoxEnvAdapter.SPEC_PARTIAL:
				this.box.doMalletAttack(params.length, { start: 0, end: params.dist || 1 })
				break;
			case BrainBoxEnvAdapter.SPEC_MALLET:
				this.box.doMalletAttack(params.length);
				break;
			case BrainBoxEnvAdapter.SPEC_WAVE_FLAG:
				this.box.doFlagRaising(params.length);
				break;
			case BrainBoxEnvAdapter.SPEC_SHAKE:
				this.box.shakeAnim(params.length);
				break;
			default:
				throw new Error("Invalid spec, unknown id: " + id);
		}
	}

	async loadMemory() {
		return ServerStore.LoadMemory();
	}

	// Archive on the server for sharing with other boxes and reloading in future
	persistMemory(engram) {
		if(!this._serverBatch) {
			this._serverBatch = [];
		}
		this._serverBatch.push(engram);

		if(this._serverBatch.length >= SERVER_BATCH_SIZE) { // arbitrary number
			const batch = this._serverBatch.slice();
			this._serverBatch = [];

			// Post over websocket back to the server
			ServerStore.EngramBatch(batch);

			// TODO: Received processed engrams to update our memory
		}
	}
}

/**
 * Actual AI logic. Think of it as the "prefrontal cortex" of our box.
 */
export class BrainBox {

	constructor(envAdapter) {
		if(!(envAdapter instanceof BrainBoxEnvAdapter)) {
			throw new Error("Invalid envAdapter " + envAdapter);
		}

		this.memory = [];

		// Connect adapter
		this.env = envAdapter;

		// For future use: Notifying of change outside of tick()
		envAdapter.setBrain(this);

		// Setup reward
		this.reward = new RollingAvg(100, 'reward');

		// Load memory from server (separate function because constructor can't be async)
		this.loadMemory();
	}

	async loadMemory() {
		// Load engrams from the server...
		// Todo: Use kmeans clustering to prune/collapse engrams
		this.memory = await this.env.loadMemory();
	}

	applyAction(action) {
		this.env.applyAction(action);

		// Stored so we can reward later
		const engram = {
			action,
			inputs:    this.env.getInputs(),
			reward:    null,
			newInputs: null
		};
		
		// So we can update our memory (e.g. change newInput) with changes between ticks()
		// and attribute them to the result of the actions in this engram
		this.lastEngram = engram;
	}

	applyReward(reward) {
		if(!isNaN(reward)) {

			// Update our engram with reward and input snapshot
			this.lastEngram.reward = reward;
			this.lastEngram.newInputs = this.env.getInputs();

			// Archive locally - this .memory archive is what we use for decision making
			this.memory.push(this.lastEngram);
			while(this.memory.length >= MAX_LOCAL_MEMORY_LENGTH) { // arbitrary memory level
				this.memory.shift();
			}

			// Archive on the server for sharing with other boxes and reloading in future
			this.env.persistMemory(this.lastEngram);
		
			// Sum up reward
			const r = this.reward.add(reward);
			console.log(`[Brain.applyReward] reward=${reward}, new r=${r}, stored engram: `, this.lastEngram);
		}

		// Reset action
		this.lastEngram = null;
	}


	// Called by BrainBoxEnvAdapter.changeNotify
	inputsChanged() {

		// Update input attribution on last engram
		if (this.lastEngram) {
			this.lastEngram.newInputs = this.env.getInputs();

			// console.log(`[Brain.inputsChanged] lastEngram updated, engram:`, this.lastEngram);
		}
	}

	endInputChangeAttribution() {
		if (this.lastEngram)
			this.applyReward(this.env.getReward());
	}

	tick() {
		this.endInputChangeAttribution();
		const inputs = this.env.getInputs(),
			action   = this.chooseActions(inputs);

		this.applyAction(action);
	}

	chooseActions(inputs) {
		const engrams = this.findSimilarEngrams(inputs);
		return this.chooseOptimalActions(engrams);
	}

	findSimilarEngrams(currentInputs) {

		// Arbitrary max number of engrams to return
		const MAX = 5;

		// Used to sort for the purpose of keeping the top-most similar each time we find a match
		const simComp = (a,b) => b.s - a.s;

		// 'let' not 'const' because we reassign when sorting
		let similar = [];

		// Search our local memory store for similar inputs using cosine similarity matching
		// Fix for https://sentry.io/organizations/sassybox/issues/1390397805
		(this.memory || []).forEach(engram => {
			const { inputs: previousInputs } = engram;
			const s = similarity(currentInputs, previousInputs);

			if (similar.length < MAX) {
				similar.push({ s, engram });
				similar = similar.sort(simComp);
			} else 
			if(similar[similar.length-1].s < s) {
				similar.pop();
				similar.push({ s, engram });
				similar = similar.sort(simComp);
			}
		});

		// console.log(`[findSimilarEngrams] found similar:`, similar, currentInputs);
		
		// Remove the similarity value since not needed and instead 
		// return a simple list of engrams sorted by the reward value (with the highest reward at the top/index 0)
		similar = similar.map(d => d.engram).sort((a, b) => b.reward - a.reward);

		return similar;
	}

	chooseOptimalActions(engrams=[]) {
		engrams = Array.from(engrams || []);
		// console.log(`[chooseOptimalActions] received engrams:`, engrams);

		if(!engrams.length || Math.random() > 0.625) { //} || engrams[0].reward < 0.5 * Math.random()) {
			console.warn(`[chooseOptimalActions] going with random`);
			return this.randomAction();
		} else {
			console.warn(`[chooseOptimalActions] choosing action:`, engrams[0]);
			return this.buildActionFromEngram(engrams[0]);
		}
	}

	buildActionFromEngram(engram) {
		// placeholder for now, just return engram
		return engram.action;
	}

	_getRandomActionSpec() {
		// Based on https://stackoverflow.com/questions/43566019/how-to-choose-a-weighted-random-array-element-in-javascript
		const input = BrainBoxEnvAdapter.actionSpecs();
		const array = []; // Just Checking...
		input.forEach(item => {
			for(let i=0; i<item.weight; i++ ) {
				array.push(item);
			}
		});

		// Probability Fun
		return array[Math.floor(Math.random() * array.length)];
	}

	randomAction() {
		// First, decide on the action to use
		const spec = this._getRandomActionSpec();

		// Next, build up the parameters based on the spec chosen
		const params = {};
		(spec.params || []).forEach(paramSpec => {
			const { name, range } = paramSpec;
		
			if(typeof(range) === 'function') {
				params[name] = range();
			} else {
				const [ start, end ] = range,
					rangeSize = end - start;

				// TODO: Support other types of random actions other than simple continuous ranges, such as categorical items, etc
				let value = Math.random() * rangeSize + start;

				// Normalize decimals
				if (Math.round(end) === end && Math.round(start) === start)
					value = Math.round(value);

				params[name] = value;
			}
		});

		// End object will be like: { id: "mallet", length: 500 }
		return { id: spec.id, ...params }
	}
}