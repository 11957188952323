import React from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashRouter as Router, Route } from 'react-router-dom';

import Routes from 'pages';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';
import { SassyBoxCoreService } from 'utils/SassyBoxCoreService';
// import KittyGameView from './components/KittyGameView';

// Used by utils/normalize-link
window._reactRoutePrefix = '#';

// Add custom Alert as a react-enabled swal instance
window.Swal = Swal;
window.AlertManager = withReactContent(Swal);
window.Alert = opts => window.AlertManager.fire(opts);

export default function App() {
	setStatusBarColor('#000000'); // top color in gradient 

	// Allow boot of the core service to continue after first render
	// (Has no effect if already booted)
	React.useEffect(() => SassyBoxCoreService.unlockBoot());

	// Render our app
	return (<>
		<Router>
			{Object.keys(Routes).map(path => <Route 
				key       = {path}
				path      = {path}
				component = {Routes[path].component}
				exact     = {Routes[path].exact}/>
			)}
		</Router>
		{/* <KittyGameView/> */}
	</>);
}
