export const DefaultTextStyles = {
	// This font is loaded in the react <KittyGameView> component
	fontFamily: "Dimbo-Regular, Arial",
	fontSize: 24,
	fill: "white",
	stroke: '#000000',
	strokeThickness: 1,
	dropShadow: true,
	dropShadowColor: "#000000",
	dropShadowBlur: 5,
	dropShadowAngle: Math.PI / 6,
	dropShadowDistance: 3,
};

