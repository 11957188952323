import React from 'react';
import { ServerStore }      from 'utils/ServerStore';
import { numberWithCommas } from 'utils/numberWithCommas';
import { mobileDetect }     from 'utils/mobile-detect';
import { ButtonGroup, Button } from 'reactstrap';

import './friends-widget.scss';

export const PENDING_COUNT_CHANGE_EVENT = 'friends.pending_count.changed';


function firstName(name) {
	const [ firstName ] = (name + "").split(/\s+/);
	if(firstName) {
		return firstName;
	} else {
		return "there";
	}
}


export class FriendsWidget extends React.Component {
	constructor() {
		super();
		this.state = {
			list: [],
			searchFriends: null,
			pending: true
		}
	}
	async componentDidMount() {
		this.refreshList();
	}

	async refreshList() {
		const list = await ServerStore.server().get('/friends', null, { autoRetry: true });
		if(Array.isArray(list))
			this.setState({ list, pending: false });
		else
		if(list.error)
			console.error(list);
	}

	async requestRespond(friend, response='approve') {
		this.setState({ pending: true });
		const res = await ServerStore.server().post('/friends/respond', {
			...friend,
			response
		}, { autoRetry: true });
		this.refreshList();
		
		// Give UI time to update
		if(res.error)
			setTimeout(() => alert(res.message), 100);

		ServerStore.emit(PENDING_COUNT_CHANGE_EVENT);
	}

	async requestDelete(friend) {
		this.setState({ pending: true });
		const res = await ServerStore.server().delete('/friends/' + friend.friendId, null, { autoRetry: true });
		this.refreshList();
		
		// Give UI time to update
		if(res.error)
			setTimeout(() => alert(res.message), 100);

		ServerStore.emit(PENDING_COUNT_CHANGE_EVENT);
	}

	async searchFriends(query) {
		try {
			// this.setState({ pending: true });
			const searchResults = await ServerStore.server().get('/friends/find', { query }, { autoRetry: true });
			if(!searchResults.error)
				this.setState({ query, searchResults, isPending: false })
			// this.refreshList();
			
			// Give UI time to update
			if(searchResults.error)
				setTimeout(() => alert(searchResults.message), 100);
		} catch(err) {
			console.warn("Caught error while trying to search:", query, err);
		}
	}

	async sendRequest(friend) {
		this.setState({ pending: true, finderOpen: false });
		const res = await ServerStore.server().post('/friends/request', friend, { autoRetry: true });
		this.refreshList();
		
		// Give UI time to update
		if(res.error)
			setTimeout(() => alert(res.message), 100);

		ServerStore.emit(PENDING_COUNT_CHANGE_EVENT);
	}

	render() {
		// .content class to get scrollable
		return <div className="friends-widget content">
			<div className={"fw-padding " + (mobileDetect.hasNotch ? " --hasNotch" : "")}>
				<h1>
					Friends

					{/* Span around button so button doesn't fill height due to flex */}
					<span>
						{this.state.finderOpen ? 
							<Button size="sm" color="secondary" onClick={() => this.setState({ finderOpen: false })}>
								Close Search
							</Button>
							:
							<Button size="sm" color="secondary" onClick={() => this.setState({ finderOpen: true, searchResults: [] })}>
								Find/Add ...
							</Button>
						}
					</span>
				</h1>
				{this.state.finderOpen && <>
					<input placeholder="Search by name, email, phone ..." type="search"
						className="form-control"
						ref={ref => ref && ref.focus()}
						onKeyUp={e  => this.searchFriends(e.target.value)}
						onChange={e => this.searchFriends(e.target.value)}
					/>
					{this.state.searchResults && 
						<table className="table table-condensed">
							<tbody>
							{(this.state.searchResults||[]).map(friend =>
								<tr key={friend.ident}>
									<td className="person approval-btns">
										{friend.name}
									</td>
									<td className="approval-btns">
										<Button color="secondary" className="decline" size="sm"
											onClick={() => this.sendRequest(friend)}>Send Friend Request</Button>
									</td> 
								</tr>
							)}
							{(!this.state.searchResults || !this.state.searchResults.length) && this.state.query ?
								<tr>
									<td colSpan="99" className="person">Sorry, couldn't find anybody matching that.</td>
								</tr>
								: <></>
							}
							</tbody>
						</table>
					}
				</>}

				{this.state.pending ? <b>Loading...</b> : 
					<table className="table table-condensed">
						<thead>
							<tr>
								<th className="person">
									Friend
								</th>
								<th>
									Points
								</th>
								<th>
									Level
								</th>
								{/* <th>
									Time
								</th> */}

							</tr>
						</thead>
						<tbody>
						{(this.state.list||[]).map(friend =>
							<tr key={friend.friendId+friend.cat}>
								
								{friend.approvalNeeded === "1" ?  // yes, string
									(friend.requestDirection === 'from-me' ? 
										<>
											<td className="waiting-person" colSpan="2">
												{friend.otherPerson}<br/>
												<small className="friend-req-pending">Waiting on their response...</small>
											</td>
											<td className="approval-btns">
												<Button color="secondary" className="decline" size="sm"
													onClick={() => this.requestDelete(friend)}>Cancel</Button>
											</td> 
										</> :
										<>
											<td className="person">
												{friend.otherPerson}<br/>
												<small className="friend-req-notify">Sent a Friend Request!</small>
											</td>
											<td colSpan="99" className="approval-btns">
												<ButtonGroup>
													<Button color="primary" className="approve" size="sm"
														onClick={() => this.requestRespond(friend, 'approve')}>Approve</Button>
													<Button color="secondary" className="decline" size="sm"
														onClick={() => this.requestRespond(friend, 'decline')}>X</Button>
												</ButtonGroup>
											</td> 
										</>
									)
									: <>
										<td className="person">
											{firstName(friend.otherPerson)}
											{/* {friend.cat}<br/>
											<small>{firstName(friend.otherPerson)}</small> */}
										</td>
										<td>
											{numberWithCommas(friend.points)}
										</td>
										<td>
											{friend.levelNum} ({friend.worldName})
											{/* {friend.worldName} S{friend.worldNum} L{friend.levelNum} */}
										</td>
									</>
								}
								{/* <td>
									{friend.minutes}
								</td> */}
							</tr>
						)}
						</tbody>
					</table>
				}
			</div>
		</div>
	}
}