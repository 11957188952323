import {Howl} from 'howler';

import AssetLoader from './AssetLoader';

import s_jump         from '../assets/sounds/prod/jump.webm';
// import musicSound from '../../assets/sounds/prod/music.webm';
import s_shot         from '../assets/sounds/prod/Laser-shot.webm';
import s_score        from '../assets/sounds/prod/coin-up.webm';
import s_health       from '../assets/sounds/prod/coin2.webm';
import s_clink        from '../assets/sounds/prod/coin-glass.webm';
import s_btn1         from '../assets/sounds/prod/button1.webm';
import s_btn2         from '../assets/sounds/prod/button2.webm';
import s_btn12        from '../assets/sounds/prod/button1-2.webm';
import s_achievement  from '../assets/sounds/prod/achievement.webm';
import s_achievement2 from '../assets/sounds/prod/achievement2.webm';
import s_start1       from '../assets/sounds/prod/game-start.webm';
import s_start2       from '../assets/sounds/prod/start.webm';
import s_positive     from '../assets/sounds/prod/positive.webm';
import s_beep         from '../assets/sounds/prod/tone-beep.webm';
import s_cowbell      from '../assets/sounds/prod/cowbell.webm';

// List as "music" so it loops automatically
import s_music_thruster from '../assets/sounds/prod/thruster.webm';

import s_music_ambient from '../assets/sounds/prod/music-ambient.webm';
import s_music_forest  from '../assets/sounds/prod/music-forest.webm';
import s_music_8bit1   from '../assets/sounds/prod/music-8bit.webm';
import s_music_8bit2   from '../assets/sounds/prod/music-8bit2.webm';

// Fallback as MP3s per https://github.com/goldfire/howler.js#format-recommendations
import s_jump_mp3         from '../assets/sounds/prod/jump.mp3';
import s_shot_mp3         from '../assets/sounds/prod/Laser-shot.mp3';
import s_score_mp3        from '../assets/sounds/prod/coin-up.mp3';
import s_health_mp3       from '../assets/sounds/prod/coin2.mp3';
import s_clink_mp3        from '../assets/sounds/prod/coin-glass.mp3';
import s_btn1_mp3         from '../assets/sounds/prod/button1.mp3';
import s_btn2_mp3         from '../assets/sounds/prod/button2.mp3';
import s_btn12_mp3        from '../assets/sounds/prod/button1-2.mp3';
import s_achievement_mp3  from '../assets/sounds/prod/achievement.mp3';
import s_achievement2_mp3 from '../assets/sounds/prod/achievement2.mp3';
import s_start1_mp3       from '../assets/sounds/prod/game-start.mp3';
import s_start2_mp3       from '../assets/sounds/prod/start.mp3';
import s_positive_mp3     from '../assets/sounds/prod/positive.mp3';
import s_beep_mp3         from '../assets/sounds/prod/tone-beep.mp3';
import s_cowbell_mp3      from '../assets/sounds/prod/cowbell.mp3';

// List as "music" so it loops automatically
import s_music_thruster_mp3 from '../assets/sounds/prod/thruster.mp3';

import s_music_ambient_mp3 from '../assets/sounds/prod/music-ambient.mp3';
import s_music_forest_mp3  from '../assets/sounds/prod/music-forest.mp3';
import s_music_8bit1_mp3   from '../assets/sounds/prod/music-8bit.mp3';
import s_music_8bit2_mp3   from '../assets/sounds/prod/music-8bit2.mp3';


const AllSounds = {
	s_jump, // becomes SoundManager.JUMP 
	s_shot,
	s_score,
	s_health,
	s_clink,
	s_btn1,
	s_btn2,
	s_btn12,
	s_achievement,
	s_achievement2,
	s_start1,
	s_start2,
	s_positive,
	s_beep,
	s_cowbell,
	s_music_ambient, // becomes SoundManager.MUSIC_AMBIENT
	s_music_forest,
	s_music_8bit1,
	s_music_8bit2,
	s_music_thruster,
};

const Mp3FallbackIndex = {
	s_jump_mp3, // becomes SoundManager.JUMP 
	s_shot_mp3,
	s_score_mp3,
	s_health_mp3,
	s_clink_mp3,
	s_btn1_mp3,
	s_btn2_mp3,
	s_btn12_mp3,
	s_achievement_mp3,
	s_achievement2_mp3,
	s_start1_mp3,
	s_start2_mp3,
	s_positive_mp3,
	s_beep_mp3,
	s_cowbell_mp3,
	s_music_ambient_mp3, // becomes SoundManager.MUSIC_AMBIENT
	s_music_forest_mp3,
	s_music_8bit1_mp3,
	s_music_8bit2_mp3,
	s_music_thruster_mp3,
}

export default class SoundManager {
	static _sounds = {};

	static _masterVolume = {
		effects: .75,
		music:   .50
	}

	static _VolumeKey = 'kitty-masterVolume';
	// Load settings for volume from window.localStorage
	static _loadVolumes() {
		Object.keys(this._masterVolume).forEach(category => {
			this._masterVolume[category] = parseFloat(window.localStorage.getItem(this._VolumeKey+'-'+category) || 1);
		});
	}

	// Save settings for volume to window.localStorage
	static _saveVolumes() {
		Object.keys(this._masterVolume).forEach(category => {
			window.localStorage.setItem(this._VolumeKey+'-'+category, this._masterVolume[category]);
		});
	}

	// Set and save volume for given category
	static setVolume(volume, category='effects') {
		this._masterVolume[category || 'effects'] = volume;
		this._saveVolumes();
	}

	static getVolume(category='effects') {
		if(category === 'all') {
			return this._masterVolume;
		} else {
			return this._masterVolume[category];
		}
	}
	
	static use(data) {
		return this.getPlayer(data);
	}

	static play(data, dontPlayIfAlreadyPlaying=true) {
		const player = this.getPlayer(data);
		player.play(dontPlayIfAlreadyPlaying);
		return player;
	}

	static getPlayer(data) {
		if(!data)
			throw new Error("First argument required, none given");
		if(!data.key || !data.url)
			throw new Error("Invalid call to SoundManager.getPlayer(): No .key or .url on the object passed")
		
		if(!this._sounds[data.key])
			this._loadSound(data.key, data.url);
		
		return this._sounds[data.key]
	}

	static _loadSound(key, url) {
		const fallbackKey = key + '_mp3',
			fallbackUrl = Mp3FallbackIndex[fallbackKey];

		const isMusic = key.includes('_music_');
		// console.trace(`[SoundManager._loadSound] ${key} => ${url}`);
		this._sounds[key] = {
			key,
			url,
			fallbackUrl,
			isPlaying: 0,
			category: isMusic ? 'music' : 'effects',
			sound: new Howl({
				src: [AssetLoader.normalizeUrl(url), AssetLoader.normalizeUrl(fallbackUrl)],
				loop: isMusic,
				volume: 1,
				onend: () => {
					this._sounds[key].isPlaying --;
				},
			}),
			play(dontPlayIfAlreadyPlaying=1) {
				if(this.isPlaying > dontPlayIfAlreadyPlaying)
					return false;
				this.isPlaying ++;
				// Apply volume at time of playing the sound so we always use the latest masterVolume number
				this.applyVolume();
				// console.warn("[SoundManager.play]", this.key);
				if(this.sound.effectiveVolume < 0.03) {
					// By refusing to play sounds at low volumes, 
					// we can allow the user to save CPU cycles by setting sound/music
					// volume to 0
					// console.log("effectiveVolume too quite ("+this.sound.effectiveVolume+"), not playing ", this.key);
					return this;
				}

				this.sound.play();
				return this;
			},
			stop() {
				this.isPlaying --;
				this.sound.stop();
				return this;
			},
			setVolumeModifier(num) {
				// console.warn(`[SoundManager.setVolumeModifier] <${key}>`, { num });
				this.volumeModifier = num;
				this.applyVolume();
				return this;
			},
			setCategory(cat) {
				this.category = cat;
				this.applyVolume();
				return this;
			},
			applyVolume() {
				const modifier = this.volumeModifier || 1,
					category = this.category || 'effects',
					categoryMaster = SoundManager._masterVolume[category],
					effectiveVolume = categoryMaster * modifier;
				
				// console.warn(`[SoundManager.applyVolume] <${key}>`, { effectiveVolume, modifier, category, categoryMaster });
				this.sound.effectiveVolume = effectiveVolume; // for use externally
				this.sound.volume(effectiveVolume);
			}
		}
	}

}

// Load settings for volume from window.localStorage
SoundManager._loadVolumes();

// Transform sound urls to keys on the class
// e.g. AllSounds.s_jump becomes SoundManager.JUMP = { key: 's_jump', url: s_jump };
Object.keys(AllSounds).forEach(key => {
	const sym = key.replace(/^s_/, '').toUpperCase();
	SoundManager[sym] = { key, url: AllSounds[key] };
});



// Just for debugging
if(process.env.NODE_ENV !== 'production') {
	window.SoundManager = SoundManager;
}