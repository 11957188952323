import * as PIXI from 'pixi.js-legacy';
import TWEEN from '@tweenjs/tween.js';
import { PixiUtils } from './PixiUtils';

import anim_glassbreak_sheet from '../assets/breaksquareglass/break-anim.json';
import anim_smoke_sheet      from '../assets/smokepuff/compiled/white.json';
import flag_triangle_sheet   from '../assets/flag/compiled/flag_triangle.json';


import SoundManager from './SoundManager';
import { PixiMatterContainer } from './PixiMatterContainer';
import { KITTY_ITEMS } from '../game/KittyActor';

const STD_VOL = 0.25;
let __soundsLoaded = false;

export class BreakableBlock extends PixiMatterContainer {
	static sounds = {};

	
	constructor(scale, breakableObjectType='#glass', resources, position={x:0,y:0}, textureNameOverride=null) {
		if(!resources)
			throw new Error("Resources required");

		if(!__soundsLoaded) {
			__soundsLoaded = true;
			SoundManager.use(SoundManager.SCORE).setVolumeModifier(STD_VOL * 1);
			SoundManager.use(SoundManager.HEALTH).setVolumeModifier(STD_VOL * 0.8);
			SoundManager.use(SoundManager.BEEP).setVolumeModifier(STD_VOL * 1.2);
			SoundManager.use(SoundManager.CLINK).setVolumeModifier(STD_VOL * 0.2);
			SoundManager.use(SoundManager.BTN12).setVolumeModifier(STD_VOL * 3.5);
			SoundManager.use(SoundManager.ACHIEVEMENT);
			// SoundManager.use(SoundManager.ACHIEVEMENT2);
			SoundManager.use(SoundManager.POSITIVE);
			
		}

		// Redirect powerpills to <power> for new logic...
		if (breakableObjectType === '<powerpill>')
			breakableObjectType = '<power>';

		const rootTextureName =
				textureNameOverride ? textureNameOverride :
				breakableObjectType === '#glass'    ? 'tile_glass100' :
				breakableObjectType === '<bad>'     ? 'tile_red'      : 
				breakableObjectType === '<star>'    ? 'tile_gold'     :
				breakableObjectType === '<power>'   ? 'tile_silver'   : //use silver for tinting, not: tile_blue'     :
				breakableObjectType === '<health>'  ? 'tile_green'    :
				breakableObjectType === '<silver>'  ? 'tile_silver'   :
				// breakableObjectType === '<cupcake>' ? 'cupcake_blue'   :
				breakableObjectType === '<doorblock>' ? 'flagpole_gold'   :
				breakableObjectType === '<powerpill>' ? 'pill_red'   :
				null,
			rootTexture       = resources[rootTextureName],
			breakScale        =
				breakableObjectType === '#glass' ? .5 :
				breakableObjectType === '<cupcake>' ? .825 : 1,
			isStatic          = breakableObjectType.startsWith('<') 
				&& !['<silver>', '<doorblock>'].includes(breakableObjectType) ? false : true;

		// console.log("[BreakableBlock] got:", {rootTextureName, position, breakableObjectType, breakScale, resources});
		
		if(!rootTextureName)
			throw new Error("Unknown breakableObjectType " + breakableObjectType);

		let altShape = null, altPhysicalSize = null, restitution = 0.9;
		if(breakableObjectType === '<doorblock>') {
			const w = rootTexture.texture.orig.width  * scale * breakScale, 
				  h = rootTexture.texture.orig.height * scale * breakScale;

			const barWidth = 0.175;
			altShape = [
				position.x,// - w*(barWidth ),
				position.y, 
				w * barWidth ,
				h * .825
			];
			// altPhysicalSize = {
			// 	offsetX: 0, //- (w + w*(barWidth)),
			// 	offsetY: 0,
			// };
			// console.log({altShape, altPhysicalSize, w, h, position});
		} else
		if(breakableObjectType === '<powerpill>') {
			const w = rootTexture.texture.orig.width  * scale * breakScale, 
				  h = rootTexture.texture.orig.height * scale * breakScale;

			const shrinkY = .95, shrinkX = .8;
				
			altShape = [
				position.x, //+ (w * (shrinkX*2)), // + w*(shrink * 2),
				position.y,// + (h * (shrinkY*2)), 
				w * shrinkX ,
				h * shrinkY
			];

			restitution = 1;
			// altPhysicalSize = {
			// 	offsetX: (w * (shrinkX*2)),
			// 	offsetY: (h * (shrinkY*2)),
			// };
		}

		super('rectangle', altShape || [	
				position.x,
				position.y, 
				rootTexture.texture.orig.width	* scale * breakScale, 
				rootTexture.texture.orig.height * scale * breakScale
			], {
				isStatic,
				altPhysicalSize,
				// altPhysicalSize: ['<powerpill>'].includes(breakableObjectType) ? {
				// 	width:  rootTexture.texture.orig.width  * breakScale * scale * .9,
				// 	height: rootTexture.texture.orig.height * breakScale * scale * .9
				// } : null,
				restitution: restitution || 0.9,
				density:     ['<doorblock>'].includes(breakableObjectType) ? 1.0 : 0.02,
				label: breakableObjectType
			});

		// For type detection because instanceof can be tricky with webpack
		this.isBreakableBlock = true;

		// PixiMatterContainer only gives the container - we add the sprite
		const sprite = new PIXI.Sprite(rootTexture.texture);
		sprite.scale = new PIXI.Point(scale * breakScale, scale * breakScale);
		
		// ***NOTE***
		// MatterJS REQUIRES an anchor of .5/.5 -
		// all positioning internally in MatterJS assumes x/y is the center of the object.
		// Therefore, for our physical sprites, we have to also calculate things from center of object.
		sprite.anchor.x = 0.5;
		sprite.anchor.y = 0.5;
		this.addChild(sprite);
		
		// Patch..
		this.sprite = sprite;

		// For our own reference
		this.breakableObjectType = breakableObjectType;
		
		// For world collision detection
		// this.body.label = breakableObjectType;
		this.body._breakable = true;
		
		// used to setup anim below
		let breakAnimData = null;

		// Sound effects, if any
		const sounds = { hit: null, break: null }
		
		const setSound = (type, sym) => {
			sounds[type] = sym;
		}
	
		this.sounds = sounds;

		// For damage(), below
		this.damageCount = 0;

		// For use with '<' objects
		let animTint = 0xFFFFFF;

		if(this.breakableObjectType === '#glass') {
			this.alpha      = 0.85;

			// setSound('break', sound_glassBreak);
			setSound('break', SoundManager.CLINK);
			
			// used to setup anim below
			breakAnimData = [ anim_glassbreak_sheet, resources.anim_glassbreak_img ];

			// Used to show damage before playing break anim
			this.breakingFrames = [ resources.tile_glass100, resources.tile_glass75, resources.tile_glass50, resources.tile_glass75 ];
		} else
		if(this.breakableObjectType.startsWith('<')) {
			// used to setup anim below
			breakAnimData = [ anim_smoke_sheet, resources.anim_smoke_img ];

			// Used to add an icon to the block
			let iconTexture = null;

			if(this.breakableObjectType === '<bad>') {
				animTint    = 0xFF0000;
				iconTexture = resources.icon_cross.texture;

				setSound('break', SoundManager.BTN12);


				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture, rootTexture ];//, resources.tile_red, resources.tile_red ];	
			} else 
			if(this.breakableObjectType === '<star>') {
				animTint    = 0xFFF608;
				iconTexture = resources.icon_star.texture;

				setSound('break', SoundManager.SCORE);

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture ];
			} else 
			if(this.breakableObjectType === '<cupcake>') {
				animTint    = 0x37ABC8;
				iconTexture = null; //resources.icon_star.texture;
				
				setSound('break', SoundManager.ACHIEVEMENT2);

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture ];
			} else 
			if(this.breakableObjectType === '<doorblock>') {
				animTint    = 0xffff00;
				iconTexture = null; //resources.icon_star.texture;
				
				// setSound('break', SoundManager.ACHIEVEMENT2);
				SoundManager.use(SoundManager.POSITIVE);
				SoundManager.use(SoundManager.ACHIEVEMENT);

				// We'll overload this to use as a flag anim
				breakAnimData = [ flag_triangle_sheet, resources.flag_triangle_img ];

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture ];
			} else 
			if(this.breakableObjectType === '<powerpill>') {
				animTint    = 0xC63636;
				iconTexture = null; //resources.icon_star.texture;
				
				setSound('break', SoundManager.HEALTH);

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture ];
			} else
			if(this.breakableObjectType === '<power>') {
				animTint    = 0x00C9C9;
				
				// id's must match something from KITTY_ITEMS
				const powerTypes = [{
					id: 'lasers',
				},
				{
					id: 'grenade',
				}, 
				{
					id: 'go_big',
				},
				{
					id: 'speed_boost',
				},
				{
					id: 'floater',
				},
				{
					id: 'explosion',
					iconHints: {
						scale: 0.625,
					},					
				},
				{
					id: 'shield',
					icon: 'power_shield', // override KITTY_ITEMS
				}].map(partial => {
					const itemDef = KITTY_ITEMS[partial.id];
					partial.itemDef = itemDef;
					partial.tint = itemDef.baseTint;
					return partial;
				});
				
				// TODO: Better way than random? Can we specify power type in level data in future?
				const powerType = powerTypes[Math.floor(Math.random() * powerTypes.length)];

				// console.log("chose:", powerType, ", matching res:", resources[powerType.itemDef.iconRes]);

				iconTexture = resources[powerType.icon || powerType.itemDef.iconRes].texture;
				this.powerType = powerType; // accessed by cat when consumed
				if(powerType.iconHints)
					// used for resizing below
					iconTexture.iconHints = powerType.iconHints;

				setSound('break', SoundManager.HEALTH);

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture ];
				
				if(powerType.tint)
					animTint = sprite.tint = powerType.tint;
			} else 
			if(this.breakableObjectType === '<health>') {
				animTint    = 0x8CE813;
				iconTexture = resources.icon_health.texture;

				setSound('break', SoundManager.BEEP);

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ rootTexture ];
			} else
			if(this.breakableObjectType === '<silver>') {
				animTint    = 0xFFFFFF;
				iconTexture = null;

				setSound('break', SoundManager.BEEP);

				// Used to show damage before playing break anim
				// In our case, we use same texture every time but decrease opacity in damage handler 
				this.breakingFrames = [ 
					rootTexture,
					rootTexture,
					rootTexture
				];
			}

			if(iconTexture) {
				const icon = new PIXI.Sprite(iconTexture);
				icon.anchor.x = 0.5;
				icon.anchor.y = 0.5;
				
				const normalizeScale = texture => {
					let scaleMod = 0.67;
					
					// set in <power> above, if at all
					if (texture.iconHints &&
						texture.iconHints.scale)
						scaleMod = texture.iconHints.scale || 0.67;

					const base = (scale * 256); // assuming base of 256
					// const sx = base / texture.orig.width  * scale;
					// const sy = base / texture.orig.height * scale;
					const sx = base / texture.orig.width  * scaleMod;
					const sy = base / texture.orig.height * scaleMod;
					
					return new PIXI.Point(sx, sy);
				};

				icon.scale = normalizeScale(iconTexture);
				this.addChild(icon);
			}
		} else {
			throw new Error("Don't know how to break " + this.breakableObjectType);
		}

		// Create breaking animation
		try { // guard against https://sentry.io/organizations/sleepy-cat-game/issues/959640471
			PixiUtils.getAnimatedSprite(...breakAnimData).then(sprite => {
				sprite.x        = 0;
				sprite.y        = 0;
				sprite.anchor.x = 0.5;
				sprite.anchor.y = 0.5;

				// Glass break is 512x512, and gridTileScaleDownFactor is adjusted for 256, so we have to shrink it by 50%
				sprite.scale = new PIXI.Point(0.50 * scale, 0.50 * scale);
				
				sprite.speed = .25;

				// Adjust smoke scaling 
				// if(this.breakableObjectType.startsWith('<')) {
				// 	// const spriteSize = 1129 * scale;
				// 	sprite.x = 0;
				// 	sprite.y = 0; //(spriteSize/2 - (rootTexture.orig.width	* scale * breakScale));				
				// }

				if(breakableObjectType === '<doorblock>') {
					sprite._topY = -256 * scale * .725
					sprite.y =  256 * scale * .75
					sprite.x = -256 * scale * .125;
					sprite.anchor.x = 0;
					// window.flagBlock = this;
					// window._scale = scale;
					// window._breakScale = breakScale;
					
				} else {

					sprite.tint = animTint;

					sprite.stop();

					// Stop after one time thru
					sprite.loop = true;
					sprite.onLoop = () => {
						sprite.stop();
						sprite.alpha = 0;

						this.doneBreaking();
						
						// this.setMatter('isStatic', false);
					};

					// Auto-show on play
					const play = sprite.play.bind(sprite);
					sprite.play = () => {
						play();
						sprite.alpha = 1;
						PixiUtils.fadeOut(this.sprite);
					};

					// Only show when needed
					sprite.alpha = 0;
				}
					
				this.addChild(sprite);
				this.anim = sprite;
			});
		} catch(e) {
			// guard against https://sentry.io/organizations/sleepy-cat-game/issues/959640471
			console.warn("Error in getAnimatedSprite when called with breakAnimData=",breakAnimData,", error was:", e);
		}

		// Just for testing from console
		if(!window.breakables)
			window.breakables = [];
		window.breakables.push(this);
	}

	doneBreaking() {
		if(this._doneBreaking)
			return;

		this._doneBreaking = true;

		this.tempDestroy();
	}

	raiseFlag(time=1000) {
		if(this.breakableObjectType !== '<doorblock>') {
			throw new Error("This is not a doorblock, no flag to raise");
		}

		// Raise the flag
		return PixiUtils.tweenXY(this.anim, this.anim.position, { 
			x: this.anim.x, 
			y: this.anim._topY
		}, 'position', time, TWEEN.Easing.Bounce.Out).then(() => {

			// Sound for when flag hits top
			// SoundManager.play(SoundManager.POSITIVE);
		})
	}

	// Play final animation then remove
	async finalDamage(otherPixiObject, methodOfDamage=null) {
		if (this._finalDamage)
			return;
		
		this._finalDamage = true;

		// Proactively remove so game can play thru
		this.matterRemove();

		if(this.breakableObjectType === '<doorblock>') {
			// Change flag appearance for an extra flair
			if (this.anim) // https://sentry.io/organizations/sleepy-cat-game/issues/959641183
				this.anim.tint = this.scene ? this.scene.actor.kittyColor : 0xf9d453;

			// Modify viewport zoom or whatever the scene wants to do
			if (this.scene) {
				// The scene itself will call this.raiseFlag() when it's ready.
				// It will do some other animations first before raising the flag.
				await this.scene.levelEndFlagRaising();
			} else {
				console.warn("No scene set on doorblock - why?? Continuing anyway...")
				await this.raiseFlag();
			}

			// Flag raised, continue ending routine
			this._endFinalDamage(otherPixiObject, methodOfDamage);

		} else {

			// Play anim and destroy
			this.anim.play();

			// Finish destruction
			this._endFinalDamage(otherPixiObject, methodOfDamage);
		}
	}

	_endFinalDamage(otherPixiObject, methodOfDamage=null) {
		
		// Notify scene that this was damaged
		if (this.objectCompleted)
			this.objectCompleted(this);
		
		if (this.sounds.break)
			SoundManager.play(this.sounds.break, 3);

		if( otherPixiObject &&
			otherPixiObject._gameActor) {
			// console.log("[BreakAbleBlock] otherPixiObject was _gameActor, could do something here");
			otherPixiObject._gameActor.brokeBlock(this, methodOfDamage);
		}
	}

	damage(otherPixiObject, methodOfDamage) {
		if(this.isDestroyed)
			return;
			
		// if(otherPixiObject && otherPixiObject._gameActor) {
		// 	// console.log("Damaged by actor:", otherPixiObject._gameActor);
		// }

		const frame = ++ this.damageCount;
		if(frame >= this.breakingFrames.length) {
			this.finalDamage(otherPixiObject, methodOfDamage);
		} else {
			this.showDamageFrame(frame);
		}
	}

	showDamageFrame(frame) {
		// Never should hit this, but still...
		if(frame >= this.breakingFrames.length)
				return;

		// store to be sure
		this.damageCount = frame;

		// Show next frame
		const resource = this.breakingFrames[frame];
		if(resource && resource.texture.baseTexture) {
			this.sprite.texture = resource.texture;
		} else {
			// Sprite was damaged somehow, just remove this
			this.doneBreaking();
		}

		if (this.sounds.hit)
			SoundManager.play(this.sounds.hit, 3);

		// Red damage is not a texture change, just opacity change...
		// BUT we use # of frames (of same texture) to indicate the number of "damages" needed to destroy
		if(this.breakableObjectType.startsWith('<')) {
			this.sprite.alpha = 1-(frame / this.breakingFrames.length);
			if (this.sprite.alpha === 1.0)
				this.sprite.alpha = 0.5;
		}
	}

	
}