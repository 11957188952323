module.exports = levelFeatureAnalysis;

// https://stackoverflow.com/a/27845224/1119559
const count = (s, c) => {
	let result = 0;
	if(!s) return result;
	for(let i=0;i<s.length;i++) if(s[i]===c) result++;
	return result;
}

/**
 * Analyzes level and returns feature descriptors and difficulty rating
 *
 * @param {Level} level - Instance of Level schema to analyze
 * @param {boolean} [debug=false] If true, returns debug data object instead of feature object
 * @returns
 */
function levelFeatureAnalysis(level, debug) {
	const { width, height, bg, data: dataTmp } = level;

	// No corners in current sim code, rendered as empty spaces, so count as empty
	const data = dataTmp.replace(/[trbl]/g, ' ');

	// These 'arbitrary' units are just that - experiential values that I've found 
	// express what I feel is the right ratios. Magic numbers, if you will. 
	const ARBITRARY_UNITS_HIGH = 10; // Larger than wide because higher levels sap more energy
	const ARBITRARY_UNITS_WIDE = 5;  // Allow some width, but width does take more time
	const ARBITRARY_AREA_SCALE_FACTOR = Math.pow(ARBITRARY_UNITS_HIGH, 2) * (ARBITRARY_UNITS_WIDE * 2); // arbitrary relation I found that feels right
	
	const n = {
		// Self-explanatory counts of level features (absolute values, we'll normalize later as a ratio to area)
		open:   count(data, ' '),
		basic:  count(data, '#'),
		stars:  count(data, '*'),
		bad:    count(data, 'x'),
		power:  count(data, '^'),
		glass:  count(data, '~'),
		health: count(data, '+'),
		balls:  count(data, 'o'),

		// Store these size values here because...lazy.
		area:   width * height,
		// Express tall/wide as ratios capped at 1.0 for use in a feature vec later, not used in difficulty
		tall:   Math.min(1, height/width / ARBITRARY_UNITS_HIGH),
		wide:   Math.min(1, width/height / ARBITRARY_UNITS_WIDE),
	};

	// 'blocks' feature is count of basic (#) + glass (~) - e.g. static occupied space.
	// We reduce glass by 0.9 as an arbitrary factor designed to express than glass is likely to be broken by bouncing balls,
	// reducing occupied static space in the level over time
	n.blocks = n.basic + n.glass * 0.9;

	// Maps not manually edited won't have pre-placed balls, so we must emulate the random ball calcs in the live game
	if(n.balls === 0) {
		// no balls in map, we use random number to add balls, median random is 0.125
		// Mimic the calc used to figure out how many random balls to add to the level if none in map
		n.balls = Math.ceil( (width-3) / (1/0.125) );
	}

	// Normalize the feature counts as a factor of area, except where noted below.
	// p1 was is for visual number debugging and understanding, not needed in prod
	const p = {}, p1 = {};
	Object.keys(n).forEach(key => {
		p[key]  = 
			// Tall/wide already normalized
			['tall','wide'].includes(key) ? n[key] :
			// Area needs normalized by something other than itself - our ARBITRARY_AREA_SCALE_FACTOR in this case
			['area'].includes(key) ? Math.min(1, n[key] / ARBITRARY_AREA_SCALE_FACTOR) :
			// Everything else: normalize the count as a factor of area
			n[key] / n.area;
		p1[key] = Math.ceil(p[key] * 100);
	});

	// Calc some relevant ratios used for determining difficulty of level.
	// The thinking behind most of these is that the larger the value calculated, the "harder"
	// the level will be. So for example, starsVsBlocks: More stars, less blocks - easy. More blocks, less stars - harder, Stars==blocks - somewhat less harder.
	const calcs = {
		// ex 2 stars to 10 blocks = 20% ratio, 1-.2 = 80% "hard"
		starsVsBlocks: Math.min(1, Math.max(0, 1 - (p.stars / p.blocks))) || 0,
		// Less open space means it's harder
		usedSpace:    (1 -  p.open),
		// Extremes in one or the other direction makes it harder
		sizeFactor:    Math.max(p.tall, p.wide),
		// Ratio of good blocks to bad blocks - kinda obvious I think
		badVsHealth:  (p.bad || 0) / (p.health || 1),
		// An attempt to quantify the chaos added by more balls
		ballsVsOthers: Math.min(1, p.balls / (p.stars + p.bad + p.power + p.health + p.glass)),
	};

	// Finally, difficulty is simply a weighted combination of the various factors
	// calculated above, scaled by the area 
	// That means that a smaller level will be "less difficult" than a bigger level,
	// all other factors being the same.
	// On levels (for whatever reason) there are no stars and no blocks, 
	// just make it a really arbitrarily small difficulty based on some factor and area
	const difficulty = !n.stars && !n.blocks ? 
		0.05 * p.area : (0
		+ calcs.starsVsBlocks * 0.4
		+ calcs.usedSpace     * 0.3
		+ calcs.sizeFactor    * 0.2
		+ calcs.badVsHealth   * 0.06
		+ calcs.ballsVsOthers * 0.04
	) * p.area;

	// const color = colorForLevelBg(bg);
	const moreFeatures = {
		gravity: bg === 'water' ? 0.015 : bg === 'stars' ? 0 : 1,
		// colorR: color[0],
		// colorG: color[1],
		// colorB: color[2],
	}

	Object.assign(p, moreFeatures);

	if(debug) {
		return {
			n,
			p1,
			calcs,
			difficulty,
			data,
			num: level.levelNum,
			id: level.id,
			width, height,
			p,
			moreFeatures
		};
	}

	const featureData = {
		difficulty,
		counts:   n,
		features: p, //{ ...p, ...moreFeatures },
		factors:  calcs,
	};

	return featureData;

}