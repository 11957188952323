import React from 'react';
import styles from './IconCircleButton.module.scss';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class IconCircleButton extends React.Component {

	state = {
		timerPercent: 0
	};

	componentDidMount() {
		if (this.props.handle)
			this.props.handle(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	setTimer(length=0) {
		this.timerValue = length;
		clearInterval(this.tid);
		if(length > 0) {
			const start = Date.now();
			this.tid = setInterval(() => {
				if(this.unmounted) {
					clearInterval(this.tid);
					return;
				}

				const elapsed    = Date.now() - start,
					percent      = elapsed / length,
					timerPercent = Math.ceil(percent * 100);
				
				this.setState({ timerPercent });

				if(percent >= 1.0) {
					if (this.props.onTimerExpired)
						this.props.onTimerExpired();
					clearInterval(this.tid);
				}

			}, 1000 / 50); // match transition timing in  css
		}
	}

	render() {
		return <div className={styles.button+" " + (this.props.className || '')}
			onClick={() => this.props.onClick && this.props.onClick()}>
			<div className={styles.timerWrap}>
				<div className={styles.timer} style={{
					height: (this.state.timerPercent || 0) + '%'
				}}/>
			</div>
			<FontAwesomeIcon icon={this.props.icon || faCheck}/>
		</div>;
	}
};