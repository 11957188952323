
import bg_img_burst_blue from 'assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';
import bg_img_bubble_blue from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_blue.png';
import bg_img_wave_blue from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue.png';

// import bg_img_burst_orange from 'assets/simplebackgrounds/burst/burst_background_large_screen_orange.png';
// import bg_img_bubble_orange from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_orange.png';

import bg_img_wave_blue2 from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue_2.png';

import bg_img_burst_purple from 'assets/simplebackgrounds/burst/burst_background_large_screen_purple.png';
import bg_img_bubble_purple from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_purple.png';
import bg_img_wave_pink from 'assets/simplebackgrounds/wave/wave_bg_large_screen_pink.png';

import bg_img_burst_red from 'assets/simplebackgrounds/burst/burst_background_large_screen_red.png';
import bg_img_bubble_red from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_red.png';
import bg_img_wave_red from 'assets/simplebackgrounds/wave/wave_bg_large_screen_red.png';

import bg_img_burst_yellow from 'assets/simplebackgrounds/burst/burst_background_large_screen_yellow.png';
import bg_img_bubble_yellow from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_yellow.png';
import bg_img_wave_yellow from 'assets/simplebackgrounds/wave/wave_bg_large_screen_yellow.png';

import tx_box from 'assets/physicsobjects/pngs/small_cardboard_box_512.png';

import bg_img_ice from 'assets/icebg/BACKGROUND1.png';

// import water_bg_left   from 'assets/waterbg/pngs/left-side.png';
// import water_bg_center from 'assets/waterbg/pngs/middle.png';
// import water_bg_right  from 'assets/waterbg/pngs/right-side.png';

import stars_bg        from 'assets/space/Background-4.png';

// import tile_blue      from 'assets/physicsobjects/pngs/tiles/blue.png';
import tile_cream     from 'assets/physicsobjects/pngs/tiles/cream.png';
// import tile_gold      from 'assets/physicsobjects/pngs/tiles/gold.png';
// import tile_green     from 'assets/physicsobjects/pngs/tiles/green.png';
import tile_orange    from 'assets/physicsobjects/pngs/tiles/orange.png';
import tile_pink      from 'assets/physicsobjects/pngs/tiles/pink.png';
import tile_purple    from 'assets/physicsobjects/pngs/tiles/purple.png';
// import tile_red       from 'assets/physicsobjects/pngs/tiles/red.png';
import tile_silver    from 'assets/physicsobjects/pngs/tiles/silver.png';
// import tile_yellow    from 'assets/physicsobjects/pngs/tiles/yellow.png';

import tile_science   from 'assets/physicsobjects/pngs/tiles/science.png';
import AssetLoader from '../../../utils/AssetLoader';

// import tile_glass100 from 'assets/breaksquareglass/block/100_PERCENT.png';

const tileThemeColors = {
	orange:   '#FF8122',  // orange
	blue:     '#16B6B6', // blue
	purple:   '#A21AC4', // purple,
	red:      '#8F1D21', // red
	silver:   '#F1F1F1', // silver
	pink:     '#EF2F7C', // pink
	cream:    '#EF2F7C', // cream
	yellow:   '#f9d453', // brand color
};

const rawLevelProps = {

	yellow: {
		statusBarColor: tileThemeColors.yellow,

		bgs: [
			{
				bg: { bg_img_burst_yellow },
			},
			{
				bg: { bg_img_wave_yellow },
			},
			{
				bg: { bg_img_bubble_yellow },
			},
		],

		box: [
			{
				bg: { tx_box },
			}
		]
	},

	ice: {
		// NB: This won't have any affect until installed as a "real" app or ran in a browser
		// PWA apps ignore this and only use manifest colors (sadly)
		statusBarColor: tileThemeColors.silver,

		bgs: [
			{
				bg: { bg_img_ice },
				rotate: false,
			}
		],
		effects: [
			{
				type: 'snow'
			}
		],
		box: [
			{
				bg: { tile_silver }
			}
		]
	},

	// water: {
	// 	statusBarColor: '#36C8E9',

	// 	bgs: [
	// 		{
	// 			bg: { water_bg_center },
	// 			rotate: false,
	// 		}
	// 	],
	// 	effects: [
	// 		{
	// 			type: 'bubbles',
	// 		}
	// 	],
	// 	box: [
	// 		{
	// 			// bg: { tile_glass100 },
	// 			// // TODO: Implement masking for stuff "inside" box
	// 			// partiallyOpaque: true,

	// 			bg: { tile_science },
	// 		},
	// 		{
	// 			bg: { tile_silver },
	// 		}
	// 	]
	// },

	space: {
		statusBarColor: '#000000',

		bgs: [
			{
				bg: { stars_bg },
				rotate: false,
			}
		],
		effects: [
			// {
			// 	type: 'bubbles',
			// }
		],
		box: [
			// {
			// 	bg: { tile_glass100 },
			// 	// TODO: Implement masking for stuff "inside" box
			// 	partiallyOpaque: true,
			// },
			{
				bg: { tile_silver },
			},
			{
				bg: { tile_science },
			},
			{
				bg: { tile_cream },
			},
		]
	},

	blue: {
		statusBarColor: tileThemeColors.blue,

		bgs: [
			{
				bg: { bg_img_bubble_blue },
			},
			{
				bg: { bg_img_burst_blue },
			},
			{
				bg: { bg_img_wave_blue },
			},
		],

		box: [
			{
				bg: { tile_orange },
			}
		]
	},
		
	// green: {
	// 	statusBarColor: tileThemeColors.green,

	// 	bgs: [
	// 		{
	// 			bg: bg_img_bubble_green,
	// 		},
	// 		{
	// 			bg: bg_img_burst_green,
	// 		},
	// 		{
	// 			bg: bg_img_wave_green,
	// 		},
	// 	],

	// 	box: [
	// 		{
	// 			bg: tile_silver
	// 		}
	// 	]	
	// },

	blue2: {
		statusBarColor: tileThemeColors.blue,

		bgs: [
			{
				bg: { bg_img_wave_blue2 },
			},
		],

		box: [
			{
				bg: { tile_orange },
			}
		]
	},
	
	purple: {
		statusBarColor: tileThemeColors.purple,

		bgs: [
			{
				bg: { bg_img_bubble_purple },
			},
			{
				bg: { bg_img_burst_purple },
			},
		],

		box: [
			{
				bg: { tile_purple },
			}
		]
	},

	pink: {
		statusBarColor: tileThemeColors.pink,

		bgs: [
			{
				bg: { bg_img_wave_pink },
			},
		],

		box: [
			{
				bg: { tile_pink },
			}
		]
	},

	red: {
		statusBarColor: tileThemeColors.red,

		bgs: [
			{
				bg: { bg_img_bubble_red },
			},
			{
				bg: { bg_img_burst_red },
			},
			{
				bg: { bg_img_wave_red },
			}
		],

		box: [
			{
				bg: { tile_cream }
			}
		]
	},

};


// // Found this from https://gomakethings.com/how-to-shuffle-an-array-with-vanilla-js/
// /**
//  * Randomly shuffle an array
//  * https://stackoverflow.com/a/2450976/1293256
//  * @param  {Array} array The array to shuffle
//  * @return {String}      The first item in the shuffled array
//  */
// function shuffle(array) {

// 	var currentIndex = array.length;
// 	var temporaryValue, randomIndex;

// 	// While there remain elements to shuffle...
// 	while (0 !== currentIndex) {
// 		// Pick a remaining element...
// 		randomIndex = Math.floor(Math.random() * currentIndex);
// 		currentIndex -= 1;

// 		// And swap it with the current element.
// 		temporaryValue = array[currentIndex];
// 		array[currentIndex] = array[randomIndex];
// 		array[randomIndex] = temporaryValue;
// 	}

// 	return array;

// };

export default class LevelProps {
	static requiredResources(level) {
		if(level) {
			const props = this.propsForLevel(level);
			const required = {
				[ props.box.bg ]: this._assets[props.box.bg],
				[ props.bg.bg  ]: this._assets[props.bg.bg],
			}

			// console.log("LevelProps.requiredResources for level ", level, " = ", required);
			return required;
		}

		return this._assets;
	}

	static remainingRequiredResources() {
		const remaining = {};
		Object.keys(this._assets).forEach(key => {
			if(!AssetLoader.resources[key]) {
				remaining[key] = this._assets[key];
			}
		});
		return remaining;
	}

	static propsForLevel(level) {
		// level = 1;
		const idx = (level - 1) % this._uniqueLevels.length;
		const props = this._uniqueLevels[idx];
		// console.log("[LevelProps:propsForLevel] level ", level, " = ", props);
		return props;
	}

	static _init() {
		this._assets = {};

		this._uniqueLevels = [];
		
		Object.keys(rawLevelProps).forEach(levelCategory => {
			const rawData = rawLevelProps[levelCategory];
			let { box: boxes, bgs, effects, ...otherProps } = rawData;

			
			boxes = Array.from(boxes || []);
			bgs = Array.from(bgs || []);
			effects = Array.from(effects || []);


			if(!boxes) {
				throw new Error("No box prop on category " + levelCategory);
			}

			if(!bgs) {
				throw new Error("No bgs prop on category " + levelCategory);
			}

			if(!effects) {
				throw new Error("No effects prop on category " + levelCategory);
			}

			// assets in box and bgs
			[ ...boxes, ...bgs ].forEach(item => {
				const key = Object.keys(item.bg)[0];
				const value = item.bg[key];
				if(value !== "/") {
					this._assets[key] = value;
				}
			});

			const sourceList = bgs.length > boxes.length ? bgs : boxes;
			sourceList.forEach((unused, idx) => {
				const box    = boxes[idx % boxes.length];
				const bg     = bgs[idx % bgs.length];
				const effect = effects[idx % effects.length];

				const { bg: boxBgData, ...boxProps } = box;
				const boxKey = Object.keys(boxBgData)[0];
				// const boxBg  = boxBgData[boxKey];

				const { bg: bgData, ...bgProps } = bg;
				const bgKey  = Object.keys(bgData)[0];
				// const bgBg   = bgData[bgKey];

				
				this._uniqueLevels.push({
					box: {
						...boxProps,
						bg: boxKey,
					},
					bg: {
						...bgProps,
						bg: bgKey
					}, 
					effect,
					...otherProps,
					levelCategory
				});
			});

			// shuffle(this._uniqueLevels);
		});

		// console.log("[LevelProps] generated:", { ...this });
	}
}

LevelProps._init();
window.LevelProps = LevelProps;

export { 
	LevelProps,
	rawLevelProps,
	tileThemeColors
};