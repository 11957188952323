import * as PIXI from 'pixi.js-legacy';

// Monkey-patch calculateVertices() due to https://sentry.io/organizations/sleepy-cat-game/issues/906592796/
// Basically, for some reason, the PIXI ticker somehow triggers a re-render which for some reason
// triggers on a sprite EVEN AFTER it's been destroyed. The sprite destroy() method sets _anchor=null,
// which causes an exception when _anchor.x is read in _original_calculateVertices().
// So, we try to prevent that by checking here for null properties and avoid an exception.
PIXI.Sprite.prototype._original_calculateVertices = PIXI.Sprite.prototype.calculateVertices;
PIXI.Sprite.prototype.calculateVertices = function calculateVertices() {
	if(!this._texture || !this._transform) {
		if(!this.vertexTrimmedData)
			this.vertexTrimmedData = new Float32Array(8);

		const vertexData = this.vertexTrimmedData;

		// xy
		vertexData[0] = 0;
		vertexData[1] = 0;

		// xy
		vertexData[2] = 0;
		vertexData[3] = 0;

		// xy
		vertexData[4] = 0;
		vertexData[5] = 0;

		// xy
		vertexData[6] = 0;
		vertexData[7] = 0;
	} else
	if(!this._anchor) {
		this._anchor = new PIXI.Point(0, 0);
	}

	try {
		PIXI.Sprite.prototype._original_calculateVertices.apply(this);
	} catch(e) {
		// console.warn("Error in overridden calculateVerticies: ", e);
		// If we get an error here, what are we REALLY going to do with it...?
	}
}

// Patch for error found in testing
PIXI.Sprite.prototype._original_containsPoint = PIXI.Sprite.prototype.containsPoint;
PIXI.Sprite.prototype.containsPoint = function(point) {
	if(!this._texture || !this._texture.orig) 
		return false;
	return PIXI.Sprite.prototype._original_containsPoint.call(this, point);
}

// Path for https://sentry.io/organizations/sleepy-cat-game/issues/941263384/
// NB disabled patch in Pixi >5.0
// PIXI.CanvasSpriteRenderer.prototype._original_render = PIXI.CanvasSpriteRenderer.prototype.render;
// PIXI.CanvasSpriteRenderer.prototype.render = function render(sprite) {
// 	var texture = sprite._texture;
// 	if(!texture || !texture._frame)
// 		return;

// 	return PIXI.CanvasSpriteRenderer.prototype._original_render.call(this, sprite);
// }


// Quit down, console
// Copied from BaseTexture.js:793 and removed console debug output
PIXI.BaseTexture.addToCache = function addToCache(baseTexture, id) {
	if (id) {
		if (baseTexture.textureCacheIds.indexOf(id) === -1) {
			baseTexture.textureCacheIds.push(id);
		}

		PIXI.utils.BaseTextureCache[id] = baseTexture;
	}
};

// Quiet down console
// Copied form Texture.js:502 and removed console debug output
PIXI.Texture.addToCache = function addToCache(texture, id) {
	if (id) {
		if (texture.textureCacheIds.indexOf(id) === -1) {
			texture.textureCacheIds.push(id);
		}
	
		PIXI.utils.TextureCache[id] = texture;
	}
};