import { ServerStore } from "./ServerStore";

/* <script async src=""></script>
<script>
</script> */

// import windowGlobal from '/windowGlobal';

// const windowGlobal = typeof window !== 'undefined' && window;

// /**
//  * Load the AdSense and return a Promise that resolves once the API has been loaded.
//  * Takes care to only inject once on a given page (window object), even if called multiple times. If already injected, Promise resolves immediately.
//  * 
//  * @export
//  * @param {string} [stripeUrl='https://js.stripe.com/v3/'] URL of the Stripe SDK to load, defaults to Stripe v3
//  * @returns Promise that resolves once SDK loads successfully from Stripe
//  */
// function injectAdsense(url = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js') {
// 	if(!windowGlobal || windowGlobal.__adsenseInjected)
// 		return Promise.resolve();

// 	return new Promise(resolve => {
// 		const document = windowGlobal.document,
// 			script     = document.createElement('script');

// 		script.onload = () => {
// 			windowGlobal.__adsenseInjected = true;

// 			if(!window.adsbygoogle)
// 				window.adsbygoogle = []

// 			window.adsbygoogle.push({
// 				google_ad_client: "ca-pub-3883874012030009",
// 				enable_page_level_ads: true
// 			});
			
// 			resolve();
// 		};
		
// 		script.src = url;
// 		script.async = false;

// 		document.head.appendChild(script)
// 	});
// }

// based on instructions at https://www.npmjs.com/package/cordova-plugin-admobpro-firebase
// select the right Ad Id according to platform
let admobid = null;
if( /(android)/i.test(navigator.userAgent) ) { // for android & amazon-fireos
	admobid = {
		banner: 'ca-app-pub-3883874012030009/4978347711', //'ca-app-pub-3883874012030009/5334473926', // or DFP format "/6253334/dfp_example_ad"
		interstitial: 'ca-app-pub-3883874012030009/1231577388'
	};
} else if(/(ipod|iphone|ipad)/i.test(navigator.userAgent)) { // for ios
	// Pub ID for IOS, not used anywhere in code right now, just here for documentationm:
	// ca-app-pub-3883874012030009~4225756407
	admobid = {
		banner: 'ca-app-pub-3883874012030009/7716255337', // or DFP format "/6253334/dfp_example_ad"
		interstitial: 'ca-app-pub-3883874012030009/5562888808'
	};
} else { // for windows phone
	admobid = null;
}

export { admobid };

// for console debugging ..
// if(process.env.NODE_ENV !== 'production')
window.admobid = admobid;

const AdMob = window.AdMob || null;

// if(!AdMob)
// 	injectAdsense();

// if(AdMob) {
// 	const defaultOptions = {
// 		adSize: 'SMART_BANNER',
// 		width: 360, // valid when set adSize 'CUSTOM'
// 		height: 90, // valid when set adSize 'CUSTOM'
// 		position: AdMob.AD_POSITION.BOTTOM_CENTER,
// 		x: 0,		// valid when set position to POS_XY
// 		y: 0,		// valid when set position to POS_XY
// 		isTesting: false,
// 		autoShow:  false
// 	};
// 	AdMob.setOptions( defaultOptions );
// }

export class AdMobUtil {
	
	// it will display smart banner at top center, using the default options
	static showBanner(opts={}) {
		if(!AdMob || !admobid)
			return;
			
		opts = {
			adId: admobid.banner,
			position: AdMob.AD_POSITION.BOTTOM_CENTER,
			autoShow: true,
			...(opts || {}),
			success: function(){
			},
			error: function(err){
				console.error("[AdMobUtil.showBanner] failed to createBanner ", err);
			}
		};
		console.log("[AdMobUtil.showBanner] opts=", opts);
		if(AdMob)
			AdMob.createBanner(opts);
	}

	static hideBanner() {
		if (AdMob)
			AdMob.hideBanner();
	}


	// prepare and load ad resource in background, e.g. at beginning of game level
	static prepareInterstitial() {
		if(AdMob && admobid)
			AdMob.prepareInterstitial({
				adId: admobid.interstitial,
				autoShow: false
			});
	}
	
	static showInterstitial() {
		// show the interstitial later, e.g. at end of game level
		if(AdMob && admobid) {
			ServerStore.metric("game.level.ad.interstital.shown");
			AdMob.showInterstitial();
		}
	}
}

// For console debugging
window.AdMobUtil = AdMobUtil;