import React from 'react';

import { GameFactory } from '../../game/Game';

export default class OverlayView extends React.Component {
	
	constructor() {
		super();

		this.state = {
			renderOverlay: null
		};

		GameFactory.waitForBoot(game => {
			// Game already has an overlay requested before we added listener
			if (game.currentReactOverlay)
				this.setState({ renderOverlay: game.currentReactOverlay });

			// Listen for UI requests from the game
			game.on('setReactOverlay', renderOverlay => this.setState({ renderOverlay }));
		});
	}

	render() {
		/* Render views requested by the 'setReactOverlay' event from the Game object  */
		return this.state.renderOverlay ? this.state.renderOverlay : <></>;
	}
};