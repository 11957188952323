import React from 'react';

import { FormGroup, Label, Button } from 'reactstrap';
import { ServerStore, FB_APP_ID } from 'utils/ServerStore';
import SoundManager from 'utils/SoundManager';

import ColorPicker from 'react-simple-colorpicker';

import { HomeNavBar } from 'game/scenes/WelcomeScene/widgets/HomeNavBar';

// Login logic (loads FB SDK automatically)
import FacebookLogin from 'components/FacebookLogin';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

import './settings-widget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRedo, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { GameFactory } from '../../../Game';

export class SettingsWidget extends React.Component {
	constructor() {
		super();

		// ServerStore.currentCat.color = 0xFF08CE;

		this.state = {
			...SoundManager.getVolume('all'),
			ver: { runningVer: '...' },
			fbIsConnected: ServerStore.currentUser.isFacebookUser,
			customizePopupVisible: false,
			kittyName: ServerStore.currentCat.name,
			kittyColor: '#' + ServerStore.currentCat.color.toString(16),
		}

		ServerStore.appVersion().then(ver => this.setState({ ver }));
	}

	setCatName(name) {
		if(name) {
			// Patch debounces by default, no need to debounce here
			ServerStore.currentCat.patch({ name });
		}
	}

	setMusicVolume(vol) {
		SoundManager.setVolume(vol/100, 'music');

		const music = SoundManager.use(SoundManager.MUSIC_FOREST);
		if(!music.isPlaying)
			music.play()
		else
			music.applyVolume();
		
		clearTimeout(this._music);
		this._music = setTimeout(() => music.stop(), 5000);
		this.setState(SoundManager.getVolume('all'));
	}

	setEffectsVolume(vol) {
		SoundManager.setVolume(vol/100, 'effects');
		SoundManager.getPlayer(SoundManager.POSITIVE).stop().play();
		this.setState(SoundManager.getVolume('all'));
	}

	customizeKitty = () => {
		this.setState({ customizePopupVisible: true });

		HomeNavBar.showBackBtn(() => {
			this.setState({ customizePopupVisible: false });
		});

	}

	renameKitty = async () => {

		const {value: kittyName} = await window.AlertManager.fire({
			title: 'Choose a name for your SassyBox',
			input: 'text',
			inputValue: this.state.kittyName,
			showCancelButton: true,
			inputValidator: (value) => {
			  if (!value) {
				return 'You need to write something!'
			  }
			}
		  });

		if(kittyName) {
			this.setState({ kittyName });
			ServerStore.currentCat.patch({ name: kittyName });
		}
	}

	changeKittyColor = (color) => {
	
		// const rgb = color.replace(/^rgba?\(|\)$/g,'').split(',').map(x => parseInt(x,10));
		// const hex = parseInt(rgb.slice(0,3).map(x => {
		// 	const hex = x.toString(16)
		// 	return hex.length === 1 ? '0' + hex : hex
		// }).join(''), 16);

		const hex = parseInt(color
			.replace(/^rgba?\(|\)$/g,'') // remove outer 'rgba('...')' wrapper
			.split(',') // split "255,31,192,0.5" => ['255','31','192','0.5']
			.slice(0, 3) // remove any possible final alpha component, giving just first 3 rgb values
			.map(x => { // convert each individual array element to a hex number string
				const hex = parseInt(x, 10).toString(16)
				return hex.length === 1 ? '0' + hex : hex
			})
			.join('') // join the array components into one long hex number
		, 16); // parse the joined string as a base-16 (hex) string, returning an integer

	  
		this.setState({ kittyColor: color }); // state gets the original 'rgba(...)' value for the color picker
		// console.log("[changeKittyColor] ", { rgb, color, hexInt: hex, hex: '#' + hex.toString(16) });
		ServerStore.currentCat.patch({ color: hex }); // store the integer color in the database
		GameFactory.game.currentScene.actor.setKittyColor(hex); // give the integer to the actor
	}

	disconnectFb = () => {
		ServerStore.unlinkFb().then(store => {
			if(store) {
				this.setState({ 
					fbIsConnected: false
				})
			} else {
				alert("Could not update server");
			}
		})
	}

	facebookConnected(response) {
		ServerStore.linkFb(response.accessToken).then(store => {
			if(store) {
				this.setState({ 
					fbIsConnected: true
				})
			} else {
				alert("Could not authenticate with server");
			}
		})
	}


	render() {
		// .content class incase scrolling needed
		return <div className="settings-widget inner">
			{this.state.customizePopupVisible ? <>
				<div className="customize-kitty-popup">
					<h1>{this.state.kittyName}</h1>
					<Button onClick={this.renameKitty}>
						<FontAwesomeIcon
							icon={faFileSignature}/>{' '}Change your kitty's name...
					</Button>

{/* 
					<Button className="loginBtn loginBtn--nofb">
						Change your kitty's color...
					</Button> */}
					<ColorPicker color={this.state.kittyColor} onChange={this.changeKittyColor} />
					<Button onClick={() => this.changeKittyColor('rgb(255,8,206)')}>
						<FontAwesomeIcon
							icon={faRedo}/> {' '}Reset to Original Color
					</Button>
				</div>
			</> 
			:
			<>
				<div className="content">
					<div className="content-padding">
						<h1 className="settings-header">Settings</h1>
						{/* <FormGroup>
							<Label>What's Your Kitty's Name?</Label>
							<input placeholder="Name" 
								className="form-control"
								defaultValue={ServerStore.currentCat.name} 
								onKeyUp={e => this.setCatName(e.target.value)}/>
						</FormGroup> */}

						<Button className="customize-kitty-btn" onClick={this.customizeKitty}>
							<FontAwesomeIcon icon={faEdit}/>
							{' '}Customize {this.state.kittyName}
						</Button> 

						<FormGroup className="volume-setting">
							<Label>Music Volume ({Math.ceil(this.state.music * 100) + '%'})</Label>
							<Slider dots step={10} defaultValue={this.state.music * 100} onChange={value => this.setMusicVolume(value)} />
						</FormGroup>

						<FormGroup className="volume-setting">
							<Label>Sound Effects Volume ({Math.ceil(this.state.effects * 100) + '%'})</Label>
							<Slider dots step={10} defaultValue={this.state.effects * 100} onChange={value => this.setEffectsVolume(value)} />
						</FormGroup>

						<div className="fb-connection-settings">
							{this.state.fbIsConnected ? 
							<>
								{/* <h3>Disconnect Facebook</h3>
								<p>You can disconnect you FB account so you can't login on any other devices.</p>

								<Button className="loginBtn loginBtn--nofb"
									onClick={this.disconnectFb}>
									Disconnect Facebook
								</Button> */}
						
							</> :
							<>
								{/* <h3>Connect Facebook</h3> */}
								

								<FacebookLogin
									appId={FB_APP_ID}
									callback={res => this.facebookConnected(res)}
									render={renderProps => <>{
										renderProps.isLoading ?
											<div className="fb-state-pending">
												Loading magic sparkles...
											</div>
											:
										renderProps.hasError ? 
											<div className="fb-state-error">
												Sorry, {renderProps.error.message}
											</div>
											:
										renderProps.hasAuthResponse ? 
											<div className="fb-state-pending">
												Connecting the wires...
											</div>
										:
										<>
											<button className="loginBtn loginBtn--facebook"
												onClick={renderProps.onClick}>
												Connect facebook
											</button>

											<p>Connect your FB account so you can play on any device and discover friends also playing SassyBox! We don’t post to Facebook.</p>
										</>
									}</>}
								/>
							</>}
						</div>

						
						<small style={{marginTop: ".75rem"}} className="version-info text-muted">
							App Version {this.state.ver.runningVer}<br/>
							Built {this.state.ver.runningBuildTime}<br/>
							User ID {ServerStore.currentUser.id}
						</small>

						{this.state.ver.needsUpdated && 
							<small style={{textAlign:"center",color:"yellow"}}>
								New version available, close app and re-open it to automatically install latest version
							</small>}
							
					</div>
			</div>
		</>}

		</div>
	}
}