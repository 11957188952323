import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';

import styles from './StatsWidget.module.scss';


export const StatsWidget = ({ cat }) => {

	if(!cat.stats)
		cat.stats = {};

	if(!cat.stats.streaks)
		cat.stats.streaks = {};

	const streaks = (Object.values(cat.stats.streaks) || []).sort((a, b) => a.seqNum - b.seqNum);

	return <div className={styles.stats}>
	
		{streaks.filter(x => !x.isDeleted && x.id).map((streak, idx) => 
			<div className={styles.streak + " " + (streak.newRecord ? styles.record : "") + " " + (idx === 0 ? styles.first : "")} key={streak.id}>
				
				<div className={styles.name}>
					<span className={styles.label}>{streak.name}</span>
				</div>

				{(streak.history || []).length > 1 && 
					<Sparklines data={streak.history} width={200} height={48}>
						<SparklinesLine color="white" />
					</Sparklines>
				}

				<div className={styles.value}>
					<div>
						<span className={styles.valueCurrent}>{streak.current}</span> / <span className={styles.valueRecord}>{streak.record}</span>
					</div>
					{streak.avg > 0 && <small className={styles.avg}>({streak.avg.toFixed(1)} avg)</small>}
				</div>
			</div>
		)}

		{!streaks.length &&
			<center>Finish the tutorial and then check back here!</center>
		}
	</div>
}