
import AssetLoader from 'utils/AssetLoader';
// import { faClipboard, faPlayCircle } from '@fortawesome/free-regular-svg-icons';

import stars_bg        from 'assets/space/Background-4.png';
import water_bg_center from 'assets/waterbg/pngs/middle.png';
import bg_img_burst_blue from 'assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';
import bg_img_bubble_blue from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_blue.png';
import bg_img_wave_blue from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue.png';

// import bg_img_burst_green from 'assets/simplebackgrounds/burst/burst_background_large_screen_green.png';
// import bg_img_bubble_green from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_green.png';
// import bg_img_wave_green from 'assets/simplebackgrounds/wave/wave_bg_large_screen_green.png';

import bg_img_burst_orange from 'assets/simplebackgrounds/burst/burst_background_large_screen_orange.png';
import bg_img_bubble_orange from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_orange.png';
import bg_img_wave_blue2 from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue_2.png';

import bg_img_burst_purple from 'assets/simplebackgrounds/burst/burst_background_large_screen_purple.png';
import bg_img_bubble_purple from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_purple.png';
import bg_img_wave_pink from 'assets/simplebackgrounds/wave/wave_bg_large_screen_pink.png';

import bg_img_burst_red from 'assets/simplebackgrounds/burst/burst_background_large_screen_red.png';
import bg_img_bubble_red from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_red.png';
import bg_img_wave_red from 'assets/simplebackgrounds/wave/wave_bg_large_screen_red.png';

// import bg_img_burst_yellow from 'assets/simplebackgrounds/burst/burst_background_large_screen_yellow.png';
// import bg_img_bubble_yellow from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_yellow.png';
// import bg_img_wave_yellow from 'assets/simplebackgrounds/wave/wave_bg_large_screen_yellow.png';

import bg_img_ice from 'assets/icebg/BACKGROUND1.png';

import bg_mountains from 'assets/parallaxingskybg/example/sky_background_mountains.png';



import stars_bg_sm        from 'assets/space/Background-4-256.png';
import water_bg_center_sm from 'assets/waterbg/pngs/middle-256.png';
import bg_img_burst_blue_sm from 'assets/simplebackgrounds/burst/burst_background_large_screen_blue-256.png';
import bg_img_bubble_blue_sm from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_blue-256.png';
import bg_img_wave_blue_sm from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue-256.png';

import bg_img_burst_orange_sm from 'assets/simplebackgrounds/burst/burst_background_large_screen_orange-256.png';
import bg_img_bubble_orange_sm from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_orange-256.png';
import bg_img_wave_blue2_sm from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue_2-256.png';

import bg_img_burst_purple_sm from 'assets/simplebackgrounds/burst/burst_background_large_screen_purple-256.png';
import bg_img_bubble_purple_sm from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_purple-256.png';
import bg_img_wave_pink_sm from 'assets/simplebackgrounds/wave/wave_bg_large_screen_pink-256.png';

import bg_img_burst_red_sm from 'assets/simplebackgrounds/burst/burst_background_large_screen_red-256.png';
import bg_img_bubble_red_sm from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_red-256.png';
import bg_img_wave_red_sm from 'assets/simplebackgrounds/wave/wave_bg_large_screen_red-256.png';

import bg_img_ice_sm from 'assets/icebg/BACKGROUND1-256.png';

import bg_mountains_sm from 'assets/parallaxingskybg/example/sky_background_mountains-256.png';



const bgKeys = {
	stars_bg,
	water_bg_center,

	bg_img_bubble_blue,
	bg_img_burst_blue,
	bg_img_wave_blue,
	
	bg_img_bubble_orange,
	bg_img_burst_orange,
	bg_img_wave_blue2,

	bg_img_bubble_purple,
	bg_img_burst_purple,
	bg_img_wave_pink,

	bg_img_bubble_red,
	bg_img_burst_red,
	bg_img_wave_red,

	bg_img_ice,

	bg_mountains,

	stars_bg_sm,
	water_bg_center_sm,

	bg_img_bubble_blue_sm,
	bg_img_burst_blue_sm,
	bg_img_wave_blue_sm,
	
	bg_img_bubble_orange_sm,
	bg_img_burst_orange_sm,
	bg_img_wave_blue2_sm,

	bg_img_bubble_purple_sm,
	bg_img_burst_purple_sm,
	bg_img_wave_pink_sm,

	bg_img_bubble_red_sm,
	bg_img_burst_red_sm,
	bg_img_wave_red_sm,

	bg_img_ice_sm,

	bg_mountains_sm,

};

export const imgForBg = (bgType, sm=false) => {
	const orig = bgType;
	if (bgType === 'colors')
		bgType = 'bg_img_burst_purple';

	if (bgType === 'me' || bgType === 'tutorial')
		bgType = 'bg_img_burst_blue';

	if (bgType === 'blue')
		bgType = 'bg_img_burst_blue';

	if (bgType === 'orange')
		bgType = 'bg_img_bubble_orange';

	if (bgType === 'blue2')
		bgType = 'bg_img_wave_blue2';

	if (bgType === 'purple')
		bgType = 'bg_img_burst_purple';
	
	if (bgType === 'pink')
		bgType = 'bg_img_wave_pink';

	// if (bgType !== 'mountains' && !bgType.startsWith('bg_img') && !['ice','water','stars'].includes(bgType))
	// 	bgType = "bg_img_wave_" + bgType;// + "_blue";

	if (bgType === 'mountains')
		bgType = 'bg_mountains';

	if (bgType === 'stars')
		bgType = 'stars_bg';

	if (bgType === 'water')
		bgType = 'water_bg_center';

	if (bgType === 'ice')
		bgType = 'bg_img_ice';

	const url = bgKeys[bgType +  (sm ? '_sm' : '')];

	if(!url)
		console.warn("[imgForBg]", { bgType, sm, orig, url });
	return AssetLoader.normalizeUrl(url);
}

export const colorForLevelBg = (bg, tileColor) => {
	if (bg === 'colors')
		bg = 'bg_img_burst_purple';
	if (bg === 'me' || bg === 'tutorial')
		bg = 'bg_img_burst_blue';
	
	if(!tileColor) {
		bg = bg + "";
		if (bg.includes('purple')) {
			tileColor = 'purple';
		} else
		if (bg.includes('red') || bg.includes('mountains')) {
			tileColor = 'cream';
		} else
		if (bg.includes('blue') || bg.includes('orange')) {
			tileColor = 'orange';
		} else
		if (bg.includes('pink')) {
			tileColor = 'pink';
		} else
		{ //if(["ice", "mountains", "water", "stars"].includes(bg)) {
			tileColor = 'silver';
		}
	}

	const tileThemeColors = {
		orange:   '#D35805',  // orange
		blue:     '#16B6B6', // blue
		purple:   '#600D6F', // purple,
		red:      '#8F1D21', // red
		silver:   '#CDD6D8', //#F1F1F1', // silver
		pink:     '#971C96', // pink
		cream:    '#EF2F7C', // cream
	};

	let themeColor = tileThemeColors[tileColor];
	
	// Sync theme colors better with these specific levels
	bg = bg + "";
	if (bg === 'ice') {
		themeColor = tileThemeColors.silver;
	} else
	if (bg === 'water') {
		themeColor = '#09A9CA';
	} else
	if (bg === 'mountains') {
		themeColor = '#D0A4BB'; //#1BD1FF';
	} else
	if (bg === 'stars') {
		themeColor = '#000000';
	} else
	if (bg.includes('blue2')) {
		themeColor = '#1A348C';
	} else
	if (bg.includes('blue')) {
		themeColor = '#84229D'; //#066464';
	}
	
	return { themeColor, tileColor };
}

export function normalizeWorldImg(world, useLargeImages=false) {
	if(!world)
		return world;

	if (world.state &&
		world.state.currentLevel) {
		const level = world.state.currentLevel;
		world.currentLevelNum = level.levelNum;
		world.currentLevelId = level.id;
	}
	// 	world.bgImgUrl = imgForBg(world.name === 'Color Surprrrise' ? 'bg_img_burst_purple' : level.bg);
	// 	world.bgColor  = colorForLevelBg(level.bg, level.tileColor).themeColor || 'black';
	// } else {
		world.bgImgUrl = imgForBg(world.name === 'Color Surprrrise' ? 'bg_img_burst_purple' : world.bg, !useLargeImages);
		world.bgColor  = colorForLevelBg(world.bg).themeColor || 'black';
	// }

	return world;
}