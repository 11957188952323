import React from 'react';


import styles from './PrivacyPage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function AboutPage({ ...props }) {
	return (<>
		<SubPageLayout title="What is SassyBox?">
			<div className={styles.root}>

				{/* <Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link> */}

				<h1>SassyBox Privacy</h1>

				<div className="content content--withSidebar content--sidebarAsTrack ">
					{/* <div className="content--sidebar margin--bottom-3">
						<aside className="jumpLinkNav" data-behavior="sticky">
							<header className="flex flex--middle jumpLinkNav__header" data-behavior="jump-link:header">
								<span>In this document</span>
								<div className="jumpLinkNav__icon">
									<svg xmlns="http://www.w3.org/2000/svg" className="icon icon--expand" width="19.4142132" height="11.1213207" viewBox="0 0 19.4142132 11.1213207">
										<path fill="#007C89" d="M9.707 11.121l-9.707-9.707 1.414-1.414 8.293 8.293 8.293-8.293 1.414 1.414z"></path>
									</svg>
									<svg xmlns="http://www.w3.org/2000/svg" className="icon icon--collapse" width="19.4142132" height="11.1213207" viewBox="0 0 19.4142132 11.1213207">
										<path fill="#007C89" d="M18 11.121l-8.293-8.293-8.293 8.293-1.414-1.414 9.707-9.707 9.707 9.707z"></path>
									</svg>
								</div>
							</header>
							<div className="jumpLinkNav__items" data-behavior="jump-link:list">
								<ul>
									<li className="jumpLinkNav__item">
										<a href="#1._The_Basics" className="cta">1. The Basics</a>
									</li><li className="jumpLinkNav__item">
										<a href="#2._Privacy_for_Users" className="cta">2. Privacy for Users</a>
									</li><li className="jumpLinkNav__item">
										<a href="#3._Privacy_for_Contacts" className="cta">3. Privacy for Contacts</a>
									</li><li className="jumpLinkNav__item">
										<a href="#4._Privacy_for_Visitors" className="cta">4. Privacy for Visitors</a>
									</li><li className="jumpLinkNav__item is-active">
										<a href="#5._General_Information" className="cta">5. General Information</a>
									</li>        
								</ul>

							</div>
						</aside>
					</div> */}
					<div className="content--main">
						<section className="helpArticle__section normalize" id="" data-behavior="highlight-section" data-module-id="articleText" data-entry-id="16D2FgpEpPkc7Vrif5L4K5">							
							
							<p>Sassybox takes data privacy seriously. This privacy policy explains who we are, how we collect, share and use Personal Information, and how you can exercise your privacy rights.</p>
							<p>We recommend that you read this privacy policy in full to ensure you are fully informed.</p>
						
							{/* <p>If you have any questions or concerns about our use of your Personal Information, then please contact us using the contact details provided at the end of Section 5.</p> */}
						
							{/* <div className="margin--top-3"><a href="//privacy.truste.com/privacy-seal/validation?rid=9a1ea7c0-f899-4ae9-af44-ead9f9bc6ca7" target="_blank"><img style="border: 0; padding: 0;" src="//privacy-policy.truste.com/privacy-seal/seal?rid=9a1ea7c0-f899-4ae9-af44-ead9f9bc6ca7" alt="TRUSTe"/></a>
							</div> */}
						</section>
						<section className="helpArticle__section normalize" id="1._The_Basics" data-behavior="highlight-section" data-module-id="articleText" data-entry-id="4y0y6GusG5qFtSY94BaXeQ" data-context-text="1. The Basics">

							<h2 className="h4">
								1. The Basics    </h2>
							<h6 id="heading+a.+about+us">A. About Us</h6>
							<p>Sassybox is an online game (the "<strong>Services</strong>") operated by Josiah Bryan, a person in the State of Michigan in the United States ("<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>," and "<strong>Sassybox</strong>").</p>
							<p>The Services enable our Users to use our game.</p>

						</section>
						<section className="helpArticle__section normalize" id="2._Privacy_for_Users" data-behavior="highlight-section" data-module-id="articleText" data-entry-id="jtmCRmtg9VldUX2Nm2HXm" data-context-text="2. Privacy for Users">

							<h2 className="h4">
								2. Privacy for Users    
							</h2>
							<p>This section applies to the Personal Information we collect and process from a User or potential User through the provision of the Services. <strong>In this section, "you" and "your" refer to Users and potential Users.</strong></p>

							<h6 id="heading+a.+information+we+collect">A. Information We Collect</h6>
							<p>The Personal Information that we may collect broadly falls into the following categories:</p>
							<p>(i) <strong>Information you provide to us</strong>: In the course of engaging with our Services, you may provide Personal Information about yous. Personal Information is often, but not exclusively, provided to us when you sign up for and use the Services, consult with our customer service team, send us an email, or communicate with us in any other way.</p>
							<p>We will let you know prior to collection whether the provision of Personal Information we are collecting is compulsory or if it may be provided on a voluntary basis and the consequences, if any, of not providing the information. By giving us this information, you agree to this information being collected.</p>
							<p>This information may include:</p>
							<ul>
								<li><p><strong>Registration information</strong>: You need a Sassybox account to use the Services as a User. When you  register for an account, you may be asked to provide certain basic information, such as your name, your email address, username, password, company name, occupation, location, and phone number.</p>
								</li>
								<li><p><strong>Billing and communications information</strong>: If you purchase our Services, you may also need to provide us with payment and billing information, such as your credit card details and billing address. Sassybox will also maintain a record of your billing transactions and purchases, and any communications and responses.</p>
								</li>
								
							</ul>

							<p>(ii) <strong>Information we collect automatically</strong>: When you use the Services, we may automatically collect certain information about your device and usage of the Services. We use cookies and other tracking technologies to collect some of this information.</p>
							<p>This information may include:</p>

							<ul>
								<li><p><strong>Device information</strong>: We collect information about the device and applications you use to access the Services, such as your IP address, your operating system, your browser ID, and other information about your system and connection.</p>
								</li>
								<li><p><strong>Log data</strong>: Our web servers keep log files that record data each time a device accesses those servers and those log files contain data about the nature of each access, including originating IP addresses. We may also access metadata and other information associated with files that you upload into our Services.</p>
								</li>
								<li><p><strong>Product usage data</strong>: We collect usage data about you whenever you interact with our Services, which may include the dates and times you access the Services and your browsing activities (such as what portions of the Services are used). We also collect information regarding the performance of the Services, including metrics related to the interactions with the Services. This information allows us to improve the content and operation of the Services, and facilitate research and analysis of the Services.</p>
								</li>
							</ul>
							
						<p>(iii) <strong>Information we collect from other sources</strong>: From time to time, we may obtain information about you or your Contacts from third-party sources, such as public databases, social media platforms, third-party data providers and our joint marketing partners. We take steps to ensure that such third parties are legally or contractually permitted to disclose such information to us.</p>
						<ul>
						<li>Examples of the information we receive from other sources include demographic information (such as age and gender), device information (such as IP addresses), location (such as city and state), and online behavioral data (such as information about use of social media websites, page view information and search results and links). We use this information, alone or in combination with other information (including Personal Information) we collect, to enhance our ability to provide relevant marketing and content to you and to develop and provide you with more relevant products, features, and services.</li>
						</ul>
						<p>(iv) <strong>Information from the use of our mobile apps</strong>: When you use our mobile apps, we may collect certain device and usage-related information in addition to information described elsewhere in this privacy policy.</p>
						<ul>
						<li><p><strong>Device information</strong>: We may collect information about the type of device and operating system you use. We do not ask for, access, or track any location-based information from your mobile device at any time while downloading or using our mobile apps or Services.</p>
						</li>
						<li><p><strong>Product usage data</strong>: We may use mobile analytics software (such as <a href="https://get.fabric.io/">Fabric.io</a>) to better understand how people use our application. We may collect information about how often you use the application and other performance data.</p>
						</li>
						</ul>
						<h6 id="heading+b.+use+of+personal+information">B. Use of Personal Information</h6>
						<p>We may use the Personal Information we collect through the Services or other sources for a range of reasons, including:</p>
						<ul>
						<li><strong>To bill and collect money owed to us by you.</strong> This includes sending you emails, invoices, receipts, notices of delinquency, and alerting you if we need a different credit card number. We use third parties for secure credit card transaction processing, and those third parties collect billing information to process your orders and credit card payments. To learn more about the steps we take to safeguard that data, see the "Our Security" section of this privacy policy.</li>
						<li><strong>To send you system alert messages.</strong> For example, we may inform you about temporary or permanent changes to our Services, such as planned outages, or send you account, security or compliance notifications, such as new features, version updates, releases, abuse warnings, and changes to this privacy policy.</li>
						<li><strong>To communicate with you about your account and provide customer support.</strong> For example, if you use our mobile apps, we may ask you if you want to receive push notifications about activity in your account. If you have opted in to these push notifications and no longer want to receive them, you may turn them off through your operating system.</li>
						<li><strong>To enforce compliance with our Terms of Use and applicable law, and to protect the rights and safety of our Users and third parties, as well as our own.</strong> This may include developing tools and algorithms that help us prevent violations. Please do not use Sassybox to send confidential information.</li>
						<li><strong>To meet legal requirements</strong>, including complying with court orders, valid discovery requests, valid subpoenas, and other appropriate legal mechanisms.</li>
						<li><strong>To provide information to representatives and advisors</strong>, including attorneys and accountants, to help us comply with legal, accounting, or security requirements.</li>
						<li><strong>To prosecute and defend a court, arbitration, or similar legal proceeding.</strong></li>
						<li><strong>To respond to lawful requests by public authorities</strong>, including to meet national security or law enforcement requirements.</li>
						<li><strong>To provide, support and improve the Services.</strong> For example, this may include sharing your information with third parties in order to provide and support our Services or to make certain features of the Services available to you. When we share Personal Information with third parties, we take steps to protect your information by requiring these third parties to enter into a contract with us that requires them to use the Personal Information we transfer to them in a manner that is consistent with this privacy policy and applicable privacy laws.</li>
						<li><strong>To provide suggestions to you.</strong> This includes adding features that compare Users’ email campaigns, using data to suggest other publishers your Contacts may be interested in, or using data to suggest products or services that you may be interested in or that may be relevant to you or your Contacts. Some of these suggestions are generated by use of our data analytics projects, which are described below.</li>
						<li><strong>For our data analytics projects.</strong> Our data analytics projects use data from Sassybox accounts, including Personal Information of Users, to provide and improve the Services. We use information like your playing habits and your details and purchase history, so we can make more informed predictions, decisions, and products for our Users. If you not to share this data, you can opt out of data analytics projects at any time by emailing us at <a href="mailto:personaldatarequests@sassybox.app">personaldatarequests@sassybox.app</a>. For example, we use data from Sassybox accounts to enable product recommendation, audience segmentation, and predicted demographics features for our Users. We will act as a data controller to process data for our data analytics projects in reliance on our legitimate business interests of improving and enhancing our products and services for our Users. As always, we take the privacy of Personal Information seriously, and will continue to implement appropriate safeguards to protect this Personal Information from misuse or unauthorized disclosure.</li>
						<li><strong>Combined information.</strong> We may combine Personal Information with other information we collect or obtain about you (such as information we source from our third-party partners) to serve you specifically, such as to deliver a product or service according to your preferences or restrictions, or for advertising or targeting purposes in accordance with this privacy policy. When we combine Personal Information with other information in this way, we treat it as, and apply all of the safeguards in this privacy policy applicable to, Personal Information.</li>
						<li><strong>To enable your use of the integrations and plugins you choose to connect to your Sassybox account.</strong>  For instance, if you choose to connect a Google integration to your Sassybox account, we'll ask you to grant us permission to view and/or download, as applicable, your Google Sheets, Google Contacts, Google Analytics and Google Drive. This allows us to configure your integration(s) in accordance with your preferences. For example, if you wanted to use the Google Contacts integration to share the templates in your Sassybox account with contacts in your Google address book, we would need to access your Google Contacts to share the templates.</li>
						<li><strong>Other purposes.</strong> To carry out other legitimate business purposes, as well as other lawful purposes about which we will notify you.</li>
						</ul>
						<h6 id="heading+c.+cookies+and+tracking+technologies">C. Cookies and Tracking Technologies</h6>
						<p>We and our partners may use various technologies to collect and store information when you use our Services, and this may include using cookies and similar tracking technologies, such as pixels and web beacons. For example, we use web beacons in the emails we send. These web beacons track certain behavior such as whether the email sent through the Services was delivered and opened and whether links within the email were clicked. They also allow us to collect information such as the recipient’s IP address, browser, email client type and other similar details. We use this information to measure the performance of your email campaigns, and to provide analytics information and enhance the effectiveness of our Services. Reports are also available to us when we send email to you, so we may collect and review that information.</p>
						
						<h6 id="heading+e.+other+data+protection+rights">E. Other Data Protection Rights</h6>
						<p>You and your Contacts may have the following data protection rights:</p>
						<ul>
						<li><strong>To access, correct, update or request deletion</strong> of Personal Information. Sassybox takes reasonable steps to ensure that the data we collect is reliable for its intended use, accurate, complete and up to date. As a User, you may contact us directly by emailing us at <a href="mailto:personaldatarequests@sassybox.app">personaldatarequests@sassybox.app</a>. You can also manage information about your Contacts within the dashboard provided through the Sassybox platform to assist you with responding to requests to access, correct, update or delete information that you receive from your Contacts.</li>
						<li>In addition, individuals who are residents of the EEA can <strong>object to processing</strong> of their Personal Information, ask to <strong>restrict processing</strong> of their Personal Information or <strong>request portability</strong> of their Personal Information. You can exercise these rights by contacting us using the contact details provided in the "Questions and Concerns" section below. If any of your Contacts wishes to exercise any of these rights, they should contact you directly, or contact us as described in the "Privacy for Contacts" section below.</li>
						<li>Similarly, if Personal Information is collected or processed on the basis of consent, the data subject can <strong>withdraw their consent</strong> at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Information conducted in reliance on lawful processing grounds other than consent. If you receive these requests from Contacts, you can segment your lists within the Sassybox platform to ensure that you only market to Contacts who have not opted out of receiving such marketing.</li>
						<li>The <strong>right to complain to a data protection authority</strong> about the collection and use of Personal Information. For more information, please contact your local data protection authority. Contact details for data protection authorities in the EEA are available <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>.</li>
						</ul>
						<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection law. We may ask you to verify your identity in order to help us respond efficiently to your request. </p>
						</section>

						
					<section className="helpArticle__section normalize" id="5._General_Information" data-behavior="highlight-section" data-module-id="articleText" data-entry-id="2z4XXBCGKnfPSdGb0bulil" data-context-text="5. General Information">

							<h2 className="h4">
								3. General Information    </h2>
							<h6 id="heading+a.+how+we+share+information">A. How We Share Information</h6>
						<p>We may share and disclose your Personal Information to the following types of third parties for the purposes described in this privacy policy <strong>(for purposes of this section, "you" and "your" refer to Users unless otherwise indicated)</strong>:</p>
						<p>(i) <strong>Our service providers</strong>: Sometimes, we share your information with our third-party service providers, who help us provide and support our Services and other business-related functions.</p>
						<p>For example, if it is necessary to provide Users something they have requested (like enable a feature such as Social Profiles), then we may share Users’ Personal Information with a service provider for that purpose. Other examples include analyzing data, hosting data, engaging technical support for our Services, processing payments, and delivering content.</p>
						<p>These third-party service providers enter into a contract that requires them to use your Personal Information only for the provision of services to us and in a manner that is consistent with this privacy policy.</p>
						
						<p>(ii) <strong>Advertising partners</strong>: We may partner with third-party advertising networks and exchanges to display advertising on our Websites or to manage and serve our advertising on other sites and may share Personal Information of Users and Visitors with them for this purpose. We and our third-party partners may use cookies and other tracking technologies, such as pixels and web beacons, to gather information about your activities on our Websites and other sites in order to provide you with targeted advertising based on your browsing activities and interests.</p>
						<p>(iii) <strong>Any competent law enforcement body, regulatory body, government agency, court or other third party</strong> where we believe disclosure is necessary (a) as a matter of applicable law or regulation, (b) to exercise, establish, or defend our legal rights, or (c) to protect your vital interests or those of any other person.</p>
						<p>(iv) <strong>A potential buyer (and its agents and advisors)</strong> in the case of a sale, merger, consolidation, liquidation, reorganization, or acquisition. In that event, any acquirer will be subject to our obligations under this privacy policy, including your rights to access and choice. We will notify you of the change either by sending you an email or posting a notice on our Website.</p>
						<p>(v) <strong>Any other person with your consent.</strong></p>
						<h6 id="heading+b.+legal+basis+for+processing+personal+information+%28eea+persons+only%29">B. Legal Basis for Processing Personal Information (EEA Persons Only)</h6>
						<p>If you are from the European Economic Area, our legal basis for collecting and using the Personal Information described above will depend on the Personal Information concerned and the specific context in which we collect it.</p>
						<p>However, we will normally collect and use Personal Information from you where the processing is in our <strong>legitimate interests</strong> and not overridden by your data-protection interests or fundamental rights and freedoms. Typically, our legitimate interests include improving, maintaining, providing, and enhancing our technology, products, and services; ensuring the security of the Services and our Websites; and for our marketing activities.</p>
						<p>If you are a User, we may need the Personal Information to <strong>perform a contract</strong> with you. In some limited cases, we may also have a <strong>legal obligation</strong> to collect Personal Information from you.</p>
						<p>If we ask you to provide Personal Information to comply with a legal requirement or to perform a contact with you, we will make this clear at the relevant time and advise you whether the provision of your Personal Information is mandatory or not, as well as of the possible consequences if you do not provide your Personal Information.</p>
						<p>Where required by law, we will collect Personal Information only where we have your <strong>consent</strong> to do so.</p>
						<p>If you have questions about or need further information concerning the legal basis on which we collect and use your Personal Information, please contact us using the contact details provided in the "Questions and Concerns" section below.</p>
						<h6 id="heading+c.+your+choices+and+opt-outs">C. Your Choices and Opt-Outs</h6>
						<p>Users and Visitors who have opted in to our marketing emails can opt out of receiving marketing emails from us at any time by clicking the "unsubscribe" link at the bottom of our marketing messages.</p>
						<p>Also, all opt-out requests can be made by emailing us using the contact details provided in the "Questions and Concerns" section below. Please note that some communications (such as service messages, account notifications, billing information) are considered transactional and necessary for account management, and Users cannot opt out of these messages unless you cancel your Sassybox account.</p>
						<h6 id="heading+d.+our+security">D. Our Security</h6>
						<p>We take appropriate and reasonable technical and organizational measures to protect Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking into account the risks involved in the processing and the nature of the Personal Information. For further information about our security practices, please see our Security page <a href="/about/security/">here</a>. If you have any questions about the security of your Personal Information, you may contact us at <a href="mailto:privacy@sassybox.app">privacy@sassybox.app</a>.</p>
						<p>Sassybox accounts require a username and password to log in. Users must keep their username and password secure, and never disclose it to a third party. Because the information in a User’s Sassybox account is so sensitive, account passwords are hashed, which means we cannot see a User’s password. We cannot resend forgotten passwords either. We will only provide Users with instructions on how to reset them.</p>
						<h6 id="heading+e.+international+transfers">E. International Transfers</h6>
						<p>(i) <strong>We operate in the United States</strong></p>
						<p>Our servers and offices are located in the United States, so your information may be transferred to, stored, or processed in the United States. While the data protection, privacy, and other laws of the United States might not be as comprehensive as those in your country, we take many steps to protect your privacy, including offering our Users a Data Processing Agreement.</p>
						
						<p>A list of Privacy Shield participants is maintained by the Department of Commerce and is available <a href="https://www.privacyshield.gov/list">here</a>.</p>
						<p>Sassybox is responsible for the processing of Personal Information we receive under each Privacy Shield Framework and subsequently transfer to a third party acting as an agent on our behalf. We comply with the Privacy Shield Principles for all onward transfers of Personal Information from the EU and Switzerland, including the onward transfer liability provisions.</p>
						<p>With respect to Personal Information received or transferred pursuant to the Privacy Shield Frameworks, we are subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, we may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
						<p>If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third-party dispute resolution provider (free of charge to you) at <a href="https://feedback-form.truste.com/watchdog/request">https://feedback-form.truste.com/watchdog/request</a>. Under certain conditions, more fully described on the Privacy Shield website, <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">here</a>, you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted.</p>
						<p>Users located in Switzerland and the EU are subject to our <a href="/legal/data-processing-addendum">Data Processing Addendum</a>, as described in our <a href="/legal/terms/">Terms of Use</a>.</p>
						<p>(iii) <strong>Users located in Australia</strong></p>
						<p>If you are a User who lives in Australia, this section applies to you. We are subject to the operation of the Privacy Act 1988 ("<strong>Australian Privacy Act</strong>"). Here are the specific points you should be aware of:</p>
						<ul>
						<li>Where we say we assume an obligation about Personal Information, we are also requiring our subcontractors to undertake a similar obligation, where relevant.</li>
						<li>We will not use or disclose Personal Information for the purpose of our direct marketing to you unless you have consented to receive direct marketing; you would reasonably expect us to use your personal details for the marketing; or we believe you may be interested in the material but it is impractical for us to obtain your consent. You may opt out of any marketing materials we send to you through an unsubscribe mechanism or by contacting us directly. If you have requested not to receive further direct marketing messages, we may continue to provide you with messages that are not regarded as "direct marketing" under the Australian Privacy Act, including changes to our terms, system alerts, and other information related to your account.</li>
						<li>Our servers are located in the United States. In addition, we or our subcontractors may use cloud technology to store or process Personal Information, which may result in storage of data outside Australia. It is not practicable for us to specify in advance which country will have jurisdiction over this type of offshore activity. All of our subcontractors, however, are required to comply with the Australian Privacy Act in relation to the transfer or storage of Personal Information overseas.</li>
						<li>If you think the information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, we will take reasonable steps, consistent with our obligations under the Australian Privacy Act, to correct that information upon your request.</li>
						<li>If you are unsatisfied with our response to a privacy matter then you may consult either an independent advisor or contact the Office of the Australian Information Commissioner for additional help. We will provide our full cooperation if you pursue this course of action.</li>
						</ul>
						<h6 id="heading+f.+retention+of+data">F. Retention of Data</h6>
						<p>We retain Personal Information where we have an ongoing legitimate business or legal need to do so. Our retention periods will vary depending on the type of data involved, but, generally, we'll refer to these criteria in order to determine retention period:</p>
						<ul>
						<li>Whether we have a legal or contractual need to retain the data.</li>
						<li>Whether the data is necessary to provide our Services.</li>
						<li>Whether our Users have the ability to access and delete the data within their Sassybox accounts.</li>
						<li>Whether our Users would reasonably expect that we would retain the data until they remove it or until their Sassybox accounts are closed or terminated.</li>
						</ul>
						<p>When we have no ongoing legitimate business need to process your Personal Information, we will either delete or anonymize it or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.</p>
						<h6 id="heading+g.+california+privacy">G. California Privacy</h6>
						<p>Under California Law, California residents have the right to request in writing from businesses with whom they have an established business relationship, (a) a list of the categories of Personal Information, such as name, email, and mailing address, and the type of services provided to the customer that a business has disclosed to third parties (including affiliates that are separate legal entities) during the immediately preceding calendar year for the third-parties’ direct marketing purposes, and (b) the names and addresses of all such third parties. To request the above information, please email us at <a href="mailto:privacy@sassybox.app">privacy@sassybox.app</a>.</p>
						<h6 id="heading+h.+changes+to+this+policy">H. Changes to this Policy</h6>
						<p>We may change this privacy policy at any time and from time to time. The most recent version of the privacy policy is reflected by the version date located at the top of this privacy policy. All updates and amendments are effective immediately upon notice, which we may give by any means, including, but not limited to, by posting a revised version of this privacy policy or other notice on the Websites. We encourage you to review this privacy policy often to stay informed of changes that may affect you. Our electronically or otherwise properly stored copies of this privacy policy are each deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this privacy policy that was in effect on each respective date you visited the Website.</p>
						<h6 id="heading+i.+questions+%26amp%3b+concerns">I. Questions &amp; Concerns</h6>
						<p>If you have any questions or comments, or if you have a concern about the way in which we have handled any privacy matter, please use our <a href="/contact/">contact form</a> to send us a message. You may also contact us by email at:</p>
						<p><strong>For EEA Residents:</strong></p>
						<p>For the purposes of EU data protection legislation, Sassybox is the controller of your Personal Information. Our Data Protection Officer can be contacted at <a href="mailto:dpo@sassybox.app">dpo@sassybox.app</a>.</p>
						<p><strong>For Non-EEA Residents:</strong></p>
						<p>Sassybox<br/>
						<a href="mailto:privacy@sassybox.app">privacy@sassybox.app</a><br/>
						</p>
						</section>
					</div>
				</div>
				
			</div>

		</SubPageLayout>
	</>);
}