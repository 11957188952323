import React from 'react';
import { ServerStore, gtag } from "./ServerStore";
import { DOLLAR_COST_PER_STAR } from './moneyUtils';
import { numberWithCommas }     from './numberWithCommas';
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { KITTY_ITEMS } from '../game/KittyActor';

import {StripeProvider, Elements, injectStripe, CardElement} from 'react-stripe-elements';

import { injectStripe as bootStripe } from './injectStripe';
import { AdMobUtil } from './AdMob';
import { WelcomeWidget } from '../game/scenes/WelcomeScene/widgets/WelcomeWidget';

const showSwalLoader = (text="", title="") => {
	window.AlertManager.fire({
		title: '', //(title || "Loading..."),
		type: '',
		html: (<>
			<center>
				<p>{text || "Please wait, processing ..."}</p>
				<FontAwesomeIcon icon={faStar} spin size="lg"/>
			</center>
		</>),
		showConfirmButton: false, // hide default OK button
		showCloseButton: false, // show close button top-right
	});
}


// This is the publishable key for Josiah Bryan account
const stripeApiKey = process.env.NODE_ENV === 'production' ?
	'pk_live_gJ6qg9PoGWBkVwhHsUro1ljT' :
	'pk_test_OTTxOVmYG0ILT8hfbgolRLbQ' ;

// // NB just for testing even in live, must match stripe-client mode (test/prod) on server
// const stripeApiKey = 'pk_test_OTTxOVmYG0ILT8hfbgolRLbQ';

const enableStripe = widget => {
	const Widget = injectStripe(widget);

	return props => <StripeProvider apiKey={stripeApiKey}>
		<Elements>
			<Widget {...props}/>
		</Elements>
	</StripeProvider>;
}

const CardInput = enableStripe(props => {
	if (props.getStripe)
		props.getStripe(props.stripe);

	// Match HTML font size based
	const dpi = window.devicePixelRatio,
		fontSize = 
			dpi >= 3.0 ? 24/2 :
			dpi >= 2.0 ? 24/2 :
			dpi >= 1.0 ? 32/1.5 : 32/1.5;

	return <CardElement className="form-control swal-custom-cc-input" 
		style={{
			base: {
				// 'lineHeight': '1.35', // can't set lineHeight - makes card icon not work
				'fontSize': fontSize + 'px', //1.14286rem', // ~16px to match the rest of the form
				'color': '#495057',
				'fontFamily': 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
			}
		}} />;
});

class _BuyItemButton extends React.Component {

	constructor(props) {
		super(props);

		const { item: itemDef, world, offer: purchaseOffer } = props;

		const units = world ? 0 : itemDef.dollarUnitSize || itemDef.unitSize,
			pennies = parseFloat(purchaseOffer) * 100;

		// For full documentation of the available paymentRequest options, see:
		// https://stripe.com/docs/stripe.js#the-payment-request-object
		const paymentRequest = props.stripe.paymentRequest({
			country: 'US',
			currency: 'usd',
			total: {
				label:  world ? `Unlock ${world.name}` : `${units} of ${itemDef.name}`,
				amount: pennies,
			},
		});

		// Store namespace for this button instance
		this._mns = "game.buy.button." + (world ? "world" : "item") + "." + (world ? world.id : itemDef.id);
		this._mid = world ? world.id : null;

		// Record initial visibility
		ServerStore.metric(this._mns+".shown", this._mid);

		paymentRequest.on('token', ({complete, token, ...data}) => {
			console.log('Received Stripe token: ', token);
			console.log('Received customer information: ', data);
			// Notify metric server
			ServerStore.metric(this._mns + ".pay_req_api.success", this._mid);
			this.processPurchaseToken(token);
			complete('success');
		});
	
		paymentRequest.canMakePayment().then((result) => {
			// console.log("[BuyItemButton.paymentRequest] canMakePayment result=", result);
			// Notify metric server
			// Update: Depreciated this metric
			// ServerStore.metric(this._mns + ".pay_req_api." + (result ? "enabled" : "unavailable"), this._mid);

			this.setState({canMakePayment: !!result});
		});
	
		this.state = {
			canMakePayment: false,
			paymentRequest,
			units,
		};

		// Trigger buy action right away
		if (this.props.immediateBuy)
			this.buttonClicked();
	}
	
	render() {
		const { offer } = this.props || {};
		return <>
			<Button color="warning"  className="swal2-styled swal2-confirm" onClick={evt => this.buttonClicked(evt)}>
				{this.props.customBuyLabel || <>Buy {this.state.units || ""} for ${numberWithCommas(offer.toFixed(2))}</>}
			</Button>
		</>;
	}

	buttonClicked() {
		const { item: itemDef, world, offer: purchaseOffer } = this.props || {};

		if(MarketUtils.iapMenu && window.inAppPurchase) {
			// Close the modal so it doesn't stay visible behind IAP dialog
			// Close on a timer because sometimes the IAP dialog takes a moment to boot
			setTimeout(() => {
				window.AlertManager.close();
			}, 250); 

			// Cordova IAP plugin is online (https://github.com/lilmnm-kamikaze-/customIAP)
			const iapId = world ? 
				MarketUtils.UNLOCK_WORLD_IAP.iapId : 
				itemDef.iapId;
			ServerStore.metric(this._mns + ".iap.shown", this._mid, { iapId });

			const purchaseMethod = itemDef && itemDef.isSubscription ? 'subscribe' : 'buy';

			window.inAppPurchase[purchaseMethod](iapId)
				.then(data => {
					ServerStore.metric(this._mns + ".iap.success", this._mid, { iapId, purchase: data });

					// Mark this item as consumed (TODO: everything is consumed, except subscriptions...)
					if(purchaseMethod === 'buy') { //!itemDef || (itemDef && !itemDef.isSubscription)) {
						window.inAppPurchase.consume(
							data.productType,
							data.receipt,
							data.signature
						).then(result => {
							ServerStore.metric(this._mns + ".iap.consume.success", this._mid, { iapId, result });
						})
						.catch(error => {
							ServerStore.metric(this._mns + ".iap.consume.error",   this._mid, { iapId, error });
						});
					}

					// Send to server
					return this.processPurchaseToken(data, 'iap');
				})
				.catch(error => {
					if(error && error.errorCode === -5) {
						window.AlertManager.close();
						
						ServerStore.metric(this._mns + ".iap.canceled", this._mid, { iapId, message: error });
						
						this.props.onPurchaseResult({ canceled: true });

					} else {
						ServerStore.metric(this._mns + ".iap.error",    this._mid, { iapId, error });

						console.error("[BuyItemButton] IAP Error:", error);

						this.props.onPurchaseResult({ 
							error: "Sorry, we encountered a problem completing your purchase. Please rest assured, the error was captured for our team to look at."
						}, 'iap');

						return { error };
					}
				});
		}
		else
		if (this.state.canMakePayment) {
			ServerStore.metric(this._mns + ".pay_req_api.shown", this._mid);
		// try {
			/*const res =*/ this.state.paymentRequest.show();
		// 	console.log("Result of show: ", res);
		// } catch(err) {
		// 	console.warn("Error showing native payment request:", err,", continuing with stripe modal...");
		} else {
			ServerStore.metric(this._mns + ".stripe_popup.shown", this._mid);

			const price = numberWithCommas(purchaseOffer.toFixed(2));
			// show stripe popup 
			window.AlertManager.fire({
				title: world ? `Unlock ${world.name}` : `${this.state.units} ${itemDef.name}s`,
				type: '',
				html: (<>
					<p>Enter a credit card to {world ? 
						<>unlock {world.name}</> : 
						<>buy {this.state.units} {itemDef.name}s for ${price}</>
					}</p>

					<CardInput getStripe={stripe => this.cardInputStripe=stripe}/>

					<Button color="success" className="swal2-styled swal2-confirm swal2-confirm"  onClick={() => this.processCardInput()}>
						Pay ${price}
					</Button>

					<Button color="default" className="swal2-styled" onClick={() => {
						window.AlertManager.close();
						ServerStore.metric(this._mns + ".stripe_popup.canceled", this._mid);
						this.props.onPurchaseResult({ canceled: true });
					}}>
						Cancel
					</Button>
				</>),
				showConfirmButton: false, // hide default OK button
				showCloseButton: true, // show close button top-right
			});
		}
	}

	async processCardInput() {
		if(!this.cardInputStripe) {
			throw new Error("this.cardInputStripe not defined yet");
		}

		// Within the context of `Elements`, this call to createToken knows which Element to
		// tokenize, since there's only one in this group.
		const { token } = await this.cardInputStripe.createToken({ name: ServerStore.currentUser.name }); //.then(({token}) => {
		
		if(token) {
			console.log('Received Stripe token:', { token });
			ServerStore.metric(this._mns + ".stripe_popup.token_success", this._mid);
			this.processPurchaseToken(token);
		} else {
			ServerStore.metric(this._mns + ".stripe_popup.local_card_trouble", this._mid);

			// TODO: Can we fire another SWAL above the card SWAL?
			alert("Sorry, trouble with the credit card you entered, please try again.")
		}
	}

	async processPurchaseToken(token, method='stripe') {
		window.AlertManager.close();

		const { item: itemDef, world } = this.props || {};

		showSwalLoader(world ? "Unlocking " + world.name + "..." : "Adding item to inventory...");

		const packet = method === 'iap' ? { iap: token } : { token },
			  result = world ?
				await ServerStore.unlockWorld(world,    packet) :
				await ServerStore.purchaseItem(itemDef, packet);

		if(result.error) {
			ServerStore.metric(this._mns + ".error", this._mid, { error: result.error });
		} else {
			ServerStore.metric(this._mns + ".success", this._mid);

			// Two metrics for same value, because .metric gets sent straight to mixpanel and our server as listed,
			// whereas MixPanel handles .countMetric() on a per-user basis instead of a global metric.
			// TODO: We could remove this one (game.spent.dollars) and just use game.count.dollars_spent in future
			// if we find this one redundant
			ServerStore.metric("game.spent.dollars", parseFloat(this.props.offer), { spentFor: this._mns });

			// Increment counter for this user (mainly for MixPanel's benefit, but could be useful later...)
			// NB: our ServerStore code special-cases "game.count.dollars_spent" when sending to MixPanel,
			// and also calls mixpanel.people.track_charge
			ServerStore.countMetric("game.count.dollars_spent", parseFloat(this.props.offer), { spentFor: this._mns });
		}

		this.props.onPurchaseResult(result, method);
	}
}

const BuyItemButton = enableStripe(_BuyItemButton);

export class MarketUtils {

	// Used externally in Login to try to get stripe loaded before we need to purchase anything
	// so there is no delay. However, if called again, it's a NOOP, so we can safely await it just to
	// ensure it's loaded in routines below
	static _inited = false;
	static async init() {
		if(this._inited)
			return;
		
		// Start stripe
		bootStripe();
		// console.warn( { KITTY_ITEMS } );

		// Setup here because KITTY_ITEMS is undef before init() for some
		KITTY_ITEMS.sub_no_ads.onSubscribe = () => {
			AdMobUtil.hideBanner();
			if (WelcomeWidget.handle)
				WelcomeWidget.handle.hideCloseAdButton();
		};

		// Need this here because not defined anywhere else
		this.UNLOCK_WORLD_IAP = {
			iapId: 'sassybox.unlock_world',
			name: 'Unlock Level Pack',
			dollarCost: 0.99,
		};

		// Get ready for IAP
		const iapIdHash = {};
		Object.values(KITTY_ITEMS).forEach(itemDef => iapIdHash[itemDef.iapId] = true);
		
		const iapIdList = [
			MarketUtils.UNLOCK_WORLD_IAP.iapId,
			...Object.keys(iapIdHash)
		];

		// console.log("[MarketUtils] debug: iapIdList:", iapIdList);

		// Init the cordova inAppPurchase plugin
		if(window.inAppPurchase) {
			const iapMenu = await window.inAppPurchase.getProducts(iapIdList);
			
			console.log("[inAppPurchase.getProducts] returned value:", iapMenu);

			this.iapMenu = iapMenu; // TODO: Do we even need this? getProducts() just has to init the plugin, right?

			// onAdLoaded
			// // onAdFailLoad
			// // onAdPresent
			// // onAdDismiss
			// // onAdLeaveApp
			document.addEventListener('onAdPresent',  event => console.warn( "[event:AdLoaded]",   event));
			document.addEventListener('onAdFailLoad', event => console.error("[event:AdFailLoad]", event));
			document.addEventListener('onAdLoaded',   event => console.log(  "[event:AdPresent]",  event));
			document.addEventListener('onAdDismiss',  event => console.warn( "[event:AdDismiss]",  event));
			document.addEventListener('onAdLeaveApp', event => console.warn( "[event:AdLeaveApp]", event));

			let buySub;
			document.addEventListener('onAdDismiss', buySub = (/*event*/) => {
				const immediateBuy = Math.random() > 0.75;
				ServerStore.metric("game.ads.ad_dismiss_purchase_prompt." + (immediateBuy ? "immediate_buy" : "pre_buy_prompt"));
				MarketUtils.purchaseItem(KITTY_ITEMS.sub_no_ads, immediateBuy);
			});
			document.addEventListener('onAdLeaveApp', buySub);
			document.addEventListener('onAdLeaveApp', event => {
				ServerStore.metric("game.ads.user_clicked_ad", null, event);
			});
		} else {
			// for non-phonegap modes (mainly for testing)
			document.addEventListener('onAdDismiss', (/*event*/) => {
				const immediateBuy = Math.random() > 0.75;
				ServerStore.metric("game.ads.ad_dismiss_purchase_prompt." + (immediateBuy ? "immediate_buy" : "pre_buy_prompt"));
				MarketUtils.purchaseItem(KITTY_ITEMS.sub_no_ads, immediateBuy);
			});
		}
	}
	
	/**
	 * 
	 * @static
	 * @param {*} itemDef - from KittyActor/KITTY_ITEMS 
	 * @param {boolean} [immediateBuy=false] If true, <BuyItemButton> will be clicked automatically as soon as it renders, triggering the appros platform action
	 * @memberof MarketUtils
	 */
	static async purchaseItem(itemDef, immediateBuy=false) {
		return new Promise(async resolve => {
			// Init Stripe, NOOP if already init'd
			await this.init();

			const validated = KITTY_ITEMS[itemDef.id];
			if(!validated)
				throw new Error("Invalid item");

			this._purchasePromise = resolve;

			ServerStore.metric("game.market.item." + itemDef.id + ".popup.shown");

			const haveStars = parseFloat(ServerStore.currentCat.stars),
				needStars = parseFloat(itemDef.starCost),
				remaining = haveStars - needStars,
				//purchaseOffer = remaining <= 0 ? (itemDef.dollarCost ? itemDef.dollarCost : needStars * DOLLAR_COST_PER_STAR) : 0;
				purchaseOffer = parseFloat(itemDef.dollarCost ? itemDef.dollarCost : needStars * DOLLAR_COST_PER_STAR);

			window.AlertManager.fire({
				title: itemDef.customOfferTitle || itemDef.name,
				type: '',
				html: (<>
					{itemDef.customOfferText ? 
						itemDef.customOfferText :
						<p>It costs  {numberWithCommas(needStars)}<FontAwesomeIcon icon={faStar} size="xs"/> to purchase this item. 
							You have {numberWithCommas(haveStars)}<FontAwesomeIcon icon={faStar} size="xs"/>. Buy this item?</p>
					}

					{purchaseOffer > 0 && <BuyItemButton
						item={itemDef}
						offer={purchaseOffer}
						onPurchaseResult={(result, method) => this._itemPurchaseResult(result, itemDef, method)}
						customBuyLabel={itemDef.customBuyLabel}
						immediateBuy={immediateBuy}
					/>}
					
					{needStars > 0 && remaining >= 0 &&
						<Button color="success" className="swal2-styled swal2-confirm swal2-confirm-secondary"  onClick={() => this._purchaseItemStars(itemDef, needStars)}>
							Get {itemDef.unitSize} for {numberWithCommas(needStars)} Stars
						</Button>
					}

					<Button color="default" className="swal2-styled" onClick={() => { 
						window.AlertManager.close(); 
						ServerStore.metric("game.market.item." + itemDef.id + ".popup.canceled");
						resolve();
					}}>
						Don't Unlock
					</Button>
				</>),
				showConfirmButton: false, // hide default OK button
				showCloseButton: true, // show close button top-right
			});
		});
	}

	static async _purchaseItemStars(itemDef, stars) {
		window.AlertManager.close();

		ServerStore.metric("game.market.item." + itemDef.id + ".popup.stars_chosen");

		const result = await ServerStore.purchaseItem(itemDef, { stars });

		return this._itemPurchaseResult(result, itemDef, 'stars');
	}

	static async _itemPurchaseResult(result, itemDef, method) {

		if(result.success) {
			ServerStore.metric("game.market.item." + itemDef.id + ".popup.success");

			// Update google analytics
			// this is async, so no delay is incurred here other than the cost of a .push() operation that gtag uses (e.g. no cost at all)
			gtag('event', 'item_purchased_' + method, { 
				event_label:    itemDef.id,
				event_category: 'items'
			});

			if(itemDef.isSubscription) {
				if (itemDef.onSubscribe)
					itemDef.onSubscribe();

				await window.AlertManager.fire({
					type: 'success',
					title: itemDef.name + " Purchased!",
					text: itemDef.name + " has been unlocked!",
					confirmButtonText: "Thanks!",
					showCloseButton: true,
				});
				
			} else {
					
				const ok = await window.AlertManager.fire({
					type: 'success',
					title: itemDef.name + " Purchased!",
					text: itemDef.name + " has been unlocked! You can now use it with your kitty!",
					confirmButtonText: "Thanks!",
					showCloseButton: true,
				});
				if(ok.value) {
					// this.startGame({ levelId: result.firstLevelId });
				} else {
					// this.refreshList();
				}
			}

			this._purchasePromise(true);

		} else
		if(result.error) {
			// this.refreshList();

			ServerStore.metric("game.market.item." + itemDef.id + ".popup.error", null, { error: result.error });

			this._purchasePromise(false);

			window.AlertManager.fire({
				type: 'error',
				title: 'Trouble purchasing ' + itemDef.name,
				text: result.error,
				confirmButtonText: "Thanks",
				showCloseButton: true,
			});
		} else
		if(result.canceled) {
			ServerStore.metric("game.market.item." + itemDef.id + ".popup.canceled");

			this._purchasePromise(false);
		}
	}

	static async unlockWorld(world, opts={
		showCongrats: false, 
		title: null,
		text: null,
	}) {
		return new Promise(async resolve => {
			// Init Stripe, NOOP if already init'd
			await this.init();

			this.unlockResolve = resolve;
			if(!world)
				return resolve(false);
			
			ServerStore.metric("game.market.world." + world.id + ".popup.shown", world.id);

			const haveStars = parseFloat(ServerStore.currentCat.stars),
				needStars = parseFloat(world.starCost),
				remaining = haveStars - needStars,
				// purchaseOffer = parseFloat(remaining <= 0 ? (world.dollarCost ? world.dollarCost : needStars * DOLLAR_COST_PER_STAR) : 0);
				purchaseOffer = parseFloat(world.dollarCost ? world.dollarCost : needStars * DOLLAR_COST_PER_STAR);

			// console.warn({ purchaseOffer, world });
			
			/*const response = */await window.AlertManager.fire({
				title: opts.title || world.name,
				type: '',
				html: (<>
					{opts.text ? opts.text : <>
						{opts.showCongrats ? <>
							<h3>Congratulations!</h3>
							<p>You reached the end of the last litter of levels. For more fun, unlock {world.name} and discover more levels to explore with {ServerStore.currentCat.name}!</p>
						</> : <></>}
						<p>It costs {numberWithCommas(needStars)}<FontAwesomeIcon icon={faStar} size="xs"/> to unlock this world. 
							You have {numberWithCommas(haveStars)}<FontAwesomeIcon icon={faStar} size="xs"/>. Unlock this world?</p>
					</>}

					{purchaseOffer > 0 && <BuyItemButton
						world={world}
						offer={purchaseOffer}
						onPurchaseResult={(result, method) => this._worldUnlockResult(result, world, method)}
					/>}

					{remaining >= 0 &&
						<Button color="success" className="swal2-styled swal2-confirm-secondary"  onClick={() => this._unlockWorldStars(world, needStars)}>
							Spend {numberWithCommas(needStars)} Stars
						</Button>
					}

					{/* {purchaseOffer > 0 &&
						<Button color="warning"  className="swal2-styled swal2-confirm" onClick={() => this._unlockWorldMoney(world, purchaseOffer)}>
							Buy for ${numberWithCommas(purchaseOffer.toFixed(2))}
						</Button>
					} */}

					<Button color="default" className="swal2-styled" onClick={() => {
						window.AlertManager.close();
						ServerStore.metric("game.market.world." + world.id + ".popup.canceled", world.id);
						resolve(false);
					}}>
						Don't Unlock
					</Button>
				</>),
				showConfirmButton: false, // hide default OK button
				showCloseButton: true, // show close button top-right
			});

			// Not doing this right now because _unlockWorldStars also calls .close and could cause this to fire
			// if(response && response.dismiss) {
			// 	resolve(false, response.reason);
			// }
		});
	}

	static async _unlockWorldStars(world, stars) {
		window.AlertManager.close();

		ServerStore.metric("game.market.world." + world.id + ".popup.stars_chosen", world.id);

		const result = await ServerStore.unlockWorld(world, { stars });

		return this._worldUnlockResult(result, world, 'stars');
	}

	static async _worldUnlockResult(result, world, method) {

		if(result.success) {
			ServerStore.metric("game.market.world." + world.id + ".popup.success", world.id);

			// Update google analytics
			// this is async, so no delay is incurred here other than the cost of a .push() operation that gtag uses (e.g. no cost at all)
			gtag('event', 'world_unlocked_' + method, { 
				event_label:    world.name,
				event_category: 'world'
			});
				
			const ok = await window.AlertManager.fire({
				type: 'success',
				title: world.name + " Ready to Play!",
				text: world.name + " has been unlocked! Start playing the first level now!",
				confirmButtonText: "Start Playing",
				showCloseButton: true,
			});
			if(ok.value) {
				// this.startGame({ levelId: result.firstLevelId });
				this.unlockResolve(result.firstLevelId);
			} else {
				// this.refreshList();
				this.unlockResolve(false);
			}

		} else
		if(result.error) {
			// this.refreshList();
			this.unlockResolve(false);

			ServerStore.metric("game.market.world." + world.id + ".popup.error", world.id, { error: result.error });

			window.AlertManager.fire({
				type: 'error',
				title: 'Trouble unlocking ' + world.name,
				text: result.error,
				confirmButtonText: "Thanks",
				showCloseButton: true,
			});
		} else
		if(result.canceled) {
			ServerStore.metric("game.market.world." + world.id + ".popup.canceled");

			this.unlockResolve(false);
		}
	}
}

window.market = MarketUtils;