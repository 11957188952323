import { ServerStore } from './ServerStore';


// Try to use fingerprint if supported, otherwise, prompt for PIN code
// Server handles checking return value, no checks done clientside.
export async function pincodePrompt(verb) {
	const fingerprintAuth = await promptForFingerprint(verb);
	if(fingerprintAuth === true) {
		// could be null, which means not available,
		// or false, which means it failed, 
		// so we only bypass pincode prompt if it's explicitly true
		return { fingerprint: true };
	}

	const { value: sosPasscode } = await window.Alert({
		title: 'Enter your Safety PIN',
		input: 'number',
		inputValue: '',
		showCancelButton: true,
		inputValidator: value => {
			if(!value) {
				return 'You need to enter your Safety PIN to ' + verb;
			}
		}
	});
	return sosPasscode;
}

const randomChallenge = () => new Uint8Array(new Array(128).fill(null).map(x => Math.ceil(Math.random() * 1024 )));

// This uses the API from https://www.npmjs.com/package/cordova-plugin-fingerprint-aio
// when running on cordova to use native fingerprint/faceid authentication
export async function promptForFingerprint(reason) {
	
	// WebAuthn feature detection
	// https://developers.google.com/web/updates/2018/05/webauthn
	// Disabled for now until we can perfect it
	if (false && (window.PasswordCredential || window.FederatedCredential)) {
		if(!promptForFingerprint.__webauthn_setup) {
			promptForFingerprint.__webauthn_setup = await createCreds().catch(ex => {
				console.warn("Error caught creating creds:", ex);
				return null;
			});
		} 
		
		if(promptForFingerprint.__webauthn_setup) {
			return await validateCreds(promptForFingerprint.__webauthn_setup.id).catch(ex => {
				console.warn("Error caught validating creds:", ex);
				return null;
			});
		}
	}

	if(!window.Fingerprint || !promptForFingerprint.available) {
		return null;
	}

	if(!promptForFingerprint.checked) {
		await new Promise(resolve => {
			window.Fingerprint.isAvailable(result => {
				console.log("[promptForFingerprint] IS available:", result);
				promptForFingerprint.available = true;
				resolve();
			}, error => {
				promptForFingerprint.available = false;
				console.log("[promptForFingerprint] not available:", error);
				resolve();				
			});
		});
		promptForFingerprint.checked = true;
		if(!promptForFingerprint.available)
			return null;
	}
	
	return await new Promise(resolve => {
		window.Fingerprint.show({
			clientId: "SassyBox", // Android: Used for encryption. iOS: used for dialogue if no `localizedReason` is given.
			clientSecret: "0843a88ee14044f9b952-08b952ecaee1", // Necessary for Android encrpytion of keys. Use random secret key.
			localizedReason: "We want to " + reason
		}, success => {
			resolve(true);
		}, error => {
			console.log("[promptForFingerprint] failed to auth:", error);
			resolve(false);
		});
	});
}

window.promptForFingerprint = promptForFingerprint;


// Util for webaun below from https://raw.githubusercontent.com/dvas0004/web-auth/master/src/webauthn.js
///////// START UTIL FUNCTIONS /////////
// easy way to go from string to ByteArray
const enc = new TextEncoder();

// another function to go from string to ByteArray, but we first encode the
// string as base64 - note the use of the atob() function
function strToBin(str) {
    return Uint8Array.from(atob(str), c => c.charCodeAt(0));
}

// function to encode raw binary to string, which is subsequently
// encoded to base64 - note the use of the btoa() function
function binToStr(bin) {
    return btoa(new Uint8Array(bin).reduce(
        (s, byte) => s + String.fromCharCode(byte), ''
    ));
}
///////// END UTIL FUNCTIONS /////////

///////// START WEBAUTHN FUNCTIONS /////////
const createCreds = async function() {

	const user = ServerStore.currentUser.id;


    ////// START server generated info //////
    // the below "publicKey" variable is typically generated by your server - here for DEMO purposes only
    const publicKey = {
        // random, cryptographically secure, at least 16 bytes
        challenge: randomChallenge(), //enc.encode('someRandomStringThatSHouldBeReLLYLoooong&Random'),
        // relying party
        rp: {    
			id: 'sassybox.app',
            name: 'SassyBox' // sample relying party
        },
        user: {
			id: new Uint8Array([user.id]),
			name: user.name || "Unknown User",
			displayName: user.name || "Unknown User",
		},
        authenticatorSelection: { 
            userVerification: "preferred" 
        },
        attestation: 'direct',
        pubKeyCredParams: [{
			type: "public-key", alg: -7 // "ES256" IANA COSE Algorithms registry
		}]
    }
	////// END server generated info //////
	
    console.log("navigator.credentials.create publicKey arg:", publicKey);

	
    // browser receives the publicKey object and passes it to WebAuthn "create" API
    const res = await navigator.credentials.create({
		publicKey: publicKey
	}).catch(ex => {
		console.warn("credentials.create failed:", ex);
		return { error: ex };
	})

	if(res.error) {
		return null;
	}

    console.log("navigator.credentials.create result:", res);

    // Below two lines store the most important info - the ID representing the created credentials
    // Typically they are sent via POST to your server, not stored locally - here for DEMO purposes only
    // localStorage.setItem('rawId', binToStr(res.rawId));
    // localStorage.setItem('id', binToStr(res.id));
	
	return { id: binToStr(res.rawId) };
}

const validateCreds = async function(ident){
    
    ////// START server generated info //////
    // Usually the below publicKey object is constructed on your server
    // here for DEMO purposes only
    // const rawId = localStorage.getItem('rawId');
    const AUTH_CHALLENGE = randomChallenge();
    const publicKey = {
        // your domain
        rpId: "sassybox.app",
        // random, cryptographically secure, at least 16 bytes
        challenge: AUTH_CHALLENGE, //enc.encode(AUTH_CHALLENGE),
        allowCredentials: [{
			id: strToBin(ident), //rawId),
			type: 'public-key'
        }],
        authenticatorSelection: { 
            userVerification: "preferred" 
        },
    };
    ////// END server generated info //////

    // browser receives the publicKey object and passes it to WebAuthn "get" API
    const res = await navigator.credentials.get({
        publicKey: publicKey
    }).catch(ex => {
		console.warn("navigator.credentials.get threw exception:", ex);
		return { error: ex };
	});

	if(res.error) {
		return false;
	}
    
	console.log("navigator.credentials.get result:", res);

    // here we build an object containing the results, to be sent to the server
    // usually "extractedData" is POSTed to your server
    const extractedData = {
        id: res.id,
        rawId: binToStr(res.rawId),
        clientDataJSON: binToStr(res.response.clientDataJSON)
    }

    // Usually done on the server, this is where you make your auth checks
    // here for DEMO purposes only
    const dataFromClient = JSON.parse(atob(extractedData.clientDataJSON));
    const retrievedChallenge = atob(dataFromClient.challenge);
    // const retrievedOrigin = dataFromClient.origin;

    // At MINIMUM, your auth checks should be:
    // 1. Check that the retrieved challenge matches the auth challenge you sent to the client, as we do trivially below
	// 2. Check that "retrievedOrigin" matches your domain - otherwise this might be a phish - not shown here
	const decodedChallenge = enc.decode(AUTH_CHALLENGE);
    console.log("challenge test:", { retrievedChallenge, decodedChallenge });
    if (retrievedChallenge === decodedChallenge) {
        return true; //alert("Authorized");
    } else {
        return false; //alert("Unauthorized");
    }
}

///////// END WEBAUTHN FUNCTIONS /////////

export {createCreds, validateCreds}
