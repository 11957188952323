import React from 'react';
import styles from './ShopPopup.module.scss';

import ShopWidget from './ShopWidget';
import TextOverlayWidget from './TextOverlayWidget';

import bg_img_center   from '../../../assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';
// import { PixiUtils } from '../../../utils/PixiUtils';

import { HomeNavBar } from 'game/scenes/WelcomeScene/widgets/HomeNavBar';
import { ServerStore } from '../../../utils/ServerStore';

class ShopPopup extends React.Component  {

	state = {
		transparentBg: true
	}

	componentDidMount() {
		if (this.props.handle)
			this.props.handle(this);
	}

	static DefaultShowProps = {
		text: null, 
		textTimer: 0
	};

	show(panel='badges', opts=ShopPopup.DefaultShowProps) {
		const { text, textTimer, ...otherProps } = Object.assign({}, ShopPopup.DefaultShowProps, opts);

		return this.promise = new Promise(resolve => {
			// Store, resolve in closeAction()
			this.closePromise = resolve;
			// Store for use in WelcomeScene to request close externally
			ShopPopup.activeHandle = this;
			// Fade out the actor 
			// setTimeout(() => PixiUtils.fadeOut(this.props.actor.obj, 200), 200);
			// Now, actually show our widget
			this.setState({ 
				visible: true,
				panel: panel || 'badges',
				mode:  text   ? 'textOverlay' : 'shop',
				text,
				transparentBg: text || this.props.transparentBg,
				otherProps
			});

			if(!text)
				ServerStore.metric("game.level.shop_popup.shown", null, { panel });

			// console.log("[ShopPopup.show] textTimer=", textTimer);
			if (this.textOverlayHandle && text && textTimer)
				this.textOverlayHandle.setTimer(textTimer);
			else
			// stop any active timer 
			if (this.textOverlayHandle)
				this.textOverlayHandle.setTimer(0);

			// Notify anyone listening - probably LevelsList
			if (this.props.onChange)
				this.props.onChange(true);

			if(!text)
				HomeNavBar.showBackBtn(cb => this.closeAction());
		});
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	closeAction = (buttonClicked = false) => {
		if(this.unmounted)
			return;

		if(this.state.mode === 'shop')
			ServerStore.metric("game.level.shop_popup.closed");

		// Re-show the actor
		// setTimeout(() => PixiUtils.fadeIn(this.props.actor.obj, 200), 200);
		// Hide our widget
		this.setState({ visible: false, mode: null });
		// Resolve our promise for anyone who await'd the show() function
		this.closePromise && this.closePromise(buttonClicked);
		// Clear the active handle so WelcomeScene knows it closed
		ShopPopup.activeHandle = null;
		// Notify anyone listening (probably LevelsList)
		if (this.props.onChange)
			this.props.onChange(false);

		if(!this.state.text)
			HomeNavBar.hideBackBtn();
	}

	render() {
		return (
			<div onClick={evt => evt.stopPropagation()}
				className={[styles.shop,
					(this.state.visible       ? styles.visible : ""),
					(this.state.transparentBg ? styles.transparentBg : ""),
					(this.state.mode === 'textOverlay' ? styles.textOverlay : ""),
				].join(" ")}>
			
				{!this.state.transparentBg && 
					<div className={styles.bg}
						style={{ backgroundImage: 'url(' + bg_img_center +')' }}/>
				}
			
				<TextOverlayWidget {...this.props}
					closeAction={this.closeAction}
					text={this.state.text}
					handle={handle => this.textOverlayHandle = handle}
					visible={this.state.mode && this.state.mode === 'textOverlay'}
					{...(this.state.otherProps || {})}
				/>
				{/* NB: We don't pass this.state.transparentBg, because 
				the only time we need ShopWidget to honor transparentBg is when used in WelcomeWidget,
				in which case, transparentBg comes in via props anyway, which we passthru to ShopWidget directly.
				We just need us (ShopPopup) to have transparentBg if used in TextOverlayWidget mode,
				in which case, ShopWidget would not be visible anyway. */}
				<ShopWidget {...this.props}
					closeAction={!HomeNavBar.handle && this.closeAction} 
					panel={this.state.panel} 
					onPanelChange={panel => this.setState({ panel })}
					visible={this.state.mode && this.state.mode !== 'textOverlay'}
					{...(this.state.otherProps || {})}
				/>
			</div>		
		)
	}

}

export default ShopPopup;