import React from 'react';
import styles from './WorldPopup.module.scss';
import { ServerStore } from 'utils/ServerStore';

import { normalizeWorldImg, imgForBg } from './imageUtils';

import { Sparklines, SparklinesLine } from 'react-sparklines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PixiUtils } from 'utils/PixiUtils';
import {  faStar, /*faTimes,*/ faSpinner, faRedo, faLockOpen, faLock, faPlay, faCheckCircle, faInfoCircle, faPlayCircle, faCircle, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import { GameFactory } from 'game/Game';
import { numberWithCommas } from 'utils/numberWithCommas';
import { MarketUtils } from 'utils/MarketUtils';

import { HomeNavBar } from '../HomeNavBar';

const formatSeconds = seconds => {
	seconds = parseFloat(seconds);
	if(!seconds || isNaN(seconds))
		return "";
	if(seconds > 0) {
		if(seconds < 60) {
			return `${seconds}sec`;
		} else {
			const minutes = Math.floor(seconds / 60);
			const remainingSeconds = seconds - (minutes * 60);
			return `${minutes}min ${remainingSeconds}sec`;
		}
	}
	return "";
}

class WorldPopup extends React.Component  {

	state = {
		world: null
	}

	componentDidMount() {
		if (this.props.handle)
			this.props.handle(this);
	}

	loadLargeCoverImage(world) {
		// Until this flag goes true,
		// the UI will just render the 'sm' version used in PlayMenu
		this.setState({ largeBgLoaded: false })
		
		world.bgImgUrlLg = imgForBg(world.name === 'Color Surprrrise' ? 'bg_img_burst_purple' : world.bg);
		
		// const start = Date.now();
		const preloader = new Image();
		preloader.onload = () => {
			// const end = Date.now();
			// const diff = end - start;
			// console.log("[WorldPopup] preloader got image ", preloader.src, "  in ", diff, "ms");
			this.setState({ largeBgLoaded: true })
		}
		preloader.src = world.bgImgUrlLg;
	}

	show(world=null, fromResult={}) {
		this.isVisible = true;

		// Update basic data
		normalizeWorldImg(world);

		// Preload large image
		this.loadLargeCoverImage(world);

		// Fade out the actor 
		PixiUtils.fadeOut(this.props.actor.obj, 200);
		// Now, actually show our widget
		this.setState({ visible: true, world });
		// Notify anyone listening - probably LevelsList
		if (this.props.onChange)
			this.props.onChange(true);

		this.updateWorldLevelList(world);

		HomeNavBar.showBackBtn(cb => this.closeAction());

		// console.log("[WorldPopup.show] fromResult=", fromResult);
		if(fromResult.unlockRequired) {
			// console.log("[WorldPopup.show] + unlockClicked ...");
			// Pass world in explicitly because it relies on this.state, 
			// which updates async so it would not be updated in this fn call yet
			this.unlockClicked(null, true, world); // true = showCongrats
		}

		return true;
	}

	hide() {
		if(this.isVisible) {
			this.closeAction();
		}
	}

	closeAction = evt => {
		evt && evt.stopPropagation();

		this.isVisible = false;

		// Fade out the actor 
		PixiUtils.fadeIn(this.props.actor.obj, 200);
		// Hide our widget
		this.setState({ visible: false });
		// Notify anyone listening (probably LevelsList)
		if (this.props.onChange)
			this.props.onChange(false);

		HomeNavBar.hideBackBtn();
	}

	render() {
		const world = this.state.world || {};
		return (
			<div onClick={evt => evt.stopPropagation()}
				className={[styles.popup,
					(this.state.visible       ? styles.visible : ""),
					(this.props.transparentBg ? styles.transparentBg : ""),
				].join(" ")}
				style={{
					'--theme-color': world.bgColor || 'black'
				}}>
				{this.renderWorldWidget(world)}
			</div>		
		)
	}

	setScrollPositionForCurrentLevel(el) {
		if(!el) {
			// console.log("No el given");
			return;
		}
		
		const { currentLevel } = this.state;
		
		if(!currentLevel) {
			// console.log("No current level instate");
			return;
		}
			
		const scroller = el.parentElement,
			sRect = {
				top: scroller.scrollTop,
				halfHeight: scroller.clientHeight/2,
				bottom: scroller.clientHeight + scroller.scrollTop,
			},
			li = el.querySelector('#level-' + currentLevel.id),
			liRect = li ? {
				top: li.offsetTop,
				halfHeight: li.clientHeight / 2,
				bottom: li.clientHeight + li.offsetTop
			} : null;

		if(!li) {
			// console.log("Could not find <li> in <ul>", currentLevel, el);
			return;
		}

		if(liRect.top < sRect.top || liRect.bottom > sRect.bottom) {
			const middle = liRect.top - sRect.halfHeight - liRect.halfHeight;
			scroller.scrollTop = middle;
			// console.log("scrollTop  changed:", { middle, liRect, sRect,  });

		} else {
			// console.log("rects good:", { liRect, sRect });
		}
	}

	levelClicked(level, opts={restart:false}) {
		const currentLevelId = this.state.currentLevel && this.state.currentLevel.id;
		if(level.id === currentLevelId || level.isCompleted) {
			// Notify metric server
			ServerStore.metric("game.world_popup.level_clicked");

			// console.log("[levelClicked] starting:", level);
			this.startGame({ levelId: level.id, resetActor: level.id !== currentLevelId, resetLevel: opts.restart || false });
		} else {
			// TODO

			// console.log("[levelClicked] NOT STARTING:", level, currentLevelId, this.state);
		}
	}

	startGame(opts) {
		// Note: Not tracking a metric in startGame() because the user never calls startGame() directly,
		// instead, startGame() is called as a by-product of some other user action, so metrics are tracked in 
		// those originating functions

		if(this._started) {
			console.log("Game already starting, ignoring multiple calls to start");
			return;
		}

		this._started = true;
		GameFactory.game.setScene('KittyFlySleep', opts);
	}

	headerClicked = async evt => {
		evt && evt.stopPropagation();

		if(this.state.world.state.isUnlocked) {
			if(this.state.world.state.isCompleted) {
				// Notify metric server
				ServerStore.metric("game.world_popup.header_clicked.replay");

				if((await window.AlertManager.fire({
					title: 'Replay ' + this.state.world.name + '?',
					text:  'You\'ll only get the stars you missed last time though...',
					type:  'warning',
					showCancelButton:  true,
					confirmButtonText: 'Yes, Replay',
					cancelButtonText:  'No thanks',
					reverseButtons:    true
				})).value) {
					const result = await ServerStore.replayWorld(this.state.world);
					if(result.level) {
						ServerStore.metric("game.world_popup.header_clicked.replay.success");
						this.startGame({
							levelId: result.level.id
						});
					} else {
						// Notify metric server
						ServerStore.metric("game.world_popup.replay_world.error");
					
						console.error("Could not replay world, see any other errors on console, result we got was:", result);
					}
				} else {
					// Notify metric server
					ServerStore.metric("game.world_popup.header_clicked.replay.canceled");

					console.log("User canceled replay");
				}
			} else {
				// Notify metric server
				ServerStore.metric("game.world_popup.header_clicked.play");

				this.startGame({
					levelId: this.state.currentLevel && this.state.currentLevel.id
				});
			}
		} else {
			// Notify metric server
			ServerStore.metric("game.world_popup.header_clicked.unlock");

			this.unlockClicked();
		}
	}

	unlockClicked = async (evt, showCongrats=false, world=null) => {
		evt && evt.stopPropagation();
		// console.log("[WorldPopup.unlockClicked] calling MarketUtils");
		const result = await MarketUtils.unlockWorld(world || this.state.world, { showCongrats });
		if(result) {
			// Notify metric server
			ServerStore.metric("game.world_popup.header_clicked.unlock.success");

			this.startGame({ levelId: result });
		} else {
			// Notify metric server
			ServerStore.metric("game.world_popup.header_clicked.unlock.failure");
		}
	}

	async updateWorldLevelList(world) {
		this.setState({ 
			levelListVisible: true,
			showLevelListSpinner: true
		});

		world.levels = await ServerStore.server().get('/level_status/home/levels', { worldId: world.id }, { autoRetry: true });
		// console.log(world.levels );

		const hardness = [];

		if(world && world.levels) {
			let currentLevel = false;
			world.levels.forEach(level => {
				level.isCompleted = parseFloat(level.isCompleted) === 1;
				let icon ='',
					className = '';
				if(world.state.isUnlocked) {
					if(!level.isCompleted) {
						if(!currentLevel) {
							currentLevel = level;
							icon = faPlayCircle;
							className = 'current';
						} else {
							icon = faCircle;
							className = 'locked';
						}
					} else {
						icon = faCheckCircle;
						className = 'completed';
					}
				} else {
					icon = faLock;
					className = 'locked';
				}

				// TESTING
				// level.totalPlayingTime = 123;
				// level.progress = 123;

				level._className = className;
				level._icon = icon;
				level._time = formatSeconds(level.totalPlayingTime);

				level._hard = Math.ceil(level.difficulty * 100);
				
				hardness.push(level._hard);
				
			});
			this.setState({
				levels: world.levels,
				currentLevel,
				hardness,
			});

			setTimeout(() => {
				this.setState({ showLevelListSpinner: false });
			}, 500);
		}
	}

	howHardInfo(level=null) {
		if(level) {
			// Notify metric server
			ServerStore.metric("game.world_popup.how_hard_info.level");

			window.AlertManager.fire("Level " + level.levelNum + " has a " + level._hard + "% difficulty rating");
		} else {
			// Notify metric server
			ServerStore.metric("game.world_popup.how_hard_info.world_chart");

			window.AlertManager.fire("This is a chart of how difficult the levels are in this litter of levels");
		}
	}

	renderWorldWidget(world) {
		if(!world) {
			return <></>;
		}

		if(!world.state)
			world.state = {};

		return <>
			{/* Per suggestions, this is a duplicate now of the action available on the HomeNav back button */}
			{/* <abbr onClick={this.closeAction} className={styles.closeAction}>
				<FontAwesomeIcon icon={faTimes} />
				<label><b>{' '}</b></label>
			</abbr> */}
			
			<div className={styles.worldHeader} onClick={this.headerClicked}>

				{world.bgImgUrl && 
					// eslint-disable-next-line jsx-a11y/alt-text
					<img src={(this.state.largeBgLoaded && world.bgImgUrlLg) || world.bgImgUrl} className={styles.worldHeaderBg}/>
				}

				<div className={styles.playCircle + " " + (world.state.isUnlocked ? (world.state.isCompleted ? styles.replay : styles.unlocked) : styles.locked)}>
					<FontAwesomeIcon icon={world.state.isUnlocked ? (world.state.isCompleted ? faRedo : faPlay) : faLock}/>
				</div>

				<div className={styles.headerLabel}>
					{world.name}
				</div>

			</div>

			<div className={[styles.worldPanel,
				(world.state.isUnlocked ? "unlocked" : "locked"),
				].join(' ')}>

				<div className={styles.world}>

					<div className={styles.body}>
						<div className={styles.bodyLeft}>
							{/* <div className={styles.header}>
								{!world.state.isUnlocked &&
									<div className={styles.left}>
										<FontAwesomeIcon icon={(world.state.isUnlocked ? faLockOpen : faLock)}/>

										<div className={styles.cost}>
											<FontAwesomeIcon icon={faStar} size="xs"/>
											<label>
												{world.starCost || (world.starCost = Math.ceil(Math.random() * 1000))}
											</label>
										</div>
									</div>
								}
								
								<h2>{world.name}</h2>
							</div> */}
							
							<div className={styles.description}>
								{world.description}
							</div>
						</div>
						{world.state.isUnlocked ?

							<div className={styles.bodyRight}>
								{/* {!world.state.isCompleted ?
									<div className={styles.playBtn}
										onClick={() => GameFactory.game.setScene('KittyFlySleep', {
											levelId: this.state.currentLevel && this.state.currentLevel.id })}>
										<FontAwesomeIcon icon={faPlay}/> Level {this.state.currentLevel && this.state.currentLevel.levelNum}
									</div>
								:
									<div className={styles.worldCompletedNotice}>
										<FontAwesomeIcon icon={faCheckCircle}/> All levels completed!
									</div>
								} */}
								{/* <div className={styles.moreBtn} onClick={evt => this.openLevelsList(evt)}>
									<FontAwesomeIcon icon={faPlusCircle}/> All levels
								</div> */}

								<div className={styles.timeInfo}>
									<span className={styles.hv}>{formatSeconds(world.avg_time)}</span><span className={styles.label}>avg time</span></div>
						
								<div className={styles.completionInfo}>
									<span className={styles.hv}>{world.completed} / {world.numLevels}</span>
									<span className={styles.label}>levels done</span>
								</div>

								<div className={styles.rateInfo} title="Average percent of stars you've found on every level in this world" onClick={e => 
										window.AlertManager.fire("Average percent of stars you've found on every level you've played so far in " + world.name)
									}>
									<span className={styles.hv}>{world.avg_progress}%</span>
								<span className={styles.label}>completion <FontAwesomeIcon icon={faInfoCircle} style={{color:'white'}}/></span>
								</div>
							</div>
						: <div className={styles.unlockHeader}>

								<div className={styles.unlockBtn} onClick={this.unlockClicked}>
									<FontAwesomeIcon icon={faLockOpen} className={styles.unlockIcon}/>
									Unlock for {numberWithCommas(world.starCost)} <FontAwesomeIcon icon={faStar} size="sm" className={styles.starIcon}/>
								</div>

								<div className={styles.unlockDescription}>
									You can't play this litter of levels until you unlock it!
								</div>
						</div>}
					</div>
					<div className={styles.levelList}> 
					{/* {"wc-level-list " + (this.state.levelListVisible ? " --visible": "")}> */}
						{/* <div className='back-btn' onClick={evt => this.closeLevelsList(evt)}>
							<FontAwesomeIcon icon={faChevronCircleLeft} fixedWidth/>
							<span className={styles.label}>Back</span>
						</div> */}
						{/* <div className="completion-info">
							<span className='hv'>18</span> / 32 levels done
						</div> */}
						{this.state.levelListVisible && <div className={styles.levelListWrap}>
							{this.state.hardness && this.state.hardness.length > 0 &&
								<div onClick={() => this.howHardInfo()} className={styles.chart}>
									<Sparklines data={this.state.hardness} width={240} height={24}>
										<SparklinesLine color="white" />
									</Sparklines>
								</div>}
							{this.state.showLevelListSpinner ?
								<FontAwesomeIcon icon={faSpinner} spin style={{marginTop:"1rem"}}/>
							:
							<ul ref={el => this.setScrollPositionForCurrentLevel(el)}>
								{(this.state.levels ||[]).map(level => <li
									key={level.id}
									id={"level-" + level.id}
									className={styles[level._className]} // computed at load time
									onClick={() => this.levelClicked(level)}
								>
									<FontAwesomeIcon icon={level._icon} fixedWidth/> {/* icon computed at load time */}
									<span className={styles.name}>Level {level.levelNum}</span>
									{/* <span className={styles.completion}>{level.progress ?
										<>{level.progress}% <FontAwesomeIcon icon={faStar} size="xs"/></> :
										<></>
									}</span> */}
									<span className={styles.hard} onClick={() => this.howHardInfo(level)}>{level._hard ?
										<>{level._hard}% <FontAwesomeIcon icon={faDumbbell} size="xs"/></> :
										<></>
									}</span>
									<span className={styles.time}>{level._time}</span>
									{level._className !== 'locked' ? 
										<FontAwesomeIcon icon={faRedo} size="xs" className={styles.redoIcon} title="Replay level from the start" onClick={evt => {
											evt.stopPropagation();
											this.levelClicked(level, { restart: true })
										}}/>
									:""}
								</li>)}

							</ul>}
						</div>}
					</div>
				</div>
			</div>
		</>;
	}

}

export default WorldPopup;