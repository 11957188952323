import { PixiUtils } from './PixiUtils';

// Couldn't get Plugin to import properly from Viewport, so here it is, copied from the src
class Plugin {
	constructor(parent)
	{
		this.parent = parent
		this.paused = false
	}

	/**
	 * handler for pointerdown PIXI event
	 * @param {PIXI.interaction.InteractionEvent} event
	 */
	down(event) { }

	/**
	 * handler for pointermove PIXI event
	 * @param {PIXI.interaction.InteractionEvent} event
	 */
	move(event) { }

	/**
	 * handler for pointerup PIXI event
	 * @param {PIXI.interaction.InteractionEvent} event
	 */
	up(event) { }

	/**
	 * handler for wheel event on div
	 * @param {WheelEvent} event
	 */
	wheel(event) { }

	/**
	 * called on each tick
	 */
	update() { }

	/**
	 * called when the viewport is resized
	 */
	resize() { }

	/**
	 * called when the viewport is manually moved
	 */
	reset() { }

	/**
	 * called when viewport is paused
	 */
	pause()
	{
		this.paused = true
	}

	/**
	 * called when viewport is resumed
	 */
	resume()
	{
		this.paused = false
	}
}

export class HideOverActorViewportPlugin extends Plugin
{
	static DefaultPadding = 24;

    /**
     * @param {Viewport} parent
     * @param {KittyFlySleep} our scene
     */
    constructor(parent, scene)
    {
		super(parent)
		this.viewport = parent;
		this.scene = scene;
		this.target = scene.actor;
		this.objects = [].concat(
			Object.values(this.scene.meters),
			Object.values(this.scene.buttons)
		);

		this.objects.forEach(obj => {
			// Store for use below
			obj.pluginVisibleAlpha = obj.alpha || 1;
		})
	}
	
    update()
    {
        if (this.paused)
            return
        
		const world = this.viewport.toScreen(this.target.obj.position),
			wx = world.x,
			wy = world.y;
		
		const padding = this.constructor.DefaultPadding, // px, random number really
			fadeTiming = 100,
			hiddenAlpha = 0.1,
			defaultVisibleAlpha = 0.85;

		const objects = this.objects || [];
			

		for(let i=0; i<objects.length; i++) {
			const obj = objects[i];
		
			if(!obj.__hidingRect) {
				if(obj.pluginHidingGroup) {
					// If using a hiding group, unify the rects of all objects in that group
					obj.__hidingRect = obj.pluginHidingGroup.__hidingRect = PixiUtils.getRect(obj.pluginHidingGroup, padding);
				} else {
					obj.__hidingRect = PixiUtils.getRect(obj, padding);
				}
			}

			const rect =
					obj.pluginHidingGroup ?
					obj.pluginHidingGroup.__hidingRect : 
					obj.__hidingRect,
				pad = padding; // TODO: should we ALSO set pad here?

			if (rect.includes(wx, wy, pad)) {
				
				if (obj.pluginHidingGroup) {
					obj.pluginHidingGroup.forEach(obj => {
						if(!obj.__hidden) {
							if(!obj.pluginHidingLocked) {
								obj.__hidden = true;
								PixiUtils.fadeAlpha(obj, obj.alpha, hiddenAlpha, fadeTiming);
							}
						}
					});
				} else {
					if(!obj.__hidden) {
						if(!obj.pluginHidingLocked) {
							obj.__hidden = true;
							PixiUtils.fadeAlpha(obj, obj.alpha, hiddenAlpha, fadeTiming);
						}
					}
				}

			} else {
			
				if (obj.pluginHidingGroup) {
					obj.pluginHidingGroup.forEach(obj => {
						if (obj.__hidden) {
							if(!obj.pluginHidingLocked) {
								obj.__hidden = false;
								PixiUtils.fadeAlpha(obj, obj.alpha, obj.pluginVisibleAlpha || defaultVisibleAlpha, fadeTiming);
							}
						}
					});
				} else {
					if (obj.__hidden) {
						if(!obj.pluginHidingLocked) {
							obj.__hidden = false;
							PixiUtils.fadeAlpha(obj, obj.alpha, obj.pluginVisibleAlpha || defaultVisibleAlpha, fadeTiming);
						}
					}
				}
				
			}
		}
    }
}
