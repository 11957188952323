import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTimes, faCheckSquare, faSquare, faDotCircle, faCircle, faClipboardList, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { mobileDetect } from 'utils/mobile-detect';

import { ServerStore } from 'utils/ServerStore';

// import {
// 	Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
// 	ListGroup, ListGroupItem,
// 	FormGroup,
// 	Row,
// 	InputGroup, InputGroupAddon, InputGroupText, Input
// } from 'reactstrap';


import styles from './ShopWidget.module.scss';
import { KITTY_ITEMS } from 'game/KittyActor';
import { MarketUtils } from 'utils/MarketUtils';
import { StatsWidget } from './StatsWidget';
import { MyStarsWidget } from './MyStarsWidget';


class ShopWidget extends React.Component {
	state = {
		// visible: true,
		panel: 'items',
		cat: ServerStore.currentCat,
	}

	componentWillReceiveProps(newProps) {
		if(newProps.panel)
			this.setState({ panel: newProps.panel });

		// if(newProps.visible !== undefined)
		// 	this.setState({ visible: newProps.visible });

		// if(newProps.transparentBg !== undefined)
		// 	this.setState({ transparentBg: newProps.transparentBg });
	}

	hasItem(iid) {
		if(!this.props.actor)
			return false;
		return this.props.actor.itemEnabled(iid);
	}

	toggleActive(item) {
		const actor = this.props.actor,
			{ itemDef } = item;

		if(this.hasItem(itemDef)) {
			actor.setItemEnabled(itemDef, false);
			ServerStore.metric("game.level.shop_popup.toggle_active." + itemDef.id + ".off");
		} else {
			actor.setItemAmount(itemDef, 1);
			actor.setItemEnabled(itemDef, true);
			ServerStore.metric("game.level.shop_popup.toggle_active." + itemDef.id + ".on");
		}

		this.setState({ cat: ServerStore.currentCat });
	}
	
	setActiveItem(item) {
		const actor = this.props.actor,
			{ itemDef } = item;

		actor.setActiveItem(itemDef);

		ServerStore.metric("game.level.shop_popup.set_active." + itemDef.id);

		this.setState({ cat: ServerStore.currentCat });
	}

	showPanel(panel, fromClick=false) {
		if(this.state.panel !== panel) {
			this.setState({ panel });

			if(fromClick)
				ServerStore.metric("game.level.shop_popup.panel." + panel + ".selected");

			if (this.props.onPanelChange)
				this.props.onPanelChange(panel);
		}
	}

	async purchaseItem(item) {
		const { itemDef } = item;
		const purchased = await MarketUtils.purchaseItem(itemDef);
		if(purchased && !itemDef.isConsumable) {
			this.props.actor.setItemEnabled(itemDef, true);
		}

		this.setState({ purchased: item })
	}
	
	render() {
		const state = this.state || {},
			{ closeAction, actor } = this.props || {},
			{ cat } = state;

			const invFeatures = [], invActions = [], marketItems = [];

		const has = {};
		if(actor) {
			actor.items(true, 'all').forEach(item => {
				has[item.itemDef.id] = item.amount > 0 ? item.amount : false;
				if(item.itemDef.setEnabled) {
					item.type = 'feature';
					item.isEnabled = actor.itemEnabled(item.itemDef);
					invFeatures.push(item);
				} else
				if(item.itemDef.action) {
					item.type = 'action';
					item.isActive = actor.activeItem && actor.activeItem.id === item.itemDef.id;
					// item.isActive = [activeItem.id,  item.itemDef.id].join("::");
					if(item.amount > 0)
						invActions.push(item);
				}
			});
		}

		Object.values(KITTY_ITEMS).forEach(itemDef => {
			if(!has[itemDef.id] || (itemDef.isConsumable && has[itemDef.id] < itemDef.maxItems)) {
				marketItems.push({ itemDef });
			}
		});

		// console.log({ has, marketItems, all: actor.items(true, 'all') });

		return (
			<div className={[
					styles.shop,
					(this.props.transparentBg ? styles.transparentBg : ""),
					(this.props.visible       ? styles.visible : ""),
				].join(" ")}>
				<nav>
					{/* <abbr onClick={() => this.showPanel('badges')}
						className={state.panel === 'badges' ? styles.active : ''}>
						<FontAwesomeIcon icon={faTrophy} />
						<label>Badges</label>
					</abbr> */}
					
					<abbr onClick={() => this.showPanel('items', true)}
						className={state.panel === 'items' ? styles.active : ''}>
						<FontAwesomeIcon icon={faShoppingCart} />
						<label>Items</label>
					</abbr>

					<abbr onClick={() => this.showPanel('records', true)}
						className={state.panel === 'records' ? styles.active : ''}>
						<FontAwesomeIcon icon={faClipboardList} />
						<label>Streaks</label>
					</abbr>
				</nav>


				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				{closeAction && <abbr onClick={closeAction} className={styles.closeAction}>
					<FontAwesomeIcon icon={faTimes} />
					<label><b>{' '}</b></label>
				</abbr>}
				
				<div className={styles.panelContainer}>

					<div className={styles.panel + " " + (state.panel ==='badges' ? styles.visible : "")}>
						<div className={styles.panelBody}>
							<h1>Badges</h1>
						</div>
					</div>

					<div className={styles.panel + " " + (state.panel ==='items' ? styles.visible : "")}>
						<div className={styles.panelBody}>
							<h1 style={{
								opacity: this.props.transparentBg ? 0:1,
								marginBottom: this.props.transparentBg && mobileDetect.hasNotch ? "2.25rem" : ".5rem",
							}}>Your Inventory</h1>

							{invFeatures.length > 0 && <>
								<h4>Extras</h4>
								{invFeatures.map(item => 
									<div className={styles.item} key={item.itemDef.id}
										onClick={() => this.toggleActive(item)}>
										<div className={styles.iconWrap}>
											<img src={item.itemDef.iconUrl} alt={"Icon of " + item.itemDef.name}/>
										</div>
										<div className={styles.itemName}>
											{item.itemDef.name}
											{/* {item.isEnabled ? "(Active)" : ""} */}
										</div>
										<div className={styles.control + " " +
											(item.isEnabled ? styles.active : "")}>
											<FontAwesomeIcon icon={item.isEnabled ? faCheckSquare : faSquare}/>
										</div>
									</div>
								)}
							</>}

							{invActions.length > 0 && <>
								<h4>Boosters</h4>

								{invActions.map(item => 
									<div className={styles.item} key={item.itemDef.id}
										onClick={() => this.setActiveItem(item)}>
										<div className={styles.iconWrap}>
											<img src={item.itemDef.iconUrl} alt={"Icon of " + item.itemDef.name}/>
										</div>
										<div className={styles.itemName}>
											{item.itemDef.name} ({item.amount})
										</div>
										<div className={styles.control + " " +
											(this.props.transparentBg || item.isActive ? styles.active : "")}>
											<FontAwesomeIcon icon={this.props.transparentBg ? faCheckCircle : (item.isActive ? faDotCircle : faCircle)}/>
										</div>
									</div>
								)}
							</>}

							{marketItems.length > 0 && <>
								<h4 className={styles.marketHeader}>Market</h4>
								
								<MyStarsWidget/>

								{marketItems.map(item => 
									<div className={styles.item} key={item.itemDef.id}
										onClick={() => this.purchaseItem(item)}>
										<div className={styles.iconWrap}>
											<img src={item.itemDef.iconUrl} alt={"Icon of " + item.itemDef.name}/>
										</div>
										<div className={styles.itemName}>
											{item.itemDef.name}
										</div>
										<div className={styles.cost}>
											{(item.itemDef.unitSize > 1 || item.itemDef.action) && <>
												{item.itemDef.unitSize} for {' '}
											</>}
											{item.itemDef.starCost > 0 ? <>
												{item.itemDef.starCost}
												<FontAwesomeIcon icon={faStar}/>
											</> : <>
												${item.itemDef.dollarCost}{item.itemDef.isSubscription && '/' + item.itemDef.period}
											</>}
										</div>
									</div>
								)}
							</>}
						</div>
					</div>

					<div className={styles.panel + " " + (state.panel ==='records' ? styles.visible : "")}>
						<div className={styles.panelBody}>
							<h1 style={{
								opacity: this.props.transparentBg ? 0:1,
								marginBottom: this.props.transparentBg && mobileDetect.hasNotch ? "2.25rem" : ".5rem",
							}}>Your Streaks</h1>

							<StatsWidget cat={cat}/>
						</div>
					</div>
				</div>

			</div>
		)
	}
}

export default ShopWidget;