import React from 'react';
import styles from './ToastOverlayPopup.module.scss';

import { mobileDetect } from 'utils/mobile-detect';

import {  faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { IconCircleButton } from './IconCircleButton';

const DefaultOptions = {
	text: "",
	closeAction: () => {},
	panelBottomPercent: 0,
	visible: false,
	infoAction: null, //() => {},
	actionIcon: faCheck,
	timer: 3000,
};

export default function ToastOverlayPopup() {
	const [ props, setProps ] = React.useState(DefaultOptions);

	ToastOverlayPopup.show = (text, options={}) => {
		setProps({
			...DefaultOptions,
			text,
			visible: true,
			closeAction: () => {
				setProps({
					...DefaultOptions,
					text,
					...options,
					visible: false
				});
			},
			...options
		});

		if (ToastOverlayPopup.timerBtn) {
			ToastOverlayPopup.timerBtn.setTimer(
				options.timer !== null && options.timer !== undefined ?
				options.timer : 3000
			);
		}
	};

	ToastOverlayPopup.timerButtonReady = btn => {
		ToastOverlayPopup.timerBtn = btn;
		if(props.timer) {
			btn.setTimer(props.timer);
		}
	};

	const bottomModifier = {
		'--panel-bottom': props.panelBottomPercent || '70%'
	};
	
	return (<div className={[
		(mobileDetect.hasNotch ? styles.hasNotch : ""),
		styles.widget,
		(props.visible ? styles.visible : "")
	].join(" ")}
	style={bottomModifier}>
		<div className={styles.panel} onClick={() => props.closeAction(true)}>
			<div className={styles.panelBody}>
				{typeof(props.text) === 'string' ? <p>{props.text}</p> : props.text}
			</div>
		</div>
		<div className={styles.buttonPanel}>
			
			{props.infoAction &&
				<IconCircleButton
					className={styles.button}
					onClick={() => props.infoAction(true)}
					icon={faInfo}
				/>
			}

			<IconCircleButton
				className={styles.button}
				handle={timerButton => ToastOverlayPopup.timerButtonReady(timerButton)}
				onTimerExpired={() => props.closeAction(false)}
				onClick={() => props.closeAction(true)} 
				icon={props.actionIcon || faCheck}
			/>
		</div>
	</div>);
}

window.toast = ToastOverlayPopup;