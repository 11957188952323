/* eslint-disable no-useless-concat */
import * as PIXI from 'pixi.js-legacy';
import { PixiUtils } from './PixiUtils';
import { GameFactory } from '../game/Game';
import AssetLoader from './AssetLoader';

// tokenizer from https://stackoverflow.com/questions/6162600/how-do-you-split-a-javascript-string-by-spaces-and-punctuation
const punct ='\\['+ '\\!'+ '\\"'+ '\\#'+ '\\$'+   // since javascript does not
	'\\%'+ '\\&'+ '\\\''+ '\\('+ '\\)'+  // support POSIX character
	'\\*'+ '\\+'+ '\\,'+ '\\\\'+ '\\-'+  // classes, we'll need our
	'\\.'+ '\\/'+ '\\:'+ '\\;'+ '\\<'+   // own version of [:punct:]
	'\\='+ '\\>'+ '\\?'+ '\\@'+ '\\['+
	'\\]'+ '\\^'+ '\\_'+ '\\`'+ '\\{'+
	'\\|'+ '\\}'+ '\\~'+ '\\]',
	tokenizer = new RegExp(     // tokenizer
		'\\s*'+              // discard possible leading whitespace
		'('+                 // start capture group
		'\\.{3}'+            // ellipsis (must appear before punct)
		'|'+                 // alternator
		'\\w+\\-\\w+'+       // hyphenated words (must appear before punct)
		'|'+                 // alternator
		'\\w+\'(?:\\w+)?'+   // compound words (must appear before punct)
		'|'+                 // alternator
		'\\w+'+              // other words
		'|'+                 // alternator
		'['+punct+']'+       // punct
		')'                  // end capture group
	);

const DEFAULT_FADE_TIME = 100;

export class BubbleText extends PIXI.Container {
	static DefaultOptions = {
		// resources: {}, // from the game, preloaded assets
		// icon: "",
		bubbleRes: null, // pass in a resource.X to use as bubble
		// Padding of text area (e.g. offset from bubble size)
		padding: {
			x1: 24,
			y1: 12,
			x2: 24,
			y2: 12,
		},
		text: "Hello, World",
		textStyle: {
			// This font is loaded in the react <KittyGameView> component
			fontFamily: "Dimbo-Regular, Arial",
			fontSize: 26,
			fill: "white",
			dropShadow: true,
			dropShadowColor: "#000000",
			dropShadowBlur: 5,
			dropShadowAngle: Math.PI / 6,
			dropShadowDistance: 3,
			 
			align: "center", 
			wordWrap: true, 
			wordWrapWidth: window.innerWidth * 0.80,
			breakWords: true,
			// lineHeight: 16,

			stroke: 'black',
			strokeThickness: 2,
		},
		position: {
			x: 0,
			y: 0,
		},
		closeable: true,
		onClose: () => {}
	};
	
	constructor(options=BubbleText.DefaultOptions) {
		super();

		this.opts = Object.assign({}, BubbleText.DefaultOptions, options || {});

		this.closePromise = new Promise(resolve => {
			this._resolveClose = resolve;
		});

		this.buildBubble();
		this.setText(this.opts.text);
	}

	setText(text) {
		if(!this.sprites)
			return;
		
		// TODO: break lines, scroll, etc
		const { textLabel: label, bubble } = this.sprites,
			{ padding, textStyle } = this.opts,
			controlWidth = 32, // TBD
			controlMarginLeft = 6,
			maxHeight = bubble.height
				- padding.y1
				- padding.y2;
		
		label.text = text;

		if(false) {

			if(label.height <= maxHeight) {
				// console.log("* height good, nothing more");
			} else {
				const newStyle = { ...textStyle,	
					wordWrapWidth: bubble.width * .9
						- padding.x1
						- padding.x2
						- controlWidth
						- controlMarginLeft
				};
				label.style = newStyle;
				label.x = -(controlWidth + controlMarginLeft);

				this.pages = [];
				
				// https://stackoverflow.com/questions/7311734/split-sentence-into-words-but-having-trouble-with-the-punctuations-in-c-sharp
				// const words = text.split(/((\b[^\s]+\b)((?<=\.\w).)?)/);

				const words = text.split(tokenizer).filter(x => x);

				let done = false;
				while(!done && words.length > 0) {
					const tempBuffer = [];
					let buffer = "";
					label.text = buffer;
					while(words.length > 0 && label.height < maxHeight) {
						const word = words.shift();
						tempBuffer.push(word);
						buffer = tempBuffer.join(" ");
						label.text = buffer;
						// console.log({ word, tempBuffer, buffer, labelHeight: label.height, maxHeight });
					}

					// Last word made it go over
					// words.unshift(tempBuffer.pop());

					this.pages.push(buffer = buffer + "...");

					if(label.height < maxHeight) {
						done = true;
					}

					// console.log("page:", buffer, this.pages, done);

				}

				label.text = this.pages[0];

				// TODO: Controls for paging

				console.warn("Text was too long, split into pages, but paging controls not implemented yet...")
			}
		}
	}

	close() {
		PixiUtils.fadeOut(this, this.opts.fadeOutTime || DEFAULT_FADE_TIME).then(async () => {
			if(this.opts.onClose)
				await this.opts.onClose(this);
			this.destroy();
			this._resolveClose();
		});
		return this.closePromise;
	}

	// TODO: support an icon
	// setIcon(resource, scale=1) {
	// 	if(!resource || !resource.texture) {
	// 		console.warn("[BubbleText.setIcon] resource given had no texture or null resource:", resource);
	// 		return;
	// 	}

	// 	if (this.sprites.icon) {
	// 		this.sprites.icon.destroy();			
	// 	}

	// 	const sprite = new PIXI.Sprite(resource.texture);
	// 	sprite.scale = new PIXI.Point(scale, scale);;
	// 	sprite.anchor.x = 0.5;
	// 	sprite.anchor.y = 0.5;
	// 	sprite.x = (this.sprites.icon_holder.width)  / 2;
	// 	sprite.y = (this.sprites.icon_holder.height) / 2;
	// 	this.addChild(sprite);
	// 	this.sprites.icon = sprite;
	// 	// Store for external confirmation
	// 	this.currentIconResource = resource;
	// }

	buildBubble() {
		
		const { bubbleRes, closeable, padding, text, textStyle, position } = this.opts;

		if(!bubbleRes || !bubbleRes.texture) {
			console.warn("Invalid bubbleRes given");
			return;
		}

		this.sprites = {};

		const scale =  //0.5
			Math.min(1.0,
				Math.min(
					window.innerWidth - (padding.x1 + padding.x2)/2,
					window.innerHeight - (padding.y1 + padding.y2)/2
				)
				/
				Math.max(
					bubbleRes.texture.orig.width,
					bubbleRes.texture.orig.height
				),
			),
			spriteScale = new PIXI.Point(scale, scale);
		this.opts.scale = scale;

		const bubble = this.sprites.bubble = new PIXI.Sprite(bubbleRes.texture);
		bubble.scale = spriteScale;
		bubble.anchor.x = 0.5;
		bubble.anchor.y = 0.5;
		bubble.alpha = 0.925;
		this.addChild(bubble);

		
		// Text label itself
		textStyle.lineHeight = textStyle.fontSize ;
		const textLabel = new PIXI.Text("", { ...textStyle,	
			wordWrapWidth: (bubble.width) - padding.x1 - padding.x2,
		});

		textLabel.anchor.x = 0.5;
		textLabel.anchor.y = 0.5;
		this.sprites.textLabel = textLabel;
		this.addChild(textLabel);

		this.setText(text);


		// Set position on screen
		this.x = position.x;
		this.y = position.y;
		
		if(closeable) {
			this.interactive = this.buttonMode = true;
			this.click = this.tap = () => this.close();
		}

	}

	static async popup(
		text="Hello, World!",
		options=BubbleText.DefaultOptions,
		container=GameFactory.game.gameContainer) {

		if (options.showOnceId &&
			BubbleText.hasSeen(options.showOnceId)) {
			return false;
		}

		if(!options.dontFreezeMatter) {
			GameFactory.game.postToMatter('freezeMatter', true);
		}
		
		const bubble = new BubbleText({
			...BubbleText.DefaultOptions,
			...options,
			position: {
				x: (options.position || {}).x || window.innerWidth / 2,
				y: (options.position || {}).y || window.innerHeight / 2,
			},
			bubbleRes:
				options.bubbleRes ||
				AssetLoader.resources.bubble_rect,
			text,
		});

		bubble.alpha = 0;
		PixiUtils.fadeIn(bubble, options.fadeInTime || DEFAULT_FADE_TIME);

		if(container) {
			container.addChild(bubble);
		} else {
			console.warn("BubbleText.popup: No container given, text will NOT be visible");
		}

		if (options.hideOpenPopup === undefined ||
			options.hideOpenPopup === null)
			options.hideOpenPopup = true;
			
		if (options.hideOpenPopup &&
			BubbleText.openBubble) {
			await BubbleText.openBubble.close();
			BubbleText.openBubble =null;
		}

		BubbleText.openBubble = bubble;

		await bubble.closePromise;

		if (options.showOnceId)
			BubbleText.setSeen(options.showOnceId);

		BubbleText.openBubble = null;

		if(!options.dontFreezeMatter) {
			GameFactory.game.postToMatter('freezeMatter', false);
		}

		return true;
	}

	static _seenHash() {
		try {
			return JSON.parse(window.localStorage.getItem('kitty-bubbleText') || '{}') || {};
		} catch(e) {
			return {};
		}
	}

	static hasSeen(id) {
		return this._seenHash()[id];
	}

	static setSeen(id, flag=true) {
		const hash = this._seenHash();
		if(flag) {
			hash[id] = true;
		} else {
			delete hash[id];
		}
		window.localStorage.setItem('kitty-bubbleText', JSON.stringify(hash));
	}
}


window.BubbleText = BubbleText;