import * as PIXI from 'pixi.js-legacy';
import TWEEN from '@tweenjs/tween.js';
import { PixiMatterContainer } from './PixiMatterContainer';
// import { toRadians } from './geom';

export class PixiUtils {

	/**
	 * Simple utility to sleep for a given duration by returning a Promise that resolves when the timer fires. Await the result of this function to sleep for the given time.
	 *
	 * @static
	 * @param {number} [time=0] milliseconds to sleep
	 * @returns Returns a Promise that resolves when the timer has fired at the given time
	 * @memberof PixiUtils
	 */
	static async sleep(time=0) {
		return new Promise(resolve => setTimeout(() => resolve(), time || 0));
	}

	/**
	 * Creates a `PIXI.AnimatedSprite` from given `sheetData` and `textureResource`.
	 * This is a utility we need because we have to load our spritesheets webpack and it doesn't give a 
	 * URL for the sheet json so we can't use `PIXI.loader` - but we still have to use PIXI loader to get the texture.
	 *
	 * @static
	 * @param {JSON} sheetData
	 * @param {PIXI texture} textureResource
	 * @returns
	 * @memberof PixiUtils
	 */

	static _parsedSheets = {};

	static async getAnimatedSprite(sheetData, textureResource) {
		if(!textureResource.texture) {
			console.warn("getAnimatedSprite: textureResource.texture was undefined or null, not continuing");
			return null;
		}

		if(!textureResource.texture.baseTexture) {
			console.warn("getAnimatedSprite: textureResource.texture.baseTexture was undefined or null, not continuing");
			return null;
		}

		// spritesheet-js doesn't seem to generate the .animations key,
		// so fix the data using alphabetical list of frame names
		sheetData.animations = {
			default: Object.keys(sheetData.frames).sort()
		};

		// Assuming is unique - TBD could use a different value, perhaps some texture id from textureResource?
		let cacheKey = sheetData.meta.image;
		let cacheData = this._parsedSheets[cacheKey] || {},
			sheet = cacheData.sheet;

		if(!sheet) {
			let parseError;

			try {
				// Create the Spritesheet (assuming texture is valid - note .baseTexture is required)
				sheet = new PIXI.Spritesheet(textureResource.texture.baseTexture, sheetData);
				
				// Promise of parsing
				const promise = new Promise(resolve => sheet.parse(resolve));

				// Cache parsed sprites for later reference
				this._parsedSheets[cacheKey] = cacheData = { sheet, promise, references: [] };

				// console.error("Cache miss:", cacheKey);

				// Catch any possible parse errors
				// For example, if base texture didn't load correctly...
				promise.catch(error => {
					parseError = error;
				});

				// Sheet is created but still needs parsed - parse() expects a callback/
				// Await the promise so we can create the AnimatedSprite from this sheet after parsing completes
				await promise;
			} catch(error) {
				parseError = error;
			}

			cacheData.promise = null;

			if(parseError) {
				console.warn(`getAnimatedSprite: Error parsing sprite sheet: ` + JSON.stringify(parseError) + `, returning null`);
				return null;				
			}

		} else
		if(cacheData.promise) {
			// console.error("... waiting on:", cacheKey);
			await cacheData.promise;
			// console.error("+++ done waiting on:", cacheKey);
		}

		// Since this function is async, we get here once the parse() is done
		const sprite = new PIXI.AnimatedSprite(sheet.animations.default);

		cacheData.references.push(sprite);

		const superDestroy = sprite.destroy.bind(sprite);
		sprite.destroy = (options, fromOtherUtil=false) => {
			if(!fromOtherUtil) {
				try {
					// Destroy other sprites referencing this texture
					// because once this sprite hits superDestroy(),
					// this texture will be useless
					cacheData.references.forEach(ref => {
						if(ref !== sprite) {
							if(!ref.destroyed) {
								ref.destroy(options, true);
								ref.destroyed = true;
							}
						}
					});

					// Remove from cache
					delete cacheData[cacheKey];
				} catch(e) {
					console.warn("Error in special destroy:", e);
				}
			}

			if(!sprite.destroyed) {
				superDestroy();
				sprite.destroyed = true;
			}
		}

		// Apply sensible defaults
		sprite.x = 0;
		sprite.y = 0;
		sprite.anchor.x = 0.5;
		sprite.anchor.y = 1;
		sprite.animationSpeed = 0.25;
		sprite.play();

		return sprite;

	};

	/**
	 * Creates a `PixiMatterContainer` instance in the MatterSimulation and puts a `PIXI.Sprite` inside it for the given texture.
	 *
	 * @static
	 * @param {PIXI texture} texture - Texture you probably got via `PIXI.loader.resources`. NOTE: You can provide a `Promise` here if you need to wait for a loader but still need to create a PIXI object. The Promise should resolve with the texture you want to eventually use. NOTE that if you DO give a Promise, you MUST specify `physicalOptions.altPhysicalSize.width` and `.height` because width/height cannot be changed in `Matter` (by our code automatically anyway).
	 * @param {object} position - Object containing {x,y} coords - NOTE: These coords are IGNORED if `physicalOptions.shapeArgs` is given
	 * @param {object} [sizeRelevantOptions={
	 * 		scale: 1,
	 * 	}] - Currently just `scale` is used, and is used to scale down texture size
	 * @param {string} [physicalOptions={
	 * 		altPhysicalSize: {
	 * 			offsetX: 0,
	 * 			offsetY: 0,
	 * 			width: 0,
	 * 			height: 0
	 * 		},
	 * 		shape: 'rectangle'
	 *      shapeArgs: null
	 * 	}]
	 * @param {string} [physicalOptions.shape='rectangle'] - Type of `MatterJS` shape to create. Defaults to `rectangle` and an appropriate rectangle shape is given to `MatterJS` based on `position` given above and scaled size of the texture.
	 * @param {object} [physicalOptions.shapeArgs] - If a rectangle-type args is not correct for Matter based on `physicalOptions.shape`, then you can give explicit size/position args to the `MatterJS` shape. For example, if you set `shape` to `circle`, you MUST give `shapeArgs` or `MatterJS` will throw an error since we don't (yet) handle that shape internally here.
	 * @param {object} [physicalOptions.altPhysicalSize] - Used to alter the shape used by Matter - useful if the "physical" part of your sprite should be offset from the explicit coords that Matter uses. (Matter expects all coords to anchor 50%/50% - center of the object). 
	 * @param {number} [physicalOptions.altPhysicalSize.offsetX] - Adjust X coords from the `MatterJS` body by this many pixels
	 * @param {number} [physicalOptions.altPhysicalSize.offsetY] - Adjust Y coords from the `MatterJS` body by this many pixels
	 * @memberof PixiUtils
	 */
	static createPhysicalTexture(texture, position, sizeRelevantOptions = {
		scale: 1,
	}, physicalOptions = {
		isStatic: false,
		// restitution: 1,
		// friction: 0.001,
		// density: 0.05
		altPhysicalSize: {
			offsetX: 0,
			offsetY: 0,
			width: 0,
			height: 0
		},
		shape: 'rectangle',
		shapeArgs: null,
	}) {
		const shape = physicalOptions.shape || 'rectangle';
		delete physicalOptions.shape;

		const shapeArgs = physicalOptions.shapeArgs || null;
		delete physicalOptions.shapeArgs;

		const container = new PixiMatterContainer(shape, shapeArgs || [
			position.x, position.y,
			(physicalOptions.altPhysicalSize || {}).width  || texture.orig.width  * sizeRelevantOptions.scale,
			(physicalOptions.altPhysicalSize || {}).height || texture.orig.height * sizeRelevantOptions.scale
		], physicalOptions);
		
		const createSprite = texture => {
			const sprite = new PIXI.Sprite(texture);
			sprite.scale = new PIXI.Point(sizeRelevantOptions.scale, sizeRelevantOptions.scale);
			
			// ***NOTE***
			// MatterJS REQUIRES an anchor of .5/.5 -
			// all positioning internally in MatterJS assumes x/y is the center of the object.
			// Therefore, for our physical sprites, we have to also calculate things from center of object.
			sprite.anchor.x = 0.5;
			sprite.anchor.y = 0.5;
			container.addChild(sprite);
			
			// Patch..
			container.sprite = sprite;

			// return for promise chaining
			return texture;
		};
		
		if(texture.then)
			texture.then(createSprite);
		else
			createSprite(texture);
		
		// Return the DisplayObject
		return container;
	};

	/**
	 * @private
	 * @static
	 * @memberof PixiUtils
	 */
	static _tweenAnimId = 0;

	/**
	 * Internal use really, just makes sure a TWEEN.update is being called. Can be called multiple times, will ensure only one TWEEN.update call is pending.
	 * @static
	 * @memberof PixiUtils
	 */
	static touchTweenLoop = () => {
		cancelAnimationFrame(PixiUtils._tweenAnimId);
		//If we register the callback animate, but the TWEEN.update(time) returns false, 
		//cancel/unregister the handler
		function animate( time ) {
			PixiUtils._tweenAnimId = requestAnimationFrame(animate);
			
			var result = TWEEN.update(time);
			if(!result) cancelAnimationFrame(PixiUtils._tweenAnimId);
		}
		animate();
	};

	/**
	 * Fades the `alpha` property from `fromAlpha` to `toAlpha` over `time` milliseconds
	 *
	 * @static
	 * @param {PIXI.DisplayObject} obj - Object to affect
	 * @param {number} [fromAlpha=1] Starting alpha, optional
	 * @param {number} [toAlpha=0] Ending alpha, optional
	 * @param {number} [time=300] Time in milliseconds, optional
	 * @returns
	 * @memberof PixiUtils
	 */
	static fadeAlpha(obj, fromAlpha=1, toAlpha=0, time=300, setter=null) {
		if(!obj)
			return;
		let promise = { canceled: false };
		promise = new Promise(function(resolve) {
			if(obj.alpha === toAlpha)
				return resolve();

			const value = { alpha: fromAlpha };
			new TWEEN.Tween(value)
				.to({ alpha: toAlpha }, time)
				.onUpdate(() => {
					if(promise.canceled) {
						// console.log("[fadeAlpha] promise.canceled = true, ignoring");
						return;
					}

					if(!obj.transform) // could have been deleted ...seen it happen ... 
						return;

					setter ? setter(value.alpha) : obj.alpha = value.alpha;
				})
				.onComplete(() => resolve())
				.start();
			
			PixiUtils.touchTweenLoop();
		});

		return promise;
	}

	static tweenXY(obj, fromScale={x:0,y:0}, toScale={x:1,y:1}, prop='scale', time=300, easing=TWEEN.Easing.Quadratic.Out) {
		if(!obj)
			return;
		const value = { x: fromScale.x, y: fromScale.y };
		let promise = { canceled: false };
		promise = new Promise(resolve => {
			if (obj[prop].x === toScale.x && 
				obj[prop].y === toScale.y)
				resolve();

			new TWEEN.Tween(value)
				.to(toScale, time)
				.easing(easing || TWEEN.Easing.Quadratic.Out)
				.onUpdate(() => {
					if(promise.canceled) {
						// console.log("[fadeAlpha] promise.canceled = true, ignoring");
						return;
					}

					if(!obj.transform) // could have been deleted ...seen it happen ... 
						return;
						
					obj[prop] = new PIXI.Point(value.x, value.y);
				})
				.onComplete(() => resolve())
				.start();
			
			PixiUtils.touchTweenLoop();
		});
		return promise;
	}

	static tweenXYR(obj, fromScale={x:0,y:0,rotation:0}, toScale={x:1,y:1,rotation:0}, time=300, cb=()=>{}, easing=TWEEN.Easing.Quadratic.Out) {
		if(!obj)
			return;
		const value = { x: fromScale.x, y: fromScale.y, rotation: fromScale.rotation };
		let promise = { canceled: false };
		// window['TWEEN.Easing.Quadratic.Out'] = TWEEN.Easing.Quadratic.Out;
		promise = new Promise(resolve => {
			if (obj.x === toScale.x && 
				obj.y === toScale.y &&
				obj.rotation === toScale.rotation)
				resolve();

			new TWEEN.Tween(value)
				.to(toScale, time)
				.easing(easing || TWEEN.Easing.Quadratic.Out)
				.onUpdate(() => {
					if(promise.canceled) {
						// console.log("[fadeAlpha] promise.canceled = true, ignoring");
						return;
					}

					if(!obj.transform) // could have been deleted ...seen it happen ... 
						return;
						
					// obj[prop] = new PIXI.Point(value.x, value.y);
					obj.x = value.x;
					obj.y = value.y;
					if(value.rotation !== undefined)
						obj.rotation = value.rotation;
					cb(value);
				})
				.onComplete(() => resolve())
				.start();
			
			PixiUtils.touchTweenLoop();
		});
		return promise;
	}

	/**
	 * Fade out the given `PIXI.DisplayObject`. Just a shortcut utility for calling `PixiUtils.fadeAlpha`
	 *
	 * @static
	 * @param {PIXI.DisplayObject} obj - Object to affect
	 * @param {number} [time=300] - milliseconds, optional
	 * @memberof PixiUtils
	 */
	static fadeOut = (obj, time=300) => obj && PixiUtils.fadeAlpha(obj, obj.alpha, 0, time);

	/**
	 * Fade in the given `PIXI.DisplayObject`. Just a shortcut utility for calling `PixiUtils.fadeAlpha`
	 *
	 * @static
	 * @param {PIXI.DisplayObject} obj - Object to affect
	 * @param {number} [time=300] - milliseconds, optional
	 * @memberof PixiUtils
	 */
	static fadeIn  = (obj, time=300) => obj && PixiUtils.fadeAlpha(obj, obj.alpha, 1, time);

	/**
	 * Utility to monkey-patch the `play()` method on a `PIXI.AnimatedSprite` instance
	 * to automatically fade in, play for a given number of milliseconds, and fade out and stop playing.
	 *
	 * @static
	 * @param {PIXI.AnimatedSprite} sprite
	 * @param {object} [opts={
	 * 		fadeIn: 300,
	 * 		play: 1500,
	 * 		fadeOut: 300
	 * 	}] Options, documented below
	 * @param {number} [opts.fadeIn=300] The time in milliseconds to fade in when `play()` is called
	 * @param {number} [opts.play=300] The time in milliseconds to allow the sprite to play once `play()` is called.
	 * 	NOTE: You can override this option by giving a number of milliseconds as the argument to the monkey-patched `play()` method
	 * @param {number} [opts.fadeOut=300] The time in milliseconds to fade out when `play()` ends
	 * @returns {PIXI.AnimatedSprite} Returns the sprite given
	 * @memberof PixiUtils
	 */
	static setupShortAnim(sprite, opts={
		fadeIn: 300,
		play: 1500,
		fadeOut: 300
	}) {
		sprite.alpha = 0;
		sprite.stop();
		
		sprite.__startTimer = speed => {
			clearTimeout(sprite.__playTid)
			sprite.__playTid = setTimeout(() => 
				// {
				// 	sprite.__isPlaying = false;
				// 	sprite.alpha = 0;	
				// 	sprite.stop();
				// }
				PixiUtils.fadeOut(sprite, opts.fadeOut)
					.then(() => {
						sprite.__isPlaying = false;
						sprite.stop();
					})
				, speed
			);
		}
		
		const play = sprite.play.bind(sprite);
		sprite.play = (time=opts.play) => {
			
			if (sprite.__isPlaying) {
				sprite.__startTimer(time);
				return;
			}

			sprite.__isPlaying = true;
			play();
			// Update 20190307:
			// Disabled fadeIn on anims because it seemed to cause
			// stutter in gameplay...
			// PixiUtils.fadeIn(sprite, opts.fadeIn).then(() => sprite.__startTimer(time));
			sprite.alpha = 1;
			sprite.__startTimer(time);

			return true;
		};

		return sprite;
	}

	/**
	 * Returns a basic rectangle object for the given PIXI object. `obj` may also be an array of PIXI objects,
	 * in which case, the rectangle returned will be the unified rectangle of all the rectangles.
	 * Returned object has following keys:
	 * * x1, y1, x2, y2 - top, left, right, bottom of rect
	 * * center.x, center.y - center of the rect
	 * * includes(x,y,pad=0) - returns true if point contained in the rect
	 *
	 * @param {PIXI.Container|Array} obj - PIXI Container (has x,y,width,height) or Array of PIXI Containers
	 * @param {number} [padding=0]
	 * @returns {object} with props {x1,y1,x2,y2,includes(x,y,pad)=>boolean}
	 * @memberof PixiUtils
	 */
	static getRect(obj, padding=0) {
		if(Array.isArray(obj)) {
			// If using a group, unify the rects of all objects in that group
			const rects = obj.map(container => this.getRect(container, padding));
			const rect1 = rects.shift();
			rects.forEach(rect2 => {
				if (rect2.x1 < rect1.x1)
					rect1.x1 = rect2.x1;
				if (rect2.x2 > rect1.x2)
					rect1.x2 = rect2.x2;
	
				if (rect2.y1 < rect1.y1)
					rect1.y1 = rect2.y1;
				if (rect2.y2 > rect1.y2)
					rect1.y2 = rect2.y2;
			});
	
			return rect1;
		}
	
		return {
			x1: obj.x - padding,
			y1: obj.y - padding,
			x2: obj.x + obj.width  + padding * 2,
			y2: obj.y + obj.height + padding * 2,
			center: {
				x: obj.x + obj.width / 2,
				y: obj.y + obj.height / 2
			},
	
			includes(x, y, pad=0) { 
				return (x >= this.x1 - pad && x <= this.x2 + pad &&
						y >= this.y1 - pad && y <= this.y2 + pad);
			},

			intersects(other) {
				return PixiUtils.rectIntersects(this, other);
			}
		};
	};

	static rectIntersects(r1, r2) {
		return !(r2.left > r1.right || 
				 r2.right < r1.left || 
				 r2.top > r1.bottom ||
				 r2.bottom < r1.top);
	}
}
