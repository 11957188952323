import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { numberWithCommas } from 'utils/numberWithCommas';
import { ServerStore } from 'utils/ServerStore';

import styles from './MyStarsWidget.module.scss';

export const MyStarsWidget = (/*props*/) => <div className={styles.stars}>
	<label>You have:</label>
	<span className={styles.num}>{numberWithCommas(ServerStore.currentCat.stars)}</span>
	<FontAwesomeIcon icon={faStar}/>
</div>;

		