// import HomePage        from 'pages/HomePage/HomePage';
// import AboutPage       from 'pages/AboutPage/AboutPage';
import PrivacyPage     from 'pages/PrivacyPage/PrivacyPage';

// import SafetyTipsPage  from 'pages/SafetyTipsPage/SafetyTipsPage';
// import ECAWelcomePage  from 'pages/ECAWelcomePage/ECAWelcomePage';
import KittyGameView from '../components/KittyGameView';

const Routes = {
	'/':        { component: KittyGameView, exact: true },
	// '/about':   { component: AboutPage },
	// '/tips':    { component: SafetyTipsPage },
	// '/eca':     { component: ECAWelcomePage },
	'/privacy': { component: PrivacyPage },

};

export default Routes;