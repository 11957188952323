import * as PIXI from 'pixi.js-legacy';
import TWEEN from '@tweenjs/tween.js';

import { PixiUtils } from 'utils/PixiUtils';
import SoundManager from 'utils/SoundManager';

import { toRadians } from 'utils/geom';

import gtag from 'utils/GoogleAnalytics';

// import EasingFunctions from 'utils/easing';
import AnimatorUtility from 'utils/AnimatorUtility';

import AssetLoader from 'utils/AssetLoader';

import {DropShadowFilter} from '@pixi/filter-drop-shadow';
import EventEmitter from 'events';

import flag_square_sheet   from 'assets/flag/compiled/flag_square.json';
import flag_square_img     from 'assets/flag/compiled/flag_square.png';

// import tx_box from 'assets/physicsobjects/pngs/small_cardboard_box_512.png';
import tx_mallet from 'assets/physicsobjects/pngs/mallet_facing_left_short.png';
import tx_lever from 'assets/physicsobjects/pngs/lever_stick.png';
import tx_stick from 'assets/physicsobjects/pngs/stick.png';

// import tx_litBlue0 from 'assets/scienceui/buttons/pngs/lit/lit-blue-off.png';
// import tx_litBlue1 from 'assets/scienceui/buttons/pngs/lit/lit-blue-on.png';

import tx_litGreen0 from 'assets/scienceui/buttons/pngs/lit/lit-green-off.png';
import tx_litGreen1 from 'assets/scienceui/buttons/pngs/lit/lit-green-on.png';

import tx_litRed0 from 'assets/scienceui/buttons/pngs/lit/lit-red-off.png';
import tx_litRed1 from 'assets/scienceui/buttons/pngs/lit/lit-red-on.png';

import tx_litYellow0 from 'assets/scienceui/buttons/pngs/lit/lit-yellow-off.png';
import tx_litYellow1 from 'assets/scienceui/buttons/pngs/lit/lit-yellow-on.png';


import anim_sleep_sheet     from 'assets/statuseffects/compiled/status-sleep.json';
import anim_sleep_img       from 'assets/statuseffects/compiled/status-sleep.png';

import anim_health_sheet    from 'assets/statuseffects/compiled/status-health.json';
import anim_health_img      from 'assets/statuseffects/compiled/status-health.png';

// import anim_heart_sheet     from 'assets/statuseffects/compiled/status-heart.json';
// import anim_heart_img       from 'assets/statuseffects/compiled/status-heart.png';

import anim_stars_sheet     from 'assets/statuseffects/compiled/status-star.json';
import anim_stars_img       from 'assets/statuseffects/compiled/status-star.png';

import anim_cloud_sheet     from 'assets/statuseffects/compiled/status-green-cloud.json';
import anim_cloud_img       from 'assets/statuseffects/compiled/status-green-cloud.png';

// import anim_flame_sheet     from 'assets/projectiles/anim_flame/flame.json';
// import anim_flame_img       from 'assets/projectiles/anim_flame/flame.png';

import anim_sparkle_sheet   from 'assets/sparkleeffect/compiled/sparkle.json';
import anim_sparkle_img     from 'assets/sparkleeffect/compiled/sparkle.png';

import anim_explosion_sheet from 'assets/explosioneffect/bigexplosion.json';
import anim_explosion_img   from 'assets/explosioneffect/bigexplosion.png';

import anim_flamewall_blue_sheet  from 'assets/flameeffect/flame_wall/flame_wall-blue.json';
import anim_flamewall_blue_img    from 'assets/flameeffect/flame_wall/flame_wall-blue.png';

import anim_flamewall_red_sheet  from 'assets/flameeffect/flame_wall/flame_wall-red.json';
import anim_flamewall_red_img    from 'assets/flameeffect/flame_wall/flame_wall-red.png';

// import anim_flamecross_blue_sheet from 'assets/flameeffect/flame_cross/single_cross/single_cross-blue.json';
// import anim_flamecross_blue_img   from 'assets/flameeffect/flame_cross/single_cross/single_cross-blue.png';

// import anim_fly_booster_img       from 'assets/makethingsfly/rocket.png';
// import anim_fly_booster_sheet     from 'assets/makethingsfly/rocket.json';

// import anim_fly_linees_img        from 'assets/makethingsfly/wind_lines_white.png';
// import anim_fly_lines_sheet       from 'assets/makethingsfly/wind_lines_white.json';


// import sparkle_static_img   from 'assets/extralife/sparkle_static.png';

import { ServerStore } from 'utils/ServerStore';

import LevelProps from './LevelProps';

import { BrainBox, BrainBoxEnvAdapter } from './BrainBox';

const getRandomTime = () => {
	const times = [ 200, 300, 5000, 400, 500, 600, 250, 333, 444, 555, Math.random() * 750 + 50 ];
	return times[Math.floor(Math.random() * times.length)];
}


export default class SassyBox extends EventEmitter {
	static SHAKE_EVENT = 'SHAKE_EVENT';
	static POINT_EVENT = 'POINT_EVENT';
	static POINT_FAIL_EVENT = 'POINT_FAIL_EVENT';

	constructor(game, resources) {
		super();

		this.game = game;
		this.resources = resources;

		this.brainEnv = new BrainBoxEnvAdapter(this);
		this.brain = new BrainBox(this.brainEnv);
	}

	static requiredResources() {
		return {
			tx_lever,
			tx_mallet,

			tx_stick,			
			flag_square_img,

			// tx_litBlue0,
			// tx_litBlue1,
			tx_litGreen0,
			tx_litGreen1,
			tx_litRed0,
			tx_litRed1,
			tx_litYellow0,
			tx_litYellow1,
		}
	}

	static secondaryResources() {
		return {
			// tx_box,

			anim_stars_img,
			anim_cloud_img,
			anim_explosion_img,
			anim_sparkle_img,
			anim_sleep_img,
			anim_health_img,

			anim_flamewall_blue_img,
			anim_flamewall_red_img,
			// anim_fly_booster_img
		}
	}

	async countLeverMetric() {

		// Emit socket event to server
		ServerStore.LeverClicked();

		// Update Google Analytics
		if(process.env.NODE_ENV === 'production') {
			gtag('event', 'lever_click', {
				event_label: 'Lever Click',
			});
		}

		// Update avg counter				
		if(!this.shotCountAccumulator)
			this.shotCountAccumulator = 0;

		// Count this "Shot"
		this.shotCountAccumulator ++ ;

		if(!this.shotCountTimeStart)
			this.shotCountTimeStart = Date.now();
		
		clearTimeout(this._shotMetricTid);
		this._shotMetricTid = setTimeout(() => {
			if(this.shotCountAccumulator > 0) {
				const time = Date.now() - this.shotCountTimeStart,
					sec = time / 1000;
				
				ServerStore.metric("game.lever_clicked.avg_clicks",  
					parseFloat((this.shotCountAccumulator / sec).toFixed(3)),
					{ sec, num: this.shotCountAccumulator });
					// NB: { sec, num } - special expanded props, stored in data_sec and data_num in the db for easy querying (as well as `data`) 
				this.shotCountAccumulator = 0;
				this.shotCountTimeStart   = null;
			}
		}, 2500);
	}

	async _initBoxGraphic(container, boxTextureName) {
		const { resources, scale: boxScale } = this;

		// Prebuilt factors for computing keyframes below
		const { xf, yf } = this;
		
		// // Create second right-side mallet
		// {
		// 	const mallet = new PIXI.Sprite(resources.tx_mallet.texture);
		// 	mallet.anchor.x = 0.5;
		// 	mallet.anchor.y = 1;
	
		// 	mallet.keyframes = {
		// 		'pressStart': {
		// 			r: 30,
		// 			y: yf * (-80 / yf),
		// 			x: xf * (100 / xf),
		// 		},
		// 		'pressEnd': {
		// 			r: -12,
		// 			y: yf * (-105 / yf),
		// 			x: xf * ( 120 / xf),
		// 		},
		// 		'down': {
		// 			r: 90,
		// 			y: yf * (-30 / yf),
		// 			x: xf * (-80 / xf),
		// 		}
		// 	};

		// 	// Scale down relative to box
		// 	mallet.scale = new PIXI.Point(boxScale * 0.5, boxScale * 0.5);

		// 	// Put mallet in "down" position
		// 	mallet.y = mallet.keyframes.down.y;
		// 	mallet.x = mallet.keyframes.down.x;
		// 	mallet.rotation = toRadians(mallet.keyframes.down.r);

		// 	container.addChild(mallet);
		// 	this.sprites.mallet2 = mallet;

		// 	const fromRadians = radians => {
		// 		return radians * (180/ Math.PI);
		// 	}

		// 	mallet.runKeyframe = name => {
		// 		const kf = mallet.keyframes[name];
		// 		if(!kf) {
		// 			throw new Error("Invalid keyframe name " + kf);
		// 		}
				
		// 		const data = {
		// 			...kf,
		// 			rotation: toRadians(kf.r),
		// 		};
		// 		// console.log("Running: ", data, kf);
		// 		const time = getRandomTime();

		// 		let soundHit = false;

		// 		return PixiUtils.tweenXYR(mallet, mallet, data, time, value => {
		// 		// return PixiUtils.tweenXYR(mallet, mallet, data, 4000, value => {
		// 			if(name !== 'down') {
		// 				const angle = fromRadians(value.rotation);
		// 				// console.log(angle);
		// 				if (angle <= mallet.keyframes.pressStart.r &&
		// 					angle >= mallet.keyframes.pressEnd.r) {
		// 					this.sprites.lever.rotation = value.rotation;

		// 					if(!soundHit) {
		// 						soundHit = true;
		// 						SoundManager.play(SoundManager.BTN2);
		// 					}
		// 				}
		// 			}
		// 		});
		// 	};

		// 	// mallet.runKeyframe('pressStart')

		// };

		
		// Create lever
		{
			const lever = new PIXI.Sprite(resources.tx_lever.texture);
			lever.anchor.x = 0.5;
			lever.anchor.y = 1;

			// Scale down relative to box
			lever.scale = new PIXI.Point(boxScale * 0.625, boxScale * 0.625);

			// Position top-right of box, just a hair lower than top
			lever.y = -resources[boxTextureName].texture.height * boxScale     * .4625;
			lever.x =  resources[boxTextureName].texture.width  * boxScale / 2 * .6250;

			container.addChild(lever);
			this.sprites.lever = lever;

			const R_OFF =  20,
				  R_ON  = -20;

			lever.rotation = toRadians(R_OFF);

			lever.isActive = false;

			class Scorer {
				constructor(levelGetter, onScore, onFail, setColors) {
					this.setColors = setColors;
					this.levelGetter = levelGetter;
					this.onScore = onScore;
					this.onFail  = onFail;
					this.state = 0;
					this.accumulatedGood = 0;
				}

				level = () => this.levelGetter();

				// lightsNeeded = () => 2 //this.level() % 7 + 1;
				// lightsNeeded = () => this.level() % 7 || 1; // this.level() % 6 || 1;
				lightsNeeded = () => [ 1, 3, 5 ][ (this.level() - 1) % 3 ];

				stateRef() {
					const// level = this.level(),
						needed = this.lightsNeeded();

					// console.log(`[Scorer.stateRef] `, { level, needed });

					if(!this._state || this._state.length !== needed) {
						this._state = new Array(this.lightsNeeded()).fill(0);
					}

					return this._state;
				}

				lightValues() {
					const stateCopy = this.stateRef().slice();
					const lights = stateCopy;
					// if(lights.length === 5) {
					// 	lights[1] = stateCopy[0];
					// 	lights[3] = stateCopy[1];
					// 	lights[2] = stateCopy[2];
					// 	lights[0] = stateCopy[3];
					// 	lights[4] = stateCopy[4];
					// }
					const needed = this.lightsNeeded(),
						max =
							needed === 1 ? 4 :
							needed === 2 ? 5 :
							needed === 3 ? 5 :
							needed === 5 ? 6 :
							needed === 6 ? 0 :
							needed >=  4 ? 7 : 7;

					// console.log(`[lightValues] lights start=`, [...lights], { needed, max });
							
					while(lights.length < max) {
						lights.unshift(null);
					}

					// console.log(`[lightValues] final lights=`, lights);

					return lights;
				}

				setStateValue(idx, value) {
					this.stateRef()[idx] = value;
					this.updateLights();
				}
				
				updateLights() {
					this.setColors(this.lightValues());
				}

				bad() {
					const { state} = this;

					// this.sprites.light.setColor('red0');
					this.setStateValue(state, 4);
					// if (this.state > 0) {
					// 	this.state --;
					// }

					console.log(`[Scorer:bad]`)

					if (state > 0) {
						this.state --;
						// console.log(`[Scorer:good] no score yet `, { state, needed, newState: this.state } )
					} else {
						// console.log(`[Scorer:bad] this.accumulatedGood=${this.accumulatedGood} `)

						// Must have accumulated one good value after reset inorder to fail
						if(this.accumulatedGood > 0) {
							this.onFail();

							console.warn(`[Scorer:bad] ** POINT`)

							this.resetState('blue')
						}
					}
				}

				resetState(winning='red') {
					const needed = this.lightsNeeded();

					for(let i=0; i<needed; i++) {
						this.setStateValue(i, winning === 'red' ? 3 : 5);
					}
					this.state = 0;
					this.resetOnFirstMaybe = true;
					this.accumulatedGood = 0;
				}

				maybe() {
					// Reset on first maybe on new state
					// so we don't leave some partial colors
					if (this.resetOnFirstMaybe) {
						this.resetOnFirstMaybe = false;
						const needed = this.lightsNeeded();
						for(let i=1; i<needed; i++) {
							this.setStateValue(i, 0);
						}
					}

					this.setStateValue(this.state, 1);
				}

				good() {
					const { state} = this, needed = this.lightsNeeded();

					// this.sprites.light.setColor('red');
					this.setStateValue(state, 2);

					console.log(`[Scorer:good]`)

					if (state < needed-1) {
						this.state ++;

						// Need at LEAST one accumulated good after resetState in order for an onFail() event
						this.accumulatedGood ++;

						// console.log(`[Scorer:good] no score yet `, { state, needed, newState: this.state } )
					} else {
						// console.log(`[Scorer:good] *score* `, { state, needed } )

						this.givePoint();
					}
				}

				givePoint() {
					this.onScore();

					console.warn(`[Scorer:good] ** POINT`)

					this.resetState('red');
				}
			}

			const scorer = new Scorer(
				() => this.currentLevel || 1,
				() => this._scored(),
				() => this._scoreFailed(),
				colors => this.lights.setColors(colors),
			);

			window.scorer = scorer;
			lever.scorer = scorer;
			this.scorer = scorer;

			lever.onHitByMallet = () => {
				if(lever.hitByMallet)
					return;

				// console.log("- onHitByMallet");
				lever.hitByMallet = true;

				SoundManager.play(SoundManager.BTN2);
				lever.startHitByBallet = true;

				scorer.bad();

				this.brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_MALLET_HIT);
			}

			lever.turnOn = async () => {
				lever.rotation = toRadians(R_ON);
				lever.isActive = true;

				this.brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_LEVER_ON);

				// Block interaction if "flag waving" animation running
				if(this.sprites.stick.animator.isRunning()) {
					return;
				}

				scorer.maybe();

				if (this.rewardMeter) {
					const reward = this.brainEnv.getReward();
					if(!isNaN(reward)) {
						this.rewardMeter.setValue(Math.round(reward * 100));
					}
				}

				if(false) { //Math.random() > 0.5) {
					// Ask the box to react
					this.brain.tick();
				} else {
					setTimeout(async () => {
						if( this.currentLevel > 5 && 
							this.currentLevel % 2 === 0 &&
							Math.random() > 0.9725) {
						// if(true) {
							setTimeout(() => {
								this.doFlagRaising();
							}, 100);
							return;
						} else {
							this.doMalletAttack();
						}
					}, 0);
				}
			}

			lever.turnOff = async (force=false) => {
				
				// console.log("- lever:turnOff");

				lever.rotation = toRadians(R_OFF);
				lever.isActive = false;

				this.brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_LEVER_OFF);

				if (this.sprites.mallet.animator.isRunning() || force) {
					this.sprites.mallet.animator.stop();
					// await this.sprites.mallet.runKeyframe('down');
					await this.sprites.mallet.animateTo(0);
				}

				this._startSleeping();
			};
			
			// Setup interactivity for the lever
			lever.interactive = lever.buttonMode = true;
			lever.click = lever.tap = async () => {

				// Play sound
				SoundManager.play(SoundManager.BTN1);

				// Update server with data
				this.countLeverMetric(); 

				// Update brain
				this.brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_LEVER_TOUCHED);
				
				// console.warn("* lever:click", { isActive: lever.isActive, startHitByBallet: lever.startHitByBallet });

				// Do the actual UI work
				if(lever.isActive && !lever.startHitByBallet) {
					lever.turnOff();
					this.brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_LEVER_USER_TURNOFF, true);
				} else {
					lever.turnOn();
				}

					// if(level > 10 && level % 3 === 0) {
					// 	if(Math.random() > 0.5) {
							
					// 		setTimeout(async () => { 
					// 			lever._isOff = false;
								
					// 			if (lever._pOff)
					// 				lever._pOff.canceled = true;
					// 			await (lever._pOff = this.sprites.mallet2.runKeyframe('pressStart'));
					// 			await (lever._pOff = this.sprites.mallet2.runKeyframe('pressEnd'));

					// 			lever.rotation = toRadians(R_ON);

					// 			if (lever._pOff)
					// 				lever._pOff.canceled = true;
					// 			await (lever._pOff = this.sprites.mallet2.runKeyframe('down'));

					// 		}, 0);//, 1000 * Math.random() + 10);
					// 	} else {
					// 		if(level > 50) {
					// 			this.shakeAnim();
					// 		}
					// 	}
					// }

			}
		};

		// Create "hand"
		{
			const mallet = new PIXI.Sprite(resources.tx_mallet.texture);
			mallet.anchor.x = 0.5;
			mallet.anchor.y = 1;

			// Scale down relative to box
			mallet.scale = new PIXI.Point(boxScale * 0.5, boxScale * 0.5);

			container.addChild(mallet);
			this.sprites.mallet = mallet;

			const kf = {
				'pressStart': {
					r: -20,
					y: yf * (-67 / yf),
					x: yf * ( 17 / xf),
				},
				'pressEnd': {
					r: 20,
					y: yf * (-109 / yf),
					x: xf * (  35 / xf),
				},
				'down': {
					r: -90,
					y: yf * (-30 / yf),
					x: xf * ( 60 / xf),
				},
				down2: {
					r: 0,
					x: xf * ( -50 / xf),
					y: yf * (  75 / yf),
				},
				up: {
					r: 0,
					x: xf * ( -50 / xf),
					y: yf * (-110 / yf),
				},

				'pressStart2': {
					r: -10,
					x: yf * ( -50 / xf),
					y: yf * (-110 / yf),
				},
				
			}
			
			const _animPrep = kf => {
				const { x, y } = kf;
				return {
					x, y,
					rotation: toRadians(kf.r),
				}
			};
		
			const anim = [
				{
					id: 'down',
					data: _animPrep(kf.down2),
				},
				{
					id: 'up',
					data: _animPrep(kf.up),
					at: 0.3, 
				},
				{
					id: 'pressStart',
					data: _animPrep(kf.pressStart2),
					at: 0.92,
				},
				{
					id: 'pressEnd',
					data: _animPrep(kf.pressEnd),
				}
			];

			mallet.animator = new AnimatorUtility(anim, {
				onUpdate: (/*value, anim*/) => {
					const lever = this.sprites.lever,
						mb = mallet.getBounds(),
						lb = lever.getBounds();

					if(PixiUtils.rectIntersects(mb, lb)) {
						lever.rotation = mallet.rotation;
					
						// Only trigger hit if progressing "forward" thru the time range
						if(mallet.animator.timeRange.start < mallet.animator.timeRange.end) {
							lever.onHitByMallet();							
						}
					}
				}
			}); 

			// "set" the mallet at time index 0 (down)
			mallet.animator.update(0, mallet);

			// setTimeout(async () => {
			// 	await mallet.animator.start({ data: mallet, length: 800, end: 'up' });

			// 	// mallet.animator.start({ data: mallet, length: 800, end: 'down' })

			// 	// mallet.animator.start({ data: mallet, length: 800 })

			// }, 1000);

			mallet.animateTo = async (time, speed=null, range=null) => {
				if(time === null || time === undefined) 
					time = 1;

				if(time > 0 && this.sprites.stick.animator.isRunning()) {
					// Force stick down
					this.sprites.stick.animator.update(0, this.sprites.stick);
				}

				const opts = { data: mallet, length: speed || getRandomTime(), end: range ? null : time, range };
				// console.log("mallet.animateTo: opts:", opts)
				
				return mallet.animator.start(opts)
				// return mallet.animator.start({ data: mallet, length: 3000, end: time })
				
			}
		};

		// Add flag animation after the lever but before the box
		await PixiUtils.getAnimatedSprite(flag_square_sheet, resources.flag_square_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.flag_square_sheet");
				return;
			}

			sprite.x     =  -40;
			sprite.y     = -540;
			sprite.anchor.x = 0;
			sprite.anchor.y = 0.5;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			sprite.animationSpeed = 0.33;
			sprite.alpha = 0;
			sprite.stop();

			// Stop after one time thru
			sprite.loop = true;
			// sprite.onLoop = () => {
			// 	sprite.stop();
			// 	sprite.alpha = 0;
			// };

			// Auto-show on play
			const play = sprite.play.bind(sprite);
			sprite.play = () => {
				play();
				sprite.alpha = 1;
			};
			
			// container.addChild(sprite);
			// this.anims.flag = sprite;

			const stick = new PIXI.Sprite(resources.tx_stick.texture);
			stick.anchor.x = 0.5;
			stick.anchor.y = 1;

			// Scale down relative to box
			stick.scale = new PIXI.Point(boxScale * 0.5, boxScale * 0.5);

			container.addChild(stick);
			this.sprites.stick = stick;

			stick.addChild(sprite);
			stick._flag = sprite;

			this._setupStickAnim(stick);
		});

		
		// Add the box AFTER the lever so it is "on top of" 
		// the bottom edge of the lever graphic
		{
			const box = new PIXI.Sprite(resources[boxTextureName].texture);
			box.anchor.x = 0.5;
			box.anchor.y = 0.5;
			box.scale = new PIXI.Point(boxScale, boxScale);
			// box.scale = new PIXI.Point(boxScale + .125, boxScale + .125);
			// box.y     = -20;

			container.addChild(box);
			this.sprites.box = box;

			box.interactive = box.buttonMode = true;
			box.click = box.tap = this.sprites.lever.click;
		}

		const createLight = (x=0, y=0) => {
			const lightScale = 0.8;
			const light = new PIXI.Sprite(resources.tx_litRed0.texture);
			light.y = y || -3; // line up better on box border
			light.x = x || 0;
			light.anchor.x = 0.5;
			light.anchor.y = 0.5;
			light.scale = new PIXI.Point(boxScale * lightScale, boxScale * lightScale);
			container.addChild(light);

			light.interactive = light.buttonMode = true;
			light.click = light.tap = this.sprites.lever.click;

			light.setColor = (key) => {

				// Convert numbers 0-3 to colors r0/y/r/g
				if(!isNaN(key)) {
					// key = ['red0','yellow','red','green','blue'][key];
					//      default    maybe    yes      SCORE+    Xmaybe SCOREX
					key = ['green0', 'yellow0', 'green','yellow',  'red', 'red'][key];
				}
				
				// hash of texture resources to name
				const textures = {
					red0:    resources.tx_litRed0.texture,
					red:     resources.tx_litRed1.texture,
					green0:  resources.tx_litGreen0.texture,
					green:   resources.tx_litGreen1.texture,
					// blue0:   resources.tx_litBlue0.texture,
					// blue:    resources.tx_litBlue1.texture,
					yellow0: resources.tx_litYellow0.texture,
					yellow:  resources.tx_litYellow1.texture,

				}

				// validate name
				if(!textures[key]) {
					throw new Error("Invalid color " + key);
				}

				// Actually change the texture
				light.texture = textures[key];
			}

			return light;
		}

		// Put lights on the box
		{ /* eslint-disable-line no-lone-blocks */
			// this.sprites.lightn2 = createLight(-67,   7);
			// this.sprites.lightn1 = createLight(-33,   2);
			// this.sprites.light   = createLight(  0,  -3);
			// this.sprites.lightp2 = createLight( 67, -12);
			// this.sprites.lightp1 = createLight( 33,  -7);

			// this.lights = [
			// 	this.sprites.lightn2,
			// 	this.sprites.lightn1,
			// 	this.sprites.light,
			// 	this.sprites.lightp1,
			// 	this.sprites.lightp2,
			// ];

			// this.sprites.light   = createLight(  0,  -3);

			this.lights = [];
			for(let i=-33 * 3; i<33 * 4; i+=33) {
				if(i === 0) {
					i = 3; // adjust to perfection .. ha..
				}
				this.lights.push(createLight(0, i * -1));
			}

			// for(let i=-3; i> -33 * 4; i-=33) {
			// 	this.lights.push(createLight(0, i));
			// }


			this.lights.setColor = (idx, color) => {
				this.lights[idx].setColor(color);
			}

			this.lights.setColors = array => {
				this.lights.forEach((light, idx) => {
					const color = array[idx];
					if(color === null || color === undefined)
						return light.alpha = 0;
					light.alpha = 1;
					light.setColor(color);
				});
			}

			this.lights.setColors([]);

			window.lights = this.lights;

			setTimeout(() => {
				this.sprites.lever.scorer.updateLights();
			}, 100);
		}
	}

	async init(level) {
		const { resources } = this;

		const boxTextureName = LevelProps.propsForLevel(level).box.bg;

		SoundManager.use(SoundManager.BTN1).setVolumeModifier(0.25);
		SoundManager.use(SoundManager.BTN2).setVolumeModifier(0.25);

		const boxScale = 0.5;
		this.scale = boxScale;

		// Prebuilt factors for computing keyframes below
		this.xf = -resources[boxTextureName].texture.width  * boxScale / 2;
		this.yf = -resources[boxTextureName].texture.height * boxScale;
		
		window.sprites = this.sprites = {};
		// window.toRadians = toRadians; // for debugging

		const container = new PIXI.Container();
		container.filters = [ new DropShadowFilter() ];

		// Holds hash of animations
		this.anims = {};

		// Create the box AFTER the flag so the box is on top of the flag
		await this._initBoxGraphic(container, boxTextureName);

		// Start animations/etc loading after we finish
		setTimeout(() => this.initSecondaryResources(level), 1);

		// Setup layout stuff
		container._layout = () => {
			container.x = window.innerWidth  / 2;
			// lower than half so the focus is on the top of the box
			container.y = window.innerHeight * .625;
		};
		container._layout();

		// Store for other refs
		this.sprites.container = container;

		// Re-layout these things when window size changes
		const itemsNeedLayoutOnResize = [
			container,
		];

		window.addEventListener('resize', this._resizeHandler = () => {
			itemsNeedLayoutOnResize.forEach(item => item._layout());
		});

		window.box = this;
	}

	async initSecondaryResources(/*level*/) {

		const { sprites: { container }, resources } = this;

		// Load all via asset loader
		await AssetLoader.addAll(SassyBox.secondaryResources());
		
		// Now build list of promises for those resources
		const promises = [];

		// Add "stars" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_stars_sheet, resources.anim_stars_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.stars");
				return;
			}

			sprite.x     = 0;
			sprite.y     = -100;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			sprite.animationSpeed = 0.75;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn:  100,
				play:    750,
				fadeOut: 300
			});

			container.addChild(sprite);
			this.anims.stars = sprite;
		}));

		// Add "health" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_health_sheet, resources.anim_health_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.health");
				return;
			}

			sprite.x     = 0;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			// sprite.speed = .5;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn: 300,
				play: 1000,
				fadeOut: 300
			});

			container.addChild(sprite);
			this.anims.health = sprite;
		}));

		// Add flame wall animation
		promises.push(PixiUtils.getAnimatedSprite(anim_flamewall_blue_sheet, resources.anim_flamewall_blue_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.flamewall_blue");
				return;
			}

			// sprite.x    = this.sprites.box.texture.width / 8;
			// sprite.y = 0; 
			sprite.scale = new PIXI.Point(0.625, 0.75);
			sprite.tint  = 0xffffff;
			// sprite.speed = .5;
			sprite.y = -97;
			sprite.x = -15;

			sprite.alpha = 0;
			sprite.stop();

			const play = sprite.play.bind(sprite);
			
			// Stop after one time thru
			sprite.loop = false;
			sprite.onComplete = () => {
				if(sprite.animationSpeed > 0) {
					sprite.animationSpeed *= -1;
					play();
				} else {
					sprite.stop();
					sprite.alpha = 0;
				}
			};

			// Auto-show on play
			sprite.play = () => {
				sprite.alpha = 1;
				sprite.animationSpeed = .5;
				play();
			};
			
			container.addChild(sprite);
			this.anims.flamewall_blue = sprite;
		}));

		// Add flame wall animation
		promises.push(PixiUtils.getAnimatedSprite(anim_flamewall_red_sheet, resources.anim_flamewall_red_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.flamewall_red");
				return;
			}

			// sprite.x    = this.sprites.box.texture.width / 8;
			// sprite.y = 0; 
			sprite.scale = new PIXI.Point(-0.625, 0.75);
			sprite.tint  = 0xffffff;
			// sprite.speed = .5;
			sprite.y = -97;
			sprite.x =  15;

			sprite.alpha = 0;
			sprite.stop();

			const play = sprite.play.bind(sprite);
			
			// Stop after one time thru
			sprite.loop = false;
			sprite.onComplete = () => {
				if(sprite.animationSpeed > 0) {
					sprite.animationSpeed *= -1;
					play();
				} else {
					sprite.stop();
					sprite.alpha = 0;
				}
			};

			// Auto-show on play
			sprite.play = () => {
				sprite.alpha = 1;
				sprite.animationSpeed = .5;
				play();
			};
			
			container.addChild(sprite);
			this.anims.flamewall_red = sprite;
		}));

		this.anims.flamewall = {
			play: () => {
				this._playAnim('flamewall_blue');
				this._playAnim('flamewall_red');

			}
		}

		// Add "Green cloud" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_cloud_sheet, resources.anim_cloud_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.cloud");
				return;
			}

			sprite.x     = this.sprites.box.texture.width / 9;
			sprite.y     = -115;
			sprite.scale = new PIXI.Point(0.5, 0.5);

			// Colorize the cloud
			sprite.tint  = 0xFF0000;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn:  300,
				play:   1000,
				fadeOut: 300
			});

			
			container.addChild(sprite);
			this.anims.cloud = sprite;
		}));

		// Add sleeping animation
		promises.push(PixiUtils.getAnimatedSprite(anim_sleep_sheet, resources.anim_sleep_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.sleep");
				return;
			}

			sprite.x    = this.sprites.box.texture.width / 8;
			// sprite.y = 0; 
			sprite.scale = new PIXI.Point(0.75, 0.75);
			sprite.tint  = 0xffffff;
			// sprite.speed = .5;
			sprite.y = -110;
			sprite.x = -15;

			sprite.animationSpeed = 0.5;
			
			container.addChild(sprite);

			this.anims.sleep = sprite;
			
			// Only show when sleeping in setSleeping
			sprite.alpha = 0;
			// sprite.stop();

			this._startSleeping();			
		}));
// 
		// Add "sparkle" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_sparkle_sheet, resources.anim_sparkle_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.sparkle");
				return;
			}

			sprite.x     = 0;
			sprite.y     = 0;
			sprite.anchor.x = 0.5;
			sprite.anchor.y = 0.5;
			sprite.scale = new PIXI.Point(0.5 * this.scale * 1.5, 0.5 * this.scale * 1.5);
			// sprite.speed = 1.5;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn:  200,
				play:    900,
				fadeOut: 200
			});

			container.addChild(sprite);
			this.anims.sparkle = sprite;
		}));

		// Add "explosion" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_explosion_sheet, resources.anim_explosion_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.explosion");
				return;
			}

			sprite.x     = 0;
			sprite.y     = 0;
			sprite.anchor.x = 0.4825; // looks more centered than .5
			sprite.anchor.y = 0.5;
			sprite.scale = new PIXI.Point(this.scale * 2, this.scale * 2);
			// sprite.speed = 1.5;
			sprite.animationSpeed = 0.33;
			sprite.alpha = 0;
			sprite.stop();

			// Stop after one time thru
			sprite.loop = true;
			sprite.onLoop = () => {
				sprite.stop();
				sprite.alpha = 0;
			};

			// Auto-show on play
			const play = sprite.play.bind(sprite);
			sprite.play = () => {
				play();
				sprite.alpha = 1;
			};
			
			container.addChild(sprite);
			this.anims.explosion = sprite;
		}));

		

		// // Add firework animation
		// promises.push(PixiUtils.getAnimatedSprite(anim_firework3yellow_sheet, resources.anim_firework3yellow_img).then(sprite => {
		// 	if(!sprite) {
		// 		console.warn("Error loading anims.firework3yellow");
		// 		return;
		// 	}
			
		// 	const scaledSize = (this.baseBlockSize * this.scale);

		// 	sprite.x = scaledSize * .33; //this.sprite.texture.width / 8;
		// 	sprite.y = scaledSize * 2; 
		// 	sprite.scale = new PIXI.Point(0.5, 0.5);
		// 	// sprite.tint  = this.kittyColor; // 0xf9d453;
		// 	// sprite.speed = .5;
			
		// 	this.obj.addChild(sprite);

		// 	this.anims.firework3yellow = sprite;
			
		// 	// Only show when wanted
		// 	sprite.alpha = 0;
		// 	// sprite.stop();

		// 	PixiUtils.setupShortAnim(sprite, {
		// 		fadeIn:  50,
		// 		play:    950,
		// 		fadeOut: 50
		// 	});
		// }));

		try {
			await Promise.all(promises).catch(e => {
				console.warn("Error with secondary resources:", e);
			});
			// we were using .finally on Promise.all, but iOS Safari 11 didn't seem to support it
			// so now we just await
		
			this.anims._isLoaded = true;

			// // test failure
			// // this.anims = {};
			
			// // Still will have to check for individual anims
			this._isLoaded = true;
	
			// // apply color to sprites
			// this.setKittyColor(this.kittyColor);

			if (this.onSecondaryLoad)
				this.onSecondaryLoad();

			// this.setupEditorMode(PixiMatterContainer.EditorMode);
			
		} catch(e) {
			console.warn("Error caught inside Promise.all block for SassyBox:", e);
		}

		
	}

	shakeAnim(length = 333, speed = 33) {
		this.emit(SassyBox.SHAKE_EVENT, {});

		const container = this.container();
		if (navigator && navigator.vibrate)
			navigator.vibrate(length / 2);

		const tweenTo = new TWEEN.Tween(container)
			.to({ rotation: toRadians(-20) }, speed || 33);

		const tweenFrom = new TWEEN.Tween(container)
			.to({ rotation: toRadians( 20 )}, speed || 33);

		tweenTo.chain(tweenFrom);
		tweenFrom.chain(tweenTo);
		tweenTo.start();

		setTimeout(() => {
			tweenTo.stop();
			tweenFrom.stop();
			container.rotation = 0;
		}, length || 333);

		PixiUtils.touchTweenLoop();
	}

	_scored() {
		this.emit(SassyBox.POINT_EVENT);
		this._playAnim('stars');
		this._playAnim('sparkle');
		this.updateBoxTexture();
	}

	_scoreFailed() {
		this.emit(SassyBox.POINT_FAIL_EVENT);
		this._playAnim('cloud');
	}

	_startSleeping() {
		clearTimeout(this._sleepTid);
		if (this.anims.sleep)
			this._sleepTid = setTimeout(() => 
				PixiUtils.fadeIn(this.anims.sleep, 300),
				5000
			);
					
	}

	_stopSleeping() {
		clearTimeout(this._sleepTid);
		if (this.anims.sleep)
			PixiUtils.fadeOut(this.anims.sleep, 300);			
	}

	container() {
		return this.sprites.container;
	}

	_setupStickAnim(mallet) {
		// const { resources, scale: boxScale } = this;

		const { xf, yf } = this;
		const kf = {
			down2: {
				r: 0,
				x: xf * ( -50 / xf),
				y: yf * (  75 / yf),
			},
			up: {
				r: 0,
				x: xf * ( -50 / xf),
				y: yf * (-110 / yf),
			},

			'pressStart2': {
				r: -10,
				x: xf * ( -50 / xf),
				y: yf * (-110 / yf),
			},

			'pressStart3': {
				r: 10,
				x: xf * ( -50 / xf),
				y: yf * (-110 / yf),
			},
			
		}
		
		const _animPrep = kf => {
			const { x, y } = kf;
			return {
				x, y,
				rotation: toRadians(kf.r),
			}
		};
	
		const upDist = 0.01;
		const anim = [
			{ data: _animPrep(kf.down2) },
			{
				data: _animPrep(kf.up),
				at: upDist, 
			},
			...(
				new Array(33).fill().map((x, idx) =>
					idx % 2 === 0 ? 
					{ data: _animPrep(kf.pressStart3) } :
					{ data: _animPrep(kf.pressStart2) }
				)
			),
			{
				data: _animPrep(kf.up),
				at: 1 - upDist, 
			},
			{ data: _animPrep(kf.down2) }
		];

		mallet.animator = new AnimatorUtility(anim, {
			onUpdate: (/*value, anim*/) => {}
		}); 

		// "set" the mallet at time index 0 (down)
		mallet.animator.update(0, mallet);

		mallet.play = async (length=7000) => {
			if(!mallet._flag)
				return;

			mallet._flag.play();

			this._stopSleeping();
			
			await mallet.animator.start({ data: mallet, range: { start: 0, end: 1 }, length })

			mallet._flag.stop();
			mallet._flag.alpha = 0;

			this._startSleeping();
		}

		this.anims.flag = mallet;
	}

	updateBoxTexture(level) {
		if(!level)	
			level = this.currentLevel;
	
		// console.log(`bg: ${res}`, { res, idx, bg, level })

		const res = LevelProps.propsForLevel(level).box.bg;

		// this.bgSprite.texture = res.texture;
		this.setBackgroundTexture(res);
	}

	setBackgroundTexture(key) {
		let res = this.resources[key];
		if(!res) {
			// Use tile_orange as fallback instead of throwing error
			res = this.resources.tile_orange;
			
			//throw new Error("Invalid resource key " + key);
		}

		this.sprites.box.texture = res.texture;
	}

	doFlagRaising(time) {
		this._stopSleeping();
		
		gtag('event', 'flag_raising', {
			event_label: 'Flag Raising',
		});

		// "set" the mallet at time index 0 (down)
		this.sprites.mallet.animator.update(0, this.sprites.mallet);
		// Reward
		this.scorer.givePoint();
		// Update lever
		// lever.turnOff(true);
		// Play anim
		this.anims.flag.play(time);
	}

	async doMalletAttack(speed=null, range=null) {
		const { sprites: { lever, mallet }, scorer, brainEnv } = this;
		
		this._stopSleeping();

		gtag('event', 'mallet_attack', {
			event_label: 'Mallet Attack',
		});
						
		// Stop any running animation
		const anim = mallet.animator;
		if (anim.isRunning())
			anim.stop();


		// Start mallet moving to hit the lever
		lever.hitByMallet = false;
		await mallet.animateTo(1, speed, range);

		// console.log("+ lever:turnOn - startedMalletAnim = false", { hitByMallet: lever.hitByMallet, animWasRunning, wasHitByMallet });

		lever.startHitByBallet = false;

		// If mallet hit the lever while animating,
		// user doesn't get point, instead, turn off
		// the lever and animate mallet down (forcing animation)
		
		if(lever.hitByMallet) {
			lever.turnOff(true);
			brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_LEVER_USER_TURNOFF, false);
		} else
		if(!lever.wasHitByMallet) {
			scorer.good();
			brainEnv.changeNotify(BrainBoxEnvAdapter.ENV_MALLET_HIT, false);
		}

		if(!mallet.animator.isRunning() && mallet.animator.currentTime > 0) {
			mallet.animateTo(0, speed);
		}
	}

	_playAnim(id, ...args) {
		const anim = this.anims && this.anims[id];
		if (anim) {
			return anim.play(...args);
		} 
		return null;
	}

}
