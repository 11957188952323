import React from 'react';

import { mobileDetect } from 'utils/mobile-detect';

import {  faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { IconCircleButton } from './IconCircleButton';

import styles from './TextOverlayWidget.module.scss';

class TextOverlayWidget extends React.Component {
	componentDidMount() {
		if (this.props.handle)
			this.props.handle(this);
	}

	setTimer(length=0) {
		if (this.timerButton)
			this.timerButton.setTimer(length);
	}

	render() {
		const bottomModifier = {
			'--panel-bottom': this.props.panelBottomPercent || '70%'
		};
		
		return <div className={[
			(mobileDetect.hasNotch ? styles.hasNotch : ""),
			styles.widget,
			(this.props.visible ? styles.visible : "")
		].join(" ")}
		style={bottomModifier}>
			<div className={styles.panel} onClick={() => this.props.closeAction(true)}>
				<div className={styles.panelBody}>
					{typeof(this.props.text) === 'string' ? <p>{this.props.text}</p> : this.props.text}
				</div>
			</div>
			<div className={styles.buttonPanel}>
				
				{this.props.infoAction &&
					<IconCircleButton
						className={styles.button}
						onClick={() => this.props.infoAction(true)}
						icon={faInfo}
					/>
				}

				<IconCircleButton
					className={styles.button}
					handle={timerButton => this.timerButton = timerButton}
					onTimerExpired={() => this.props.closeAction(false)}
					onClick={() => this.props.closeAction(true)} 
					icon={this.props.actionIcon || faCheck}
				/>
			</div>
		</div>
	}
}

export default TextOverlayWidget;