import BasicKittyScene from './BasicKittyScene';

export default class KittyAndBalls extends BasicKittyScene {
	init() {
		this.setupBasicKittyScene({
			addKitty: true,
			kittyScale: 2,
			kittyRestitution: 1.0,
			addBalls: true,
			addSun: true,
		});
	}
}