import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

window.Sentry = Sentry;
if(process.env.NODE_ENV === 'production') {
	// josiahbryan+sassybox@gmail.com on Sentry
	Sentry.init({
		// DSN for the project, never changes
		dsn:     'https://0a16c122a5c645f083de1bbb7d4fa1e9@sentry.io/1836790',
		// Note: Releases are *registered* in Sentry by bin/gitcom
		release: 'sassybox-client@' + process.env.REACT_APP_GIT_REV,
	});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if(!window._pgStarted)
	serviceWorker.register();