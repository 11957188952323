import React from 'react';
import { ServerStore } from 'utils/ServerStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faShoppingCart, faInfoCircle, faPlay, faClipboardList, faLock, faRedo } from '@fortawesome/free-solid-svg-icons';

import styles from  './PlayMenu.module.scss';

// import WorldCard    from './LevelsList/WorldCard';
// import ResumeCard   from './LevelsList/ResumeCard';
// import TutorialCard from './LevelsList/TutorialCard';
// import StatsCard    from './LevelsList/StatsCard';
import { GameFactory } from 'game/Game';

import ShopPopup from 'game/scenes/KittyFlySleep/ShopPopup';
import WorldPopup from './PlayMenu/WorldPopup';
// import { HomeNavBar } from './HomeNavBar';

import Hammer from 'hammerjs';
import { numberWithCommas }     from 'utils/numberWithCommas';

import { MarketUtils } from 'utils/MarketUtils';

import { normalizeWorldImg } from './PlayMenu/imageUtils';
import { KITTY_ITEMS } from '../../../KittyActor';
import { MyStarsWidget } from '../../KittyFlySleep/MyStarsWidget';

export class PlayMenu extends React.Component {
	constructor() {
		super();
		this.state = {
			currentWorld: {},
			level:        {},
			levelState:   {},
			worldList:    [],
			pending:      true,
			wLocked: [],
			wActive: [],
			wCompleted: [],
		}
	}

	async componentDidMount() {
		this.refreshList();

		if (this.props.handle)
			this.props.handle(this);
	}

	hammerScroll(el) {
		// Great idea, but not working right now, and not a priority right now either.
		// - JB 20190325
		// el.hammer = new Hammer.Manager(el);
		// el.hammer.add(new Hammer.Pan());
		// el.hammer.on('panstart panmove panend', ev => {
		// 	const evt = ev.srcEvent ? ev.srcEvent : ev;
		// 	evt.preventDefault  && evt.preventDefault();
		// 	evt.stopPropagation && evt.stopPropagation();
		// 	const v = ev.overallVelocityX;
		// 	const x = -1 * (v < 0 ? Math.floor(v) : Math.ceil(v) );
		// 	console.log("el=",el,", ev.overallVelocityX=",v,", x=", x,", el.scrollLeft=", el.scrollLeft, ", ev=", ev);
		// 	el.scrollLeft += x;
		// });
	}

	async refreshList() {
		const { level, levelState, worldState } = await ServerStore.getLevelState(),
			patch = { level, levelState, worldState, currentLevelId: parseFloat(level.id) };
		
		// console.log("[LevelsList] patch=", patch);

		this.setState(patch);

		const home = await ServerStore.getHomeScreenData();

		this.setState({ ...home, pending: false });

		if(home.error) {
			console.error(home.error);
			window.location.reload();
		}
			

		const wActive  = [],
			wLocked    = [], 
			wCompleted = [],
			worldList  = home.worldList || [];

		worldList.forEach((world, idx) => {
			if(!world)
				return;

			normalizeWorldImg(world);

			if (world.bg === 'tutorial') {
				(world.state || (world.state = {})).isUnlocked = true;
				world.currentLevelId = 1;
				world.currentLevelNum = "1";
			} 
		
			if(world.state.isUnlocked && world.currentLevelId && !world.state.isCompleted)  {
				wActive.push(world);
			} else
			if(world.state.isCompleted) {
				wCompleted.push(world);
			} else {
				wLocked.push(world);
			}
			//calculatedProgressPercent
		});

		wActive.sort((a,b) => {
			if(!a.state.isCompleted && b.state.isCompleted)
				return -1;

			if(a.state.isCompleted && !b.state.isCompleted)
				return +1;

			return 0;
		});

		this.setState({ wActive, wLocked, wCompleted });

		if(false) {
			if(wLocked[1]) {
				this.worldPopup.show(wLocked[1]);
				console.warn("[PlayMenu] shopwing worldPopup with wLocked[1] just for debugging");
			}

			// if(this.shopPopup) {
			// 	this.shopPopup.show('items')
			// 	console.warn("[PlayMenu] showing shopPopup for debugging");
			// }
		}
	}

	setActive(flag, externalOptions) {
		// console.warn("[PlayMenu.setActive] flag=", flag);
		this.isActive = flag;
		this.externalOptions = externalOptions || { fromResult: {} };
		if (this.onActiveChange)
			this.onActiveChange(flag);

		if(!this.el)
			return flag;
	
		if(flag) {
			ServerStore.metric("game.play_menu.shown");

			this.el.classList.add(styles.active);
			
			if(this.worldPopup && 
				externalOptions &&
				externalOptions.fromResult &&
				externalOptions.fromResult.world)
				this.worldPopup.show(externalOptions.fromResult.world, externalOptions.fromResult);
		} else {
			ServerStore.metric("game.play_menu.hidden");

			// just to be safe
			this.worldPopup.hide();

			this.el.classList.remove(styles.active);
		}

		return flag;
	}

	setEl(el) {
		this.el = el;
		if(!el)
			return;

		// console.log("[PlayMenu.setEl] isActive=", this.isActive);
			
		if(this.isActive)
			this.setActive(this.isActive);

		const target = this.el.querySelector('[data-purpose=swipe-down-target]'),
			mc       = new Hammer.Manager(target),
			Swipe    = new Hammer.Swipe({ direction: Hammer.DIRECTION_DOWN });
			
		mc.add(Swipe);
		mc.on('swipedown', () => {
			if (this.props.closeSelf)
				this.props.closeSelf();
		});

	}

	toggleActive() {
		return this.setActive(!this.isActive);
	}

	playBtnClicked = evt =>  {
		evt.stopPropagation();

		this.startGame({ levelId: this.state.level.id });
	}

	startGame(opts, cw) {

		if(!cw)
			cw = this.state.currentWorld;

		const state = ( cw || {} ).state || {};

		// console.log("[PlayMenu.startGame]", { opts, cw });

		if(!state.isUnlocked && cw.bg !== 'tutorial')
			return this.unlockWorld();

		if(this._started) {
			console.log("Game already starting, ignoring multiple calls to start");
			return;
		}

		this._started = true;

		// ServerStore.metric("game.home_screen.play_menu.start_game");

		// console.warn(this.state.currentWorld)
		GameFactory.game.setScene('KittyFlySleep', opts);
	}

	async unlockWorld() {
		const world = this.state.currentWorld;
		if(!world) {
			throw new Error("No this.state.currentWorld");
		}

		const result = await MarketUtils.unlockWorld(world);
		if(result) {
			(world.state || (world.state = {})).isUnlocked = true;
			this.startGame({ levelId: result }, world);
		} else {
			// this.worldPopup.show(world);
		}
	}

	worldClicked = async (world, evt) => {
		evt.stopPropagation();
		// console.log("[worldClicked]", world.id, world.name, world);

		if(world.state.isUnlocked) {
			if(world.state.isCompleted) {
				// Notify metric server
				ServerStore.metric("game.play_menu.world_clicked.replay.prompt_shown");

				if((await window.AlertManager.fire({
					title: 'Replay ' + world.name + '?',
					text:  'You\'ll only get the stars you missed last time though...',
					type:  'warning',
					showCancelButton:  true,
					confirmButtonText: 'Yes, Replay',
					cancelButtonText:  'No thanks',
					reverseButtons:    true
				})).value) {
					const result = await ServerStore.replayWorld(world);
					if(result.level) {
						ServerStore.metric("game.play_menu.world_clicked.replay.success");
						this.startGame({
							levelId: result.level.id
						}, world);
					} else {
						ServerStore.metric("game.play_menu.world_clicked.replay.error");
						console.error("Could not replay world, see any other errors on console, result we got was:", result);
					}
				} else {
					ServerStore.metric("game.play_menu.world_clicked.replay.canceled");
				}

			} else {
				ServerStore.metric("game.play_menu.world_clicked.play");
				// console.log("Calling startGame with currentLevelId:", world.currentLevelId);
				this.startGame({ levelId: world.currentLevelId, isReplay: world.state.isCompleted }, world);
			}
		} else {
			ServerStore.metric("game.play_menu.world_clicked.locked");
			this.worldPopup.show(world);
			// if(!world.state.isUnlocked) {
				// this.unlockWorld(world);
			// } else {
			// 	window.AlertManager.fire({
			// 		type: 'error',
			// 		title: 'Trouble playing ' + world.name,
			// 		text: "Not locked and no current level - not sure what to do, tell Josiah, this shouldn't happen",
			// 		confirmButtonText: "Thanks",
			// 		showCloseButton: true,
			// 	});
			// }
		}
	}

	async purchaseItem(item, evt) {
		evt && evt.stopPropagation();
		const { itemDef } = item;
		
		ServerStore.metric("game.play_menu.item_clicked");
		const purchased = await MarketUtils.purchaseItem(itemDef);
		if(purchased && !itemDef.isConsumable) {
			this.props.actor.setItemEnabled(itemDef, true);
		}
		this.setState({ purchased: item })
	}
	
	
	render() {
		const state = this.state || {},
			level = state.level || {},
			world = state.currentWorld || {},
			{ actor } = this.props;

		// const invFeatures = [], invActions = [], 
		const marketItems = [];

		const has = {};
		if(actor) {
			actor.items(true, 'all').forEach(item => {
				has[item.itemDef.id] = item.amount > 0 ? item.amount : false;
				// if(item.itemDef.setEnabled) {
				// 	item.type = 'feature';
				// 	item.isEnabled = actor.itemEnabled(item.itemDef);
				// 	invFeatures.push(item);
				// } else
				// if(item.itemDef.action) {
				// 	item.type = 'action';
				// 	item.isActive = actor.activeItem && actor.activeItem.id === item.itemDef.id;
				// 	// item.isActive = [activeItem.id,  item.itemDef.id].join("::");
				// 	if(item.amount > 0)
				// 		invActions.push(item);
				// }
			});
		}

		Object.values(KITTY_ITEMS).forEach(itemDef => {
			if(!has[itemDef.id] || (itemDef.isConsumable && has[itemDef.id] < itemDef.maxItems)) {
				marketItems.push({ itemDef });
			}
		});
		
		const renderWorldTile = world => <div className={styles.hCard} key={world.id}>
			<div className={styles.mainWrap} onClick={evt => this.worldClicked(world, evt)}>
				{world.bgImgUrl && 
					<img src={world.bgImgUrl} alt="Level Background"/>}
				<div className={styles.mainLabel}>
					{world.name}
				</div>
				<div className={world.state.isCompleted ? styles.lockCircle : styles.playCircle}>
					<FontAwesomeIcon icon={world.state.isCompleted ? faRedo : faPlay}/>
				</div>
			</div>
			<div className={styles.progressBar}>
				<div className={styles.inner} style={{
					width: world.calculatedProgressPercent + '%'
				}}/>
			</div>
			{world.currentLevelNum > 0 &&
				<div className={styles.mainFooter} 
					onClick={e => this.worldPopup.show(world) && e.stopPropagation()}>
					{world.bg === 'tutorial' ? 
						<label style={{textAlign: 'center', width: '100%'}}>
							Tutorial
						</label>:
						<>
							<label>Level {numberWithCommas(world.currentLevelNum)}</label>
							<FontAwesomeIcon icon={faInfoCircle}/>
						</>
					}
				</div>
			}
		</div>;

		return <div className={styles.play} ref={el => this.setEl(el)}>
			<ShopPopup 
				actor={this.props.actor} 
				handle={handle => (this.shopPopup = handle)} 
				transparentBg={true} 
				onChange={popupVisible => this.setState({ popupVisible })}
			/>

			<WorldPopup
				actor={this.props.actor} 
				handle={handle => (this.worldPopup = handle)} 
				onChange={popupVisible => this.setState({ popupVisible })}
			/>

			{/* .content class to get scrollable */}
			<div className={styles.content + 
					(this.state.popupVisible ? " " + styles.contentHidden : "")}>
				{/* Use a separate element for padding because .content adjusts right padding to hide scrollbars on 1.0 DPI devices (e.g. desktops/laptops) */}
				<div className={styles.padding}>
					<div data-purpose="swipe-down-target">
						{/* Pass-thru layer over kitty actor rendered in PIXI */}
						<div className={styles.clickLayer}></div>

						<div className={styles.mainHeaderTitle}>
							<div className={styles.line1}>
								{ServerStore.currentCat.name} wants to play
							</div>
							<div className={styles.line3}>
								{world.name}
							</div>
							<div className={styles.line2}>
								Level {numberWithCommas(level.levelNum)}
							</div>
						</div>
						<div className={styles.mainHeaderControls}>
							<div className={styles.btn + " " + styles.btnSecondary}
								onClick={e => this.shopPopup.show('items') && e.stopPropagation()}
							>
								<FontAwesomeIcon icon={faShoppingCart} />
								<label>Items</label>
							</div>

							<div className={styles.btn + " " + styles.playBtn} onClick={this.playBtnClicked}>
								<FontAwesomeIcon icon={faPlay}/> 
								<label>Play Level</label>
							</div>

							<div className={styles.btn + " " + styles.btnSecondary}
								onClick={e => this.shopPopup.show('records') && e.stopPropagation()}
							>
								<FontAwesomeIcon icon={faClipboardList}/>
								<label>Streaks</label>
							</div>
						</div>
					</div>

					{this.state.pending && <div className={styles.loadingSpinner}>
						<FontAwesomeIcon icon={faStar} spin />
					</div>}

					{this.state.wActive && this.state.wActive.length > 0 && <>
						<div className={styles.cardListHeader}>
							Continue Playing with {ServerStore.currentCat.name} ({this.state.wActive.length})
						</div>

						{/* Horizontal list of worlds/litters player has unlocked, and not completed. Once clicked, they open another popup for that world */}

						<div className={styles.hCardScroll} ref={el => this.hammerScroll(el)}>
							{this.state.pending ? <b></b> : 
								<>
								{(this.state.wActive||[]).map(world => renderWorldTile(world))}
							</>}

							<div className={styles.hCardEndHack}></div>
						</div>
					</>}
					
					

					{this.state.wLocked && this.state.wLocked.length > 0 && <>
						<div className={styles.cardListHeader}>
							Available Litters of Levels ({this.state.wLocked.length})

							<MyStarsWidget/>
						</div>
						{/*  Horizontal list of worlds that are still locked. Once clicked, unlock and open world popup */}

						<div className={styles.hCardScroll} ref={el => this.hammerScroll(el)}>
							{this.state.pending ? <b></b> : 
								<>
								{(this.state.wLocked||[]).map(world => 

									<div className={styles.hCard} key={world.id} onClick={evt => this.worldClicked(world, evt)}>
										<div className={styles.mainWrap}>
											{world.bgImgUrl && 
												<img src={world.bgImgUrl} alt="Level Background"/>}
											<div className={styles.mainLabel}>
												{world.name}
											</div>
											<div className={styles.lockCircle}>
												<FontAwesomeIcon icon={faLock}/>
											</div>
										</div>
										<div className={styles.mainFooter}>
											<label className={styles.costLabel}>
												{numberWithCommas(world.starCost)}
												<FontAwesomeIcon icon={faStar}/>
											</label>
											<FontAwesomeIcon icon={faInfoCircle}/>
										</div>
									</div>
								)}
							</>}

							<div className={styles.hCardEndHack}></div>
						</div>
					</>}



					{/* 
					<div className={styles.hCardScroll}>
						<div className={styles.hCard}>
							<div className={styles.mainWrap}>
								<img src='https://placekitten.com/96/139' alt="I Can Haz Kitten"/>
								<div className={styles.mainLabel}>
									Blue 1.0
								</div>
								<div className={styles.lockCircle}>
									<FontAwesomeIcon icon={faLock}/>
								</div>
							</div>
						</div>*/}

					{marketItems && marketItems.length > 0 && <>
						<div className={styles.cardListHeader}>
							Market Items
							<MyStarsWidget/>
						</div>
						{/*  Horizontal list of worlds that are still locked. Once clicked, unlock and open world popup */}

						<div className={styles.hCardScroll} ref={el => this.hammerScroll(el)}>
							{(marketItems||[]).map(item => 

								<div className={styles.hCard} key={item.itemDef.id} onClick={evt => this.purchaseItem(item, evt)}>
									<div className={styles.mainWrap}>
										{/* <img src='https://placekitten.com/96/139' alt="I Can Haz Kitten"/> */}
										{item.itemDef.iconUrl && 
											// eslint-disable-next-line jsx-a11y/img-redundant-alt
											<img src={item.itemDef.iconUrl} className={styles.marketImage} alt="Item Image"/>}
										<div className={styles.mainLabel}>
											{item.itemDef.name}
										</div>
										<div className={styles.lockCircle}>
											<FontAwesomeIcon icon={faLock}/>
										</div>
									</div>
									<div className={styles.mainFooter}>
										<label className={styles.costLabel}>
											{item.itemDef.starCost > 0 ? <>
												{item.itemDef.starCost}
												<FontAwesomeIcon icon={faStar}/>
											</> : <>
												${item.itemDef.dollarCost}{item.itemDef.isSubscription && '/' + item.itemDef.period}
											</>}
										</label>
										{/* <FontAwesomeIcon icon={faInfoCircle}/> */}
									</div>
								</div>
							)}
							
							<div className={styles.hCardEndHack}></div>
						</div>
					</>}



					{this.state.wCompleted && this.state.wCompleted.length > 0 && <>
						<div className={styles.cardListHeader}>
							Play Again ({this.state.wCompleted.length})
						</div>

						{/* Horizontal list of worlds have been completed. Once clicked, open world popup */}

						<div className={styles.hCardScroll} ref={el => this.hammerScroll(el)}>
							{this.state.pending ? <b></b> : 
								<>
								{(this.state.wCompleted||[]).map(world => renderWorldTile(world))}
							</>}

							<div className={styles.hCardEndHack}></div>
						</div>
					</>}

					

{/* 
					{this.state.pending ? <b></b> : 
						<>
						{(this.state.worldList||[]).map(world => 
							<WorldCard 
								world={world} 
								key={world.id} 
								currentLevel={this.state.level}
								unlockWorld={world => this.unlockWorld(world)}
								// worldClicked={(world, evt) => this.worldClicked(world, evt)}
							/>
						)}
					</>} */}

				</div>
			</div>
		</div>
	}

};