export const affirmations = `
	Good job!
	Wow, you did awesome!
	Who taught you so well?!
	Impressive...
	You should teach classes on this!
	You're smart and funny and ... yeah, you're pretty cool.
	You deserve a nap for what you just did!
	You’re the cats meow!
	Great job! Now let’s go get some Taco Bell!
`.split("\n").map(x => x.trim()).filter(x => x);

export const affirmationsActiveTense = `
	You're beautiful and I care about you and I hope you have a great day.
	You're doing awesome, keep up the good work!
	You're doing awesome!
	You should teach classes on this!
	You're smart and funny and ... yeah, you're pretty cool.
	Can't wait to see how you do on this level!
	You're doing amazingly well!
	Mmeeowwwwwwww
	You are the only you. (Ps 139)#info:bible
	You’re perfectly made as you are. Be your best you...#info:bible
	When in doubt follow the path that warms your heart and lights you up. (Ps 119)#info:bible
	You were created for a purpose beyond your wildest imagination.#info:bible
	Put your hands over your heart and felt that heartbeat. That’s purpose.#info:bible
`.split("\n").map(x => x.trim()).filter(x => x);

export const encouragements = `
	You can do it!
	Keep going, you got this!
	Nobody is purrrrrfect, you can do it!
	I believe in you, you go this!
	Don't sweat, just pawws, pray (Psalm 33.20), and keep going!
`.split("\n").map(x => x.trim()).filter(x => x); // todo: Add info:bible support to encouragements array

export function randomAffirmation(opts = { activeTense: false }) {
	const list = opts.activeTense ? affirmationsActiveTense: affirmations; 	
	return list[Math.floor(Math.random() * list.length)];
}

export function randomEncouragement() {
	return encouragements[Math.floor(Math.random() * encouragements.length)];
}