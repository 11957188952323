
const DEFAULT_CEILING_INSET = 6;
const DEFAULT_FLOOR_HEIGHT = 1;

// const TuningParams = {
// 	lineStartProbability: 0.8,
// 	lineEndProbability: 0.8125, //0.7,

// 	silverTile: 0.425,
// 	glassTile:  0.80, 
// 	badTile:    0.85,
// 	starTile:   0.95,
// 	powerTile:  0.96,
// 	healthTile: 1.0,
// };

const DefaultTuningParams = {
	lineStartProbability: 0.8,
	lineEndProbability: 0.8125, //0.7,

	silverTile: 0.425,
	glassTile:  0.80, 
	badTile:    0.85,
	starTile:   0.95,
	powerTile:  0.96,
	healthTile: 1.0,

	maxStars: 100,

	breakMaze: 0.9,
};

const backgroundOptionsList = `
mountains
stars
water
bg_img_bubble_blue
bg_img_burst_blue
bg_img_wave_blue
bg_img_bubble_orange
bg_img_burst_orange
bg_img_wave_blue2
bg_img_bubble_purple
bg_img_burst_purple
bg_img_wave_pink
bg_img_ice
`.split("\n").map(x => x.trim()).filter(x => x);

function randomBgImageType() {
	
	return backgroundOptionsList[Math.floor(Math.random() * backgroundOptionsList.length)];
}

function parseMapBuffer(data) {
	const rows = data.split("\n");
	const meta = rows[0].startsWith('{') ? JSON.parse(rows.shift()) : {};
	const worldTileWidth  = meta.width + 1  || rows[0].length + 1; // 0-based arrays and such
	const worldTileHeight = meta.height + 
			(meta.ceilingInset || DEFAULT_CEILING_INSET) +
			(meta.floorHeight  || DEFAULT_FLOOR_HEIGHT)  
			|| 
		rows.length + 
			DEFAULT_CEILING_INSET + 
			DEFAULT_FLOOR_HEIGHT; // DEFAULT_FLOOR_HEIGHT for floor and space below floor

	const mapData = rows.map(row => row.split(''));

	return {
		worldTileHeight,
		worldTileWidth,
		mapData,
		meta
	};
}

function generateRandomMaze(mazeWidth=20, mazeHeight=20) {

	const maze = [];
	// const mazeWidth = 20;
	// const mazeHeight = 20;

	function makeMaze() {
		
		const moves = [];
		for(var i = 0; i < mazeHeight; i ++){
			maze[i] = [];
			for(var j = 0; j < mazeWidth; j ++){
				maze[i][j] = 1;
			}
		}

		let posX = 1;
		let posY = 1;
		maze[posX][posY] = 0; 
		moves.push(posY + posY * mazeWidth);
		
		while(moves.length){
			var possibleDirections = "";
			if(posX+2 > 0 && posX + 2 < mazeHeight - 1 && maze[posX + 2][posY] === 1){
					possibleDirections += "S";
			}
			if(posX-2 > 0 && posX - 2 < mazeHeight - 1 && maze[posX - 2][posY] === 1){
					possibleDirections += "N";
			}
			if(posY-2 > 0 && posY - 2 < mazeWidth - 1 && maze[posX][posY - 2] === 1){
					possibleDirections += "W";
			}
			if(posY+2 > 0 && posY + 2 < mazeWidth - 1 && maze[posX][posY + 2] === 1){
					possibleDirections += "E";
			} 
			if(possibleDirections){
					var move = Math.floor(Math.random() *  possibleDirections.length);
					// eslint-disable-next-line default-case
					switch (possibleDirections[move]){
						case "N": 
							maze[posX - 2][posY] = 0;
							maze[posX - 1][posY] = 0;
							posX -= 2;
							break;
						case "S":
							maze[posX + 2][posY] = 0;
							maze[posX + 1][posY] = 0;
							posX += 2;
							break;
						case "W":
							maze[posX][posY - 2] = 0;
							maze[posX][posY - 1] = 0;
							posY -= 2;
							break;
						case "E":
							maze[posX][posY + 2]=0;
							maze[posX][posY + 1]=0;
							posY += 2;
							break;         
					}
					moves.push(posY + posX * mazeWidth);     
			}
			else{
					var back = moves.pop();
					posX = Math.floor(back / mazeWidth);
					posY = back % mazeWidth;
			}
			// drawMaze(posX, posY);          
			// console.log(posX, posY);
		}      
	}

	makeMaze();
	const buffer = [];
	maze.forEach(row => buffer.push(row.map(x => x ? '#' : ' ').join('')));
	// console.log(buffer.join("\n"));
	return buffer.join("\n");
}

function generateBlankMap(worldTileWidth, worldTileHeight) {
	const maze = [];
	for(var i = 0; i < worldTileHeight; i ++){
		maze[i] = [];
		for(var j = 0; j < worldTileWidth; j ++){
			maze[i][j] = 0;
		}
	}
	const buffer = [];
	maze.forEach(row => buffer.push(row.map(x => x ? '#' : ' ').join('')));
	// console.log(buffer.join("\n"));
	return buffer.join("\n");
}

function generateRandomMazeMap(worldTileWidth, worldTileHeight, TuningParams=DefaultTuningParams, debug=false) {
	const buffer = generateRandomMaze(worldTileWidth, worldTileHeight);
	const lines = buffer.split("\n");
	const encodedBuffer = [];
	let numStars = 0;
	lines.forEach((mazeLine, gy) => {
		let lineStart = false;
		
		const encodedRow = [];

		for(let x=0;x<mazeLine.length; x++) {
			if(!lineStart && Math.random() > TuningParams.lineStartProbability) {
				lineStart = true;
			}

			if(lineStart && Math.random()  > TuningParams.lineEndProbability) {
				lineStart = false;
			}

			if(lineStart) {
				const type = Math.random();
				if(type < TuningParams.silverTile) {
					// encodedRow.push('#');
					encodedRow.push(mazeLine[x]);
				} else
				if(type < TuningParams.glassTile) {
					encodedRow.push('~');
				} else 
				if(type < TuningParams.badTile) {
					encodedRow.push('x');
				} else 
				if(type < TuningParams.starTile) {//} && numStars < TuningParams.maxStars) {
					encodedRow.push('*');
					numStars ++;
				} else
				if(type < TuningParams.powerTile) {
					encodedRow.push('^');
				} else 
				if(type < TuningParams.healthTile) {
					encodedRow.push('+');
				} else {
					encodedRow.push(' ');
				}
			} else {
				encodedRow.push(mazeLine[x]); //(Math.random() > TuningParams.breakMaze) ? ' ' : mazeLine[x]);
			}
		}

		encodedBuffer.push((debug ? `[row:${gy}]`:'') + encodedRow.join(''));

	});

	const meta = {
		width: worldTileWidth,
		height: worldTileHeight,
		// floorHeight: DEFAULT_FLOOR_HEIGHT,
		// ceilingInset: DEFAULT_CEILING_INSET,
		bg: randomBgImageType(),
		numStars,
	};

	// const output = JSON.stringify(meta) + "\n" + encodedBuffer.join("\n");
	return { meta, buffer: encodedBuffer.join("\n") };
}

function generateRandomMap(worldTileWidth, worldTileHeight, TuningParams=DefaultTuningParams, debug=false) {
	console.log("[generateRandomMap]", { worldTileWidth, worldTileHeight, TuningParams });

	const buildLevel = () => {

		let numStars = 0;
		let lineStart = false;

		const encodedBuffer = [];

		for(let gy=0; gy<worldTileHeight; gy++) {
			lineStart = false;

			const encodedRow = [];

			for(let gx=0; gx<worldTileWidth; gx++) {
				if(!lineStart && Math.random() > TuningParams.lineStartProbability) {
					lineStart = true;
				}

				if(lineStart && Math.random()  > TuningParams.lineEndProbability) {
					lineStart = false;
				}

				if(lineStart) {
					const type = Math.random();
					if(type < TuningParams.silverTile) {
						encodedRow.push('#');
					} else
					if(type < TuningParams.glassTile) {
						encodedRow.push('~');
					} else 
					if(type < TuningParams.badTile) {
						encodedRow.push('x');
					} else 
					if(type < TuningParams.starTile && numStars < TuningParams.maxStars) {
						encodedRow.push('*');
						numStars ++;
					} else
					if(type < TuningParams.powerTile) {
						encodedRow.push('^');
					} else 
					if(type < TuningParams.healthTile) {
						encodedRow.push('+');
					} else {
						encodedRow.push(' ');
					}
				} else {
					encodedRow.push(' ');
				}
			}

			encodedBuffer.push((debug ? `[row:${gy}]`:'') + encodedRow.join(''));
		}

		// reverse because built bottom-up
		// return encodedBuffer.reverse().join("\n");

		return { numStars, buffer: encodedBuffer.join("\n") };
	};

	// When generating a random level, we must include at least one star block so they can *complete* the level
	let buffer = '', count = 0, numStars = 0;
	while(numStars === 0 && count ++ < 100) {
		console.log("[generateRandomMap]", count);
		const res = buildLevel();
		buffer = res.buffer;
		numStars = res.numStars;
	}

	const meta = {
		width: worldTileWidth,
		height: worldTileHeight,
		// floorHeight: 2,
		bg: randomBgImageType(),
		numStars,
	};

	// buffer = JSON.stringify(meta) + "\n" + buffer;
	return { meta, buffer };
	
	// return buffer;
}

const findPath = (from, to, buffer) => {

	const debug = false;

	const parsed = parseMapBuffer(buffer),
		maze = parsed.mapData;
	// console.log(maze);

	const valueAt = pos => 
		pos.y >= 0 && pos.y < maze.length && 
		pos.x >= 0 && pos.x < maze[pos.y].length && 
		maze[pos.y][pos.x];
	const nodeAt  = pos => Object.assign({}, pos, { value: valueAt(pos), id: [pos.x, pos.y].join('x') });

	const start = nodeAt(from),
		goal = nodeAt(to);

	const dist_between = (a,b) => {
		// euclidian distance
		return Math.sqrt(
			Math.pow((a.x - b.x), 2) +
			Math.pow((a.y - b.y), 2)
		);
	}

	const heuristic_cost_estimate = (a,b) => {
		return dist_between(a,b);
	}

	const  reconstruct_path = (cameFrom, current) => {
		// console.log("[aStar] reconstruct_path: ",{ current, cameFrom });
		const total_path = [ current ];
		while(cameFrom[current.id]) {
			// console.log("[aStar] reconstruct_path: current.id=", current.id, cameFrom[current.id]);
			current = cameFrom[current.id];
			total_path.push(current);
		};
		return total_path;
	}

	const neighbors_of_current = node => {
		const neighbors = [];

		// Helper, only returns node if node is "empty"
		const ivn = p => {
			const n = nodeAt(p);
			if(n.value && n.value !== '#')
				return n;
			return null;
		}

		let n;
		// Check basic Up, Down, Right, Left directions
		(n = ivn({ x: node.x, y: node.y + 1 })) && neighbors.push(n);
		(n = ivn({ x: node.x, y: node.y - 1 })) && neighbors.push(n);
		(n = ivn({ x: node.x + 1, y: node.y })) && neighbors.push(n);
		(n = ivn({ x: node.x - 1, y: node.y })) && neighbors.push(n);

		/* To move on a diag, must check +1,0 and 0,-1 to make sure empty,
		   and only then can we check +1,-1 to see if we can move there.
		   E.g. the '+' in the below diagram must be empty to moe to '?'

		##+?#
		##x+#
		#####
		#####
		*/
		
		// Move up-right
		(n = ivn({ x: node.x, y: node.y - 1 })) &&
		(n = ivn({ x: node.x + 1, y: node.y })) &&
		(n = ivn({ x: node.x + 1, y: node.y - 1 })) && neighbors.push(n);

		// Move down-right
		(n = ivn({ x: node.x, y: node.y + 1 })) &&
		(n = ivn({ x: node.x + 1, y: node.y })) &&
		(n = ivn({ x: node.x + 1, y: node.y + 1 })) && neighbors.push(n);
		

		// Move up-left
		(n = ivn({ x: node.x, y: node.y - 1 })) &&
		(n = ivn({ x: node.x - 1, y: node.y })) &&
		(n = ivn({ x: node.x - 1, y: node.y - 1 })) && neighbors.push(n);

		// Move down-left
		(n = ivn({ x: node.x, y: node.y + 1 })) &&
		(n = ivn({ x: node.x - 1, y: node.y })) &&
		(n = ivn({ x: node.x - 1, y: node.y + 1 })) && neighbors.push(n);

		
		return neighbors;
	};

	// Most of the code is a mostly verbatim translation of the pesudocode at https://en.wikipedia.org/wiki/A*_search_algorithm

    // The set of nodes already evaluated
    let closedSet = [];

    // The set of currently discovered nodes that are not evaluated yet.
    // Initially, only the start node is known.
    let openSet = [ start ];

    // For each node, which node it can most efficiently be reached from.
    // If a node can be reached from many nodes, cameFrom will eventually contain the
    // most efficient previous step.
    const cameFrom = {}; //:= an empty map

    // For each node, the cost of getting from the start node to that node.
    const gScore = {}; //:= map with default value of Infinity

    // The cost of going from start to start is zero.
    gScore[start.id] = 0;

    // For each node, the total cost of getting from the start node to the goal
    // by passing by that node. That value is partly known, partly heuristic.
    const fScore = {}; //:= map with default value of Infinity

    // For the first node, that value is completely heuristic.
	fScore[start] = heuristic_cost_estimate(start, goal)
	
	// while openSet is not empty
	while(openSet.length > 0) {
		debug && console.log("\n\n\n********** openSet:", openSet);
		//current := the node in openSet having the lowest fScore[] value
		let current = null, lowestScore = Number.MAX_VALUE;
		openSet.forEach(node => {
			if(!current || (fScore[node.id] || Number.MAX_VALUE) < lowestScore) {
				lowestScore = fScore[node.id];
				current = node;
			}
		});
		debug && console.log("1. ", { current, goal, cameFrom });

        if(current.id === goal.id) 
            return reconstruct_path(cameFrom, current)

        openSet = openSet.filter(x => x.id !== current.id);
		closedSet.push(current);
		
		debug && console.log("2. ", { current, closedSet, openSet });

		//for each neighbor of current
		const neighbors = neighbors_of_current(current);

		debug && console.log("3. ", neighbors);

		for(let i=0; i<neighbors.length; i++) {
			const neighbor = neighbors[i];
			debug && console.log("3x" + i, neighbor);
		
            if(closedSet.find(x => x.id === neighbor.id))
                continue;		// Ignore the neighbor which is already evaluated.

			// The distance from start to a neighbor
			const tentative_gScore = (gScore[current.id] || 9999999) + dist_between(current, neighbor);
			
			debug && console.log("3x" + i + ".1", { neighbor, tentative_gScore });

            if(!openSet.find(x => x.id === neighbor.id))	// Discover a new node
                openSet.push(neighbor);
            else if(tentative_gScore >= (gScore[neighbor.id] || Infinity))
				continue; // ignore neighbor...?

			const new_fScore = (gScore[neighbor.id] || 9999999) + heuristic_cost_estimate(neighbor, goal);
			debug && console.log("3x" + i + ".2", { neighbor, new_fScore });
			
            // This path is the best until now. Record it!
            cameFrom[neighbor.id] = current;
            gScore[neighbor.id] = tentative_gScore
			fScore[neighbor.id] = new_fScore;
		};

		debug && console.log("4.", { cameFrom, openSet, closedSet });
		// return;
	}

	// console.log("Hit end without finding goal");
	return [];
}

module.exports = {
	parseMapBuffer,
	generateRandomMap,
	generateRandomMazeMap,
	generateBlankMap,
	randomBgImageType,
	backgroundOptionsList,
	DefaultTuningParams,
	DEFAULT_FLOOR_HEIGHT,
	DEFAULT_CEILING_INSET,
	findPath
}

// async function main() {
// 	// const m = generateRandomMazeMap(10, 10);
// 	// console.log(m);
// 	// const m = generateRandomMap(10, 10);
// 	// console.log(m);

// 	// const map = { meta: { width: 10, height: 10, bg: 'mountains', numStars: 2 },
// 	// buffer:
// 	//  '**########\n# #~    ##\n# ### # #~\n#   # # ##\n###~~~^ #~\n# # # ~+##\n# # ### #~\n#   ~   ##\n#####~####\n##########' };
// 	const map = { meta:
// 		{ width: 10, height: 10, bg: 'bg_img_bubble_purple', numStars: 4 },
// 	   buffer:
// 		'          \n#x#~#~#   \n*   #*#~x#\n~####~#~#~\n #x       \n        # \n* ~       \n   #~~~###\n ###*^ ~~ \n    ~~~#x#' }

// 	// const testBuffer2 = `x *`;
	
// 	// const m = parseMapBuffer(map.buffer);
// 	// console.log(m);

// 	// const d = aStar({x:1, y:7}, {x:1, y:0}, map.buffer);
// 	// const d = aStar({x:0, y:0}, {x:2, y:0}, testBuffer2);
// 	const d = aStar({x:0, y:9}, {x:9, y:0}, map.buffer);
// 	console.log(d);
// 	console.log(map.buffer);
// }

// main().then(_ => process.exit()).catch(E => console.error(E));
