// Originally from https://raw.githubusercontent.com/keppelen/react-facebook-login/master/src/facebook.js
// https://github.com/keppelen/react-facebook-login/blob/master/styles/facebook.scss
// https://github.com/keppelen/react-facebook-login/blob/master/src/facebook-with-button.js
// Customized for my use - JB 201902156
// @flow
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Encode object to url parameters
 *
 * @param			{Object} paramsObj The object needs to encode as url parameters
 * @return		 {String} Encoded url parameters
 */
const getParamsFromObject = params => '?' + Object.keys(params)
	.map(param => `${param}=${encodeURIComponent(params[param])}`)
	.join('&');



/**
 * Extract the value for a given key in a url-encoded parameter string
 *
 * @param			{String} paramString The encoded parameter string
 * @param			{String} key The target key
 * @return		 {Object} Decoded value for given parameter key
 */

const decodeParamForKey = (paramString, key) => {
	return decodeURIComponent(
		paramString.replace(
		new RegExp(
			'^(?:.*[&\\?]' +
			// eslint-disable-next-line no-useless-escape
			encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
			'(?:\\=([^&]*))?)?.*$', 'i'
		),
		'$1'
		)
	);
	};

const getIsMobile = () => {
	let isMobile = false;

	try {
		isMobile = !!((window.navigator && window.navigator.standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
	} catch (ex) {
		// continue regardless of error
	}

	return isMobile;
};

// const DEBUG = true;

class FacebookLogin extends React.Component {

	static propTypes = {
		isDisabled: PropTypes.bool,
		callback: PropTypes.func.isRequired,
		appId: PropTypes.string.isRequired,
		xfbml: PropTypes.bool,
		cookie: PropTypes.bool,
		authType: PropTypes.string,
		scope: PropTypes.string,
		state: PropTypes.string,
		responseType: PropTypes.string,
		returnScopes: PropTypes.bool,
		redirectUri: PropTypes.string,
		autoLoad: PropTypes.bool,
		disableMobileRedirect: PropTypes.bool,
		isMobile: PropTypes.bool,
		fields: PropTypes.string,
		version: PropTypes.string,
		language: PropTypes.string,
		onClick: PropTypes.func,
		onFailure: PropTypes.func,
		render: PropTypes.func.isRequired,
	};

	static defaultProps = {
		redirectUri: //typeof window !== 'undefined' ? window.location.href : '/',
			(process.env.NODE_ENV === 'production' ?
				'https://sassybox.app' :
				window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
			),
		scope: 'public_profile,email,user_friends',
		returnScopes: false,
		xfbml: false,
		cookie: false,
		authType: '',
		fields: 'name',
		version: '3.1',
		language: 'en_US',
		disableMobileRedirect: false,
		isMobile: getIsMobile(),
		onFailure: null,
		state: 'facebookdirect',
		responseType: 'code',
	};

	state = {
		isSdkLoaded: false,
		isProcessing: false,
		isLoading: true,
		hasAuthResponse: false,
		error: null,
	};

	componentDidMount() {
		this._isMounted = true;

		if (window.facebookConnectPlugin) {
			console.log("[FacebookLogin.componentDidMount] has facebookConnectPlugin, calling getLoginStatus");
			window.FB = window.facebookConnectPlugin;
			window.FB.getLoginStatus(this.checkLoginAfterRefresh);
			this.setStateIfMounted({ isSdkLoaded: true });
			return;
		}

		if (document.getElementById('facebook-jssdk')) {
			this.sdkLoaded();
			return;
		}
		
		this.setFbAsyncInit();
		this.loadSdkAsynchronously();
		
		let fbRoot = document.getElementById('fb-root');
		if (!fbRoot) {
			fbRoot = document.createElement('div');
			fbRoot.id = 'fb-root';
			document.body.appendChild(fbRoot);
		}
	}

	componentWillReceiveProps(nextProps) {
		// if (this.state.isSdkLoaded && nextProps.autoLoad && ! this.props.autoLoad) {
		//	 window.FB.getLoginStatus(this.checkLoginAfterRefresh);
		// }
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	setStateIfMounted(state) {
		if (this._isMounted) {
			this.setState(state);
		}
	}
	
	setFbAsyncInit() {
	// console.log("<CustomLogin> override setFbAsyncInit")
		const { appId, xfbml, cookie, version, /*autoLoad*/ } = this.props;
		window.fbAsyncInit = () => {
			window.FB.init({
				version: `v${version}`,
				appId,
				xfbml,
				cookie,
			});

			this.setStateIfMounted({ isSdkLoaded: true });
		//	 if (autoLoad || this.isRedirectedFromFb()) {
				window.FB.getLoginStatus(this.checkLoginAfterRefresh);
		//	 }
		};
	}

	isRedirectedFromFb() {
		const params = window.location.search;
		return (
			decodeParamForKey(params, 'code') ||
			decodeParamForKey(params, 'granted_scopes')
		);
	}

	sdkLoaded() {
		this.setState({ isSdkLoaded: true });
	}

	loadSdkAsynchronously() {
		if(window.facebookConnectPlugin)
			return;

		const tid = setTimeout(() => this._sdkLoadingError({ timeout: true }), 10000);
		const { language } = this.props;
		this._sdkScript = ((d, s, id) => {
			const element = d.getElementsByTagName(s)[0];
			const fjs = element;
			let js = element;
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = `https://connect.facebook.net/${language}/sdk.js`;
			js.onerror = this._sdkLoadingError;
			js.onload = () => clearTimeout(tid);
			fjs.parentNode.insertBefore(js, fjs);
			return js;
		})(document, 'script', 'facebook-jssdk');
	}

	_sdkLoadingError = (err) => {
		console.error("[Error Loading FB SDK]", err);
		this._sdkScript.remove();
		this.setState({ error: {
			message: 
				err.timeout ? "Timed out loading Facebook SDK" :
				err.message ? err.message :
				"Unknown Error in Facebook",
			timeout: err.timeout,
		}});
	}

	responseApi = (authResponse) => {
		if(window.facebookConnectPlugin) {
			// https://github.com/jeduan/cordova-plugin-facebook4#the-graph-api
			window.FB.api('/me', [], (me) => {
				Object.assign(me, authResponse);
				this.props.callback(me);
				this.setStateIfMounted({ isLoading: false, hasAuthResponse: true });
			});	
			return;
		}

		window.FB.api('/me', { locale: this.props.language, fields: this.props.fields }, (me) => {
			Object.assign(me, authResponse);
			this.props.callback(me);
			this.setStateIfMounted({ isLoading: false, hasAuthResponse: true });
		});
	};

	checkLoginState = (response) => {
		this.setStateIfMounted({ isProcessing: false });
		if (response.authResponse) {
			console.log("[FacebookLogin.checkLoginState] authResponse=", response.authResponse);	
			this.responseApi(response.authResponse);
		} else {
			console.log("[FacebookLogin.checkLoginState] no authResponse, object=", response);
			this.setStateIfMounted({ isLoading: false });
			if (this.props.onFailure) {
				this.props.onFailure({ status: response.status });
			} else {
				this.props.callback({ status: response.status });
			}
		}
	};

	checkLoginAfterRefresh = (response) => {
		console.log("[FacebookLogin.checkLoginAfterRefresh] response=", response);
		if (response.status === 'connected') {
			this.checkLoginState(response);
		} else {
			console.log("[FacebookLogin.checkLoginAfterRefresh] not connected, setting isLoaded:false");
			this.setStateIfMounted({ isLoading: false });
			if(this.props.autoLoad) {
				// https://github.com/jeduan/cordova-plugin-facebook4#login-1
				if(window.facebookConnectPlugin) {
					window.FB.login(['public_profile'],
						loginResponse => {
							console.log("window.facebookConnectPlugin response:", loginResponse);
							this.checkLoginState(loginResponse)
						},
						error => console.error("Error in facebookConnectPlugin.login:", error)
					);
				} else {
					window.FB.login(loginResponse => this.checkLoginState(loginResponse), true);
				}
			}
		}
	};

	click = (e) => {
		console.log("[FacebookLogin.click] state=", this.state, ", props=", this.props);
		if (!this.state.isSdkLoaded || this.state.isProcessing || this.props.isDisabled) {
			return;
		}
		this.setState({ isProcessing: true });
		const { scope, appId, onClick, returnScopes, responseType, redirectUri, disableMobileRedirect, authType, state } = this.props;

		if (typeof onClick === 'function') {
			onClick(e);
			if (e.defaultPrevented) {
				return;
			}
		}

		const params = {

			client_id: appId,
			redirect_uri: redirectUri,
			state,
			return_scopes: returnScopes,
			scope,
			response_type: responseType,
			auth_type: authType,
		};

		if(window.facebookConnectPlugin) {
			const scopes = scope.split(',');
			console.log("[FacebookLogin.click] facebookConnectPlugin scopes=", scopes);
			window.facebookConnectPlugin.login(scopes, result => {
				console.log("click.facebookConnectPlugin.login.result=", result);
				this.checkLoginState(result);
			}, err => console.error("click.facebookConnectPlugin.login.error=", err));
		} else
		if (this.props.isMobile && !disableMobileRedirect) {
			window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
		} else {
			if (!window.FB) {
				if (this.props.onFailure) {
					this.props.onFailure({ status: 'facebookNotLoaded' });
				}

				return;
			}

			window.FB.login(this.checkLoginState, { scope, return_scopes: returnScopes, auth_type: params.auth_type });
		}
	};

	render() {
		const { render } = this.props;

		if (!render) {
			throw new Error('ReactFacebookLogin requires a render prop to render');
		}

		const propsForRender = {
			onClick: this.click,
			isDisabled: !!this.props.isDisabled,
			isProcessing: this.state.isProcessing,
			isSdkLoaded: this.state.isSdkLoaded,
			isLoading: !this.state.error && this.state.isLoading,
			hasAuthResponse: this.state.hasAuthResponse,
			hasError: !!this.state.error,
			error: this.state.error,
		};

		return this.props.render(propsForRender);
	}
}

export default FacebookLogin;