// import windowGlobal from '/windowGlobal';

const windowGlobal = typeof window !== 'undefined' && window;

/**
 * Load the Stripe API and return a Promise that resolves once the API has been loaded (no value resolved, Stripe available globally as documented by Stripe.)
 * Takes care to only inject once on a given page (window object), even if called multiple times. If Stripe already injected, Promise resolves immediately.
 * 
 * @export
 * @param {string} [stripeUrl='https://js.stripe.com/v3/'] URL of the Stripe SDK to load, defaults to Stripe v3
 * @returns Promise that resolves once SDK loads successfully from Stripe
 */
export function injectStripe(stripeUrl = 'https://js.stripe.com/v3/') {
	if(!windowGlobal || windowGlobal.__stripeInjected)
		return Promise.resolve();

	return new Promise(resolve => {
		const document   = windowGlobal.document,
			stripeScript = document.createElement('script');

		stripeScript.onload = () => {
			windowGlobal.__stripeInjected = true;
			resolve();
		};
		
		stripeScript.src = stripeUrl;
		stripeScript.async = false;

		document.head.appendChild(stripeScript)
	});
}