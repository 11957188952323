// Import this first so it can start anything it wants and is guaranteed first priority
import { SassyBoxCoreService } from 'utils/SassyBoxCoreService';

import React, { Component } from 'react';

// import { GameFactory } from '../../game/Game';

import HammerTouchEmulator from 'hammer-touchemulator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as spinnerIcon } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content';
import 'sweetalert2/src/sweetalert2.scss'

// Load Dimbo font into browser
import './styles/FontDimboRegular.css';

// Load layout styles for view
import './styles/layout.scss';

import { setStatusBarColor } from '../../utils/MobileStatusBarColor';

// We have to use a dedicated component for the OverlayView because this component
// by design will not re-render on prop changes
import OverlayView from './OverlayView';
import { randomAffirmation } from '../../utils/randomAffirmation';

// import { AdMobUtil } from '../../utils/AdMob';
import  'utils/AdMob';
// import { ServerStore } from '../../utils/ServerStore';

const DISCLAIMER_KEY = 'kitty-justUpdated';

window.AlertManager = withReactContent(Swal);
// window.alert = text => window.AlertManager.fire(text);

export default class KittyGame extends Component {	
	constructor(props) {
		super(props);

		// Clear the timeout set in index.html since we have entered the app safely
		clearTimeout(window.autoupgradeDeadmanTimeout);
		
		if(!window._hammerTouchEmulatorAdded) {
			window._hammerTouchEmulatorAdded = true;
			HammerTouchEmulator();
		}

		/// Top color in loading gradient
		setStatusBarColor("#db4983")

		this.wasUpdated = process.env.NODE_ENV !== 'production' || window.localStorage.getItem(DISCLAIMER_KEY);
		if(this.wasUpdated) {
			window.localStorage.removeItem(DISCLAIMER_KEY);
			this.affirmation = randomAffirmation(); // rendered in update message
		}

		// Attempt to combat IOS not honoring config
		if (window.StatusBar)
			window.StatusBar.hide();
	}

	// https://codepen.io/chles/pen/aNxMxQ

	setupGame(element) {
		this.element = element;
		
		this.startFontCheck();
	}

	// Wait for Dimbo-Regular to load so PIXI has the font available
	startFontCheck() {
		if(!document.fonts || !document.fonts.check)
			return this.bootGame();

		const startedAt = Date.now(),
			maxWaitTime = 2000,
			timeoutTime = 100;
			
		const check = () => {
			if(!document.fonts.check('1em Dimbo-Regular')) {
				if(Date.now() - startedAt < maxWaitTime) {
					setTimeout(check, timeoutTime);
				} else {
					this.bootGame();
				}
			} else {
				this.bootGame();
			}
		};

		check();
	}

	async bootGame() {
		// This removes our Loading span
		if (this.element)
			this.element.removeChild(this.element.querySelector('#dimbo-font-load-trigger'));

		// Try to make use of code splitting, still not confident this works, I can't seem to find a separate chunk after build
		const { GameFactory } = await import(/* webpackChunkName: "game-core" */ '../../game/Game');

		// Boot our core service once UI has a chance to render
		// Blocks until deffered promise resolved in App.js by unlockBoot();
		await SassyBoxCoreService.boot();
	
		// Create the game object
		this.game = GameFactory.setupGame(this.element.querySelector('#game-container'));

		this.GameFactory = GameFactory; // store for shutdown later
	}

	componentWillUnmount() {
		// this.game.off('setReactOverlay', this._overlayHandler);

		this.GameFactory.shutdownGame();
	}

	shouldComponentUpdate() {
		// This component does not react to prop changes - all state managed internally in the PIXI app
		return false;
	}

	render() {
		return <div id="game-wrap"
				ref={ref => this.setupGame(ref)}>

			<div id="game-container">
				{/* setupGame() will add main PIXI stage here */}
			</div>

			<span id="dimbo-font-load-trigger">
				{/* Have to have this here to trigger the Dimbo font to actually load */}
				Loading...
			</span>

			<div id="react-view-overlay">
				<OverlayView/>
			</div> 

			<div id="basic-loading-indicator">
				<FontAwesomeIcon icon={spinnerIcon} spin size="3x"/>
				
				{this.wasUpdated && <div className="updated-text">
					<p>We just updated, so this may take a minute to download the changes.</p>
					{/* <p>Your kitty is drinking coffee and thinking, "{this.affirmation}"</p> */}
				</div>}
			</div>

			
		</div>;
	}
}
