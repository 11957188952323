import MobileDetect from 'mobile-detect';
import { isPhoneGap } from './isPhoneGap';

const md = new MobileDetect(window.navigator.userAgent);

// Thanks to https://stackoverflow.com/a/41749865/1119559
// Detects if added to homescreen as a PWA
const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

export const mobileDetect = {
	md,
	hasNotch:
		// If not standalone and not PhoneGap'd, then
		// there likely is browser chrome above the app, so technically *we* don't "have notch",
		// even though the device may have a notch.
		(isStandalone || isPhoneGap) && 
		(md.is('iPhone') && md.version('iPhone') >= 10.0),
};