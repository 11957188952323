import React from 'react';

import './LevelEndOverlay.scss';
import { randomAffirmation } from 'utils/randomAffirmation';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ServerStore } from '../../../utils/ServerStore';
// import { numberWithCommas } from '../../../utils/numberWithCommas';
import { StatsWidget } from './StatsWidget';
import { IconCircleButton } from './IconCircleButton';

export default class LevelEndOverlay extends React.Component {
	handle = null;

	constructor(props) {
		super(props);

		/// Top color in loading gradient
		setStatusBarColor("#db4983")

		this.state = {
			progressData: {  isValid: false },
			visible: false,
			rankingChanges: {
				isPending: true
			},
			result: {},
			affirmation: randomAffirmation(),
		}

		if (this.props.handle)
			this.props.handle(this);

		LevelEndOverlay.handle = this; 
		
		setTimeout(() => {
			this.setState({ visible: true });
			this.resultReceived = Date.now();
		}, 33);
	}

	// setResult(result) {
	// 	this.setState({ result });

	// 	this.resultReceived = Date.now();
	// }


	requestClose = () => {
		if(!this.state.visible)
			return Promise.resolve();

		return new Promise(resolve => {
			// For use if they click closeOverlay
			this.requestClosePromiseResolver = resolve;
			
			const avgReaderWpm      = 500, // avg person reads approx 200 words per minute
				approxWordsOnScreen = this.contentEl ? this.contentEl.innerText.split(/\s+/).length : 20,
				minutesToReadScreen = approxWordsOnScreen / avgReaderWpm,
				millisecondsToRead  = minutesToReadScreen * 60 * 1000,
				desiredTime         = Math.max(2000, millisecondsToRead),
				elapsedTime         = Date.now() - (this.resultReceived || 0),
				timeRemaining       = Math.min(desiredTime, Math.max(0, desiredTime - elapsedTime));

			// console.log("[LevelEndOverlay.requestClose] reading time calc info:", { desiredTime, elapsedTime, timeRemaining, millisecondsToRead, minutesToReadScreen, approxWordsOnScreen });

			if (this.timerButton)
				this.timerButton.setTimer(timeRemaining);

			setTimeout(async () => {
				await this.closeOverlay();
				// resolve();
			}, timeRemaining);
		});
	}

	closeOverlay = () =>   {
  		if(!this.state.visible)
			return Promise.resolve();

		LevelEndOverlay.handle = null;
			
		return new Promise(resolve => {
			this.setState({ visible: false });

			// Resolve after CSS anim finishes (.5s)
			setTimeout(() => {
				if (this.requestClosePromiseResolver)
					this.requestClosePromiseResolver();

				resolve()
			}, 501);
		});
	}

	componentWillReceiveProps({ game }) {
		if (game.currentScene) {
			// console.warn("[LevelEndOverlay] set shootIntercept in componentWillReceiveProps");
			game.currentScene.shootIntercept = this.closeOverlay;
		}
	} 

	componentDidMount() {
		const scene = this.props.game.currentScene;

		if (scene) {
			
			// console.warn("[LevelEndOverlay] set shootIntercept in componentDidMount");
			scene.shootIntercept = this.closeOverlay;
			
			// const { level, worldState } = scene || {};
			// if(level && worldState) try {
			// 	const completed = parseFloat((worldState.progressSummary || {}).completed),
			// 		numLevels   = parseFloat((level.world                || {}).numLevels),
			// 		progress    = Math.ceil( (completed + 1) / numLevels * 100),
			// 		remaining   = numLevels - completed - 1;

			// 	const progressData = {
			// 		isValid: true,
			// 		progress,
			// 		remaining,
			// 		numLevels,
			// 		completed
			// 	};

			// 	this.setState({ progressData });
			// } catch(e) {
			// 	console.warn("Error creating progressData:", e, level, worldState);
			// }
		}
	}

	setRankingChanges(rankingChanges) {
		
		const changes = rankingChanges;

		if(!changes.newBelowCurrent) {
			changes.hasChanges =  false;
			// Not handling anything else right now
			console.log("[setRankingChanges] no newBelowCurrent, no one else to notify");
		} else {

			const ref = changes.newBelowCurrent;

			const [ name ] = ref.otherPerson.split(/\s+/);
			
			// Compose alert
			const title = `You just beat ${name}'s score!`;
			const body = changes.currentIdx === 0 ? `You're leading the scoreboard!` : 
				`You're now at spot # ${changes.currentIdx + 1} on the scoreboard - nice!`;

			changes.changes = <>
				<h3>{title}</h3>
				<p>{body}</p>
			</>;
		}

		this.setState({ rankingChanges });
	}

	render() {
		// const progressData = this.state.progressData || {};
		const streakInfo = this.props.streakInfo || {};
		const rankingChanges = this.state.rankingChanges || {};
		
		if(!streakInfo.name) {
			streakInfo.name = streakInfo.id.replace('_', ' ');
		}
		

		return <div id="level-end-overlay" className={this.state.visible ? "visible" : ""}>
			<div className="content" onClick={this.closeOverlay}>
				<div className="content-padding" ref={el => this.contentEl = el}>

					{!rankingChanges.isPending && rankingChanges.hasChanges ?
						rankingChanges.changes : <></>}

					{/* <h2>Active Streaks</h2> */}
					<p>{this.state.affirmation}</p>

					<StatsWidget cat={ServerStore.currentCat}/>
					
					{rankingChanges.isPending ? <p><FontAwesomeIcon icon={faSpinner} spin/></p> : ''}

					{/* {rankingChanges.isPending ? 
						<p><FontAwesomeIcon icon={faSpinner} spin/></p> :
					rankingChanges.hasChanges ?
						rankingChanges.changes :
					streakInfo.id ? <>
						{streakInfo.newRecord ? <p className="streak-record">Great job! New record of {streakInfo.current} {streakInfo.name}!</p> :
						 streakInfo.current   ? <p className="streak-current">
						 	You're at {streakInfo.current} {streakInfo.name} (Your record is {streakInfo.record})
						</p> : ''}
					</>:''}	
					
					<p>{this.state.affirmation}</p>
					
					{progressData.isValid &&
						<p>You have {numberWithCommas(ServerStore.currentCat.points)} points, and 
							you're {progressData.progress}% done with pack of levels,
							{" "}only {progressData.remaining} left!</p>
					} */}

					<div className="level-end-button">
						<IconCircleButton
							handle={timerButton => (this.timerButton = timerButton) && timerButton.setTimer(75000)}
							onTimerExpired={() => this.closeOverlay()}
							onClick={() => this.closeOverlay()} 
						/>
					</div>

				</div>
			</div>
	</div>}
}