import * as PIXI from 'pixi.js-legacy';
import AssetLoader from '../../utils/AssetLoader';

export default class Scene {
	
	/**
	 * @property {Game} game - Pointer to the game for this scene
	 *
	 * @memberof Scene
	 */
	game = null;

	/**
	 * @property {Array} Array of objects and containers that were added using `addObject()` so we can destroy them automatically
	 * @private
	 * @memberof Scene
	 */
	__objectsAdded = [];

	/**
	 * @private
	 * @memberof Scene
	 */
	__resourcesLoaded = {};

	/**
	 * Creates an instance of Scene.
	 * @param {Game} game
	 * @throws Error if no game given
	 * @memberof Scene
	 */
	constructor(game) {
		if(!game)
			throw new Error("`game` required")
		this.game = game;
	}

	/**
	 * Initalizes the scene - override in a subclass to do anything useful
	 *
	 * @memberof Scene
	 */
	init() {
		throw new Error("Not implemented in this Scene");
	}

	/**
	 * Called by the game before switching scenes to teardown any objects added to the game.
	 * By default, calls `destroyObjects()` to remove any objects added via `addObject()`
	 * You can override to reimplement, for example, to stop any timers started in `init`
	 *
	 * @memberof Scene
	 */
	destroy() {
		this.destroyObjects();
	}

	/**
	 * Adds the given `displayObject` (anything that PIXI.Container.addChild acccepts) 
	 * to the given `container`. If `container` not given, adds to `game.viewport`
	 *
	 * @param {PIXI.DisplayObject} displayObject
	 * @param {PIXI.Container} [container=null]
	 * @memberof Scene
	 */
	addObject(displayObject, container=null) {
		if(!container)
			container = this.game.viewport;
		this.__objectsAdded.push({ displayObject, container });
		container.addChild(displayObject);
		return displayObject;
	}

	/**
	 * Removes any objects added with `addObject()` from the given container (or viewport)
	 * Also takes care to call `destory(true)` on all objects (ref http://pixijs.download/release/docs/PIXI.Container.html#destroy)
	 * Note that the act of calling `destroy(true)` will delete the underlying textures, which will invalidate resources stored in `PIXI.loader.resources`.
	 * However, PIXI itself doesn't appear to properly remove the resource from the loader, nor does subsequent `add()` calls on the loader detect the 
	 * destroyed texture. So, in this method, we also manually remove any resources loaded using `loadResources()` from the `PIXI.loader.resources` hash,
	 * so that future calls to `PIXI.loader.add()` for the same resources will correctly re-create the texture.
	 * @memberof Scene
	 */
	destroyObjects() {
		this.__objectsAdded.forEach(ref => {
			// remove from PIXI
			ref.container.removeChild(ref.displayObject);

			// Destroy pixi displayObject - required!!!!
			// http://pixijs.download/release/docs/PIXI.Container.html#destroy
			let destroyed = false;
			try {
				ref.displayObject.destroy(true);
				destroyed = true;
			} catch(err) {
				// If no texture, we are guaranteed to get an error,
				// but I'm not sure why _texture would be missing - I just know I get errors sometimes.
				if(ref.displayObject._texture)
					console.warn("Trouble destroying ref with `true`:", err);
			}

			if(!destroyed) {
				try {
					ref.displayObject.destroy({children: true});
					destroyed = true;
				} catch(err) {
					// If no texture, we are guaranteed to get an error,
					// but I'm not sure why _texture would be missing - I just know I get errors sometimes.
					if(ref.displayObject._texture)
						console.warn("Trouble destroying ref with `{children:true}`:", err);
				}
			}
		});

		Scene.removeResourcesFromPixi(this.__resourcesLoaded);

		// Reset lists so we can cleanly re-init
		this.__objectsAdded = [];
		this.__resourcesLoaded = {};

		// from http://www.html5gamedevs.com/topic/11781-is-there-a-cache-to-clear-that-im-missing/
		for (const textureUrl in PIXI.utils.BaseTextureCache) { /* eslint-disable-line no-unused-vars */
			delete PIXI.utils.BaseTextureCache[textureUrl];
		}
		for (const textureUrl in PIXI.utils.TextureCache) { /* eslint-disable-line no-unused-vars */
			delete PIXI.utils.TextureCache[textureUrl];
		}

		// Finally, reset the loader
		AssetLoader.reset();
	}

	/**
	 * Remove the given resource keys from PIXI.loader. Used internally by `destroyObjects()`, and exposed as a static method for re-use by `KittyActor`
	 *
	 * @static
	 * @param {object} hash - only keys are important
	 * @memberof Scene
	 */
	static removeResourcesFromPixi(hash) {
		// Remove from PIXI.loader.resources so that when we re-init, 
		// the loader will re-create the textures destroyed by destroy(true) above
		// const loader = PIXI.loader;
		Object.keys(hash || {}).forEach(key => {
			const res = AssetLoader.resources[key];
			if (res && res.destroy)
				res.destroy();

			// delete loader.resources[key];
		})
	}

	/**
	 * Loads the given resources using `PIXI.loader` and returns a `Promise` that resolves when the loader returns the resources.
	 * Note that this also stores the list of resources for destruction by `destroyObjects` when the scene is destroyed.
	 *
	 * @param {object} resources - key/value hash. Each key/value used to call `PIXI.loader.add(key, value)`
	 * @returns {Promise} Resolves with a reference to `PIXI.loader.resources`
	 * @memberof Scene
	 */
	loadResources(resources) {
		// Store for patching PIXI.loader when destroying
		this.__resourcesLoaded = resources;

		// Takes care not to add twice, returns promise of load, and
		// normalizes URLs on PhoneGap if not included in binary
		return AssetLoader.addAll(resources);
	}
}