import * as PIXI from 'pixi.js-legacy';
import React from 'react';

import Scene from "../Scene";

import SassyBox from './SassyBox';

import SoundManager from 'utils/SoundManager';
import { ServerStore, APP_PAUSED_EVENT, APP_RESUMED_EVENT } from 'utils/ServerStore';
import { LoaderUtil } from 'utils/LoaderUtil';
import { DefaultTextStyles as TextStyles } from 'utils/TextStyles';

import { setStatusBarColor } from 'utils/MobileStatusBarColor';

import { gtag } from 'utils/ServerStore';
import { isPhoneGap } from 'utils/isPhoneGap';

import LevelProps from './LevelProps';

import WelcomePopup from './WelcomePopup/WelcomePopup';
import ToastOverlayPopup from './ToastOverlayPopup/ToastOverlayPopup';
import AppStoreLinkWidget from './AppStoreLinkWidget/AppStoreLinkWidget';

import AssetLoader from 'utils/AssetLoader';

import { numberWithCommas } from 'utils/numberWithCommas';
import { mobileDetect } from 'utils/mobile-detect';
import { BasicMeter } from 'utils/BasicMeter';

import bg_img_burst_blue from 'assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';
import tile_orange    from 'assets/physicsobjects/pngs/tiles/orange.png';


// import tile_science   from 'assets/physicsobjects/pngs/tiles/science.png';

const welcomeDoneCacheKey = 'sassybox-welcomeDone';

const ENABLE_MUSIC = false;

const levelForScore = score => Math.floor(score / 10) + 1;

let TutorialModeflag = false;

export default class SassyBoxScene extends Scene {

	async init() {
		// Enable welcome mode
		const welcomeDone = window.localStorage.getItem(welcomeDoneCacheKey) === 'true';
		this.setWelcomeMode(welcomeDone);
	
		await this.downloadScoreData(welcomeDone);
		
		// Extract score for loading LevelProps resources based on level
		const { score } = this.scoreData || {},
			level = levelForScore(score);

		// console.log({ scoreData: this.scoreData });
		
		const resources = await this.loadResources({
			...SassyBox.requiredResources(),
			...LevelProps.requiredResources(level),
			// ...BasicMeter.requiredResources(['red']),

			// Guarantee these are loaded for fallbacks
			bg_img_burst_blue,
			tile_orange
		});

		this.resources = resources;
		
		// Hide index.html loader anim once ready to render UI
		LoaderUtil.loaded();

		await this.setupBackground(resources, level);
		await this.setupSassyBoxScene(resources, level);
	
		// Move out of main thread
		setTimeout(async () => {
			// await this.addMeters();
			await this.setupIndicators();
			await this.setupSounds();
			await this.listenForPauseResume();
		
			// For debugging
			window.SassyBoxScene = this;
			
			// console.log(`✔️ Loaded!`);

			// Play starting sound
			SoundManager.use(SoundManager.START1).setVolumeModifier(0.2).play();

			// Start music playing
			if(ENABLE_MUSIC) {
				this.musicTrack.play();
			}

			setTimeout(async () => {
				// console.log("Loading remaining level resources...");
				await AssetLoader.addAll(LevelProps.remainingRequiredResources());
				// console.log("Done");
			}, 1000);

		}, 100);
	}

	async downloadScoreData(welcomeDone=true) {
		this.scoreData = welcomeDone ? 
			await ServerStore.GetScore() : 
			{ score: 0 };
	}

	async setupBackground(resources, level) {
		if(!level) {
			throw new Error("no level");
		}
		const { bg: { bg } } = LevelProps.propsForLevel(level)
		const { game } = this;
		// Add background texture first
		const bgSprite = new PIXI.Sprite(resources[bg].texture);
		if(true) {
			bgSprite.anchor.x = 0.5;
			bgSprite.anchor.y = 0.5;

			bgSprite.x = window.innerWidth / 2;
			bgSprite.y = window.innerHeight / 2;

			this.addObject(bgSprite, game.gameContainer);

			game.app.ticker.add(this._tick = (time) => {
				if (bgSprite.transform) {
					if (this.rotateBg) {
						bgSprite.rotation += 0.001;
					} else {
						bgSprite.scale.x += 0.00025;
						bgSprite.scale.y += 0.00025;

						bgSprite.rotation = 0;
					}
				}
			});

			bgSprite._layout = () => {
				bgSprite.x = window.innerWidth / 2;
				bgSprite.y = window.innerHeight / 2;

				const scale = 
					Math.max(window.innerWidth, window.innerHeight) 
					/ Math.min(bgSprite.texture.orig.width, bgSprite.texture.orig.height)
					* 2; // ensure coverage of corners as we rotate

				bgSprite.scale = new PIXI.Point(scale, scale);
			};
			bgSprite._layout();
		}

		this.bgSprite = bgSprite;
		

		// Re-layout these things when window size changes
		const itemsNeedLayoutOnResize = [
			bgSprite,
		];

		window.addEventListener('resize', this._resizeHandler = () => {
			itemsNeedLayoutOnResize.forEach(item => item._layout());
		});
	}

	setBackgroundTexture(key) {
		let res = this.resources[key];
		if(!res) {
			// Use bg_img_burst_blue as fallback instead of throwing error
			res = this.resources.bg_img_burst_blue;

			//throw new Error("Invalid resource key " + key);
		}

		this.bgSprite.texture = res.texture;
	}

	async setupSassyBoxScene(resources, level) {
		this.box = new SassyBox(this.game, resources);
		await this.box.init(level);
		if(!this.welcomeDone)
			this.box.container().alpha = 0;
		this.addObject(this.box.container(), this.game.gameContainer);
	}

	setupIndicators() {
		const scoreText = new PIXI.Text(
			'',
			{
				...TextStyles,
				fontSize: 32, 
				align: "center", 
				wordWrap: true, 
				wordWrapWidth: window.innerWidth * 0.85
			}
		);
		scoreText.anchor.x = 0.5;
		scoreText.anchor.y = 0.5;
		scoreText.x = window.innerWidth / 2;
		scoreText.y = 32 * 2; //window.innerHeight / 2;
		this.addObject(scoreText, this.game.gameContainer);
		this.scoreText = scoreText;

		const globalText = new PIXI.Text(
			'',
			{
				...TextStyles,
				fontSize: 32, 
				align: "center", 
				wordWrap: true, 
				wordWrapWidth: window.innerWidth * 0.85
			}
		);
		globalText.anchor.x = 0.5;
		globalText.anchor.y = 0.5;
		globalText.x = window.innerWidth / 2;
		globalText.y = window.innerHeight - 32 * 1.5;
		this.addObject(globalText, this.game.gameContainer);
		this.globalText = globalText;

		let score = this.scoreData.score || parseFloat(window.localStorage.getItem('score') || 0) || 0;
		let level = 0;

		const calcLevel = () => {
			level = levelForScore(score);
			this.box.currentLevel = level;
			this.box.updateBoxTexture(level);
		}

		this.box.currentScore = score;
		calcLevel();


		const updateUiFromScore = this.updateUiFromScore = () => {
			// console.log(`New score:${score}`);
			// scoreText.text = `Score: ${score}\nLevel ${level}`;
			const leverClicks = 
				this.scoreData &&
				this.scoreData.leverInfo && 
				this.scoreData.leverInfo.leverClicks;

			const globalLeverClicks = 
				this.scoreData &&
				this.scoreData.leverInfo &&
				this.scoreData.leverInfo.globalLeverClicks;

			scoreText.text = `Level ${numberWithCommas(level || 1)}\nScore: ${numberWithCommas(score || 0)} | Levers: ${numberWithCommas(leverClicks || 0)}`;

			const highScoreName = 
				this.scoreData &&
				this.scoreData.userForHighScore &&
				this.scoreData.userForHighScore.name;

			const highScore = 
				(this.scoreData && 
				 this.scoreData.highScore) || 0;

			globalText.text = `High Score: ${numberWithCommas(highScore || 0)}`
				+ (this.scoreData.haveHighScore ? ' (You!)': ' (' + (highScoreName || '?') + ')') + '\n' 
				+ `All Levers: ${numberWithCommas(globalLeverClicks || 0)}`;

			// // const bg = [ 'bg_img', 'bg_img2', 'bg_img4', 'bg_img3', 'bg_img5' ],
			// const bg = Object.keys({

			// 	// bg_img_ice,

			// 	bg_img_bubble_blue,
			// 	bg_img_burst_blue,
			// 	bg_img_wave_blue,
				
			// 	// bg_img_bubble_green,
			// 	// bg_img_burst_green,
			// 	// bg_img_wave_green,

			// 	bg_img_bubble_orange,
			// 	bg_img_burst_orange,
			// 	bg_img_wave_blue2,

			// 	bg_img_bubble_purple,
			// 	bg_img_burst_purple,
			// 	bg_img_wave_pink,

			// 	bg_img_bubble_red,
			// 	bg_img_burst_red,
			// 	bg_img_wave_red,
			// }),
			// 	idx = (level - 1) % bg.length,
			// 	res = bg[idx];

			// console.log(`bg: ${res}`, { res, idx, bg, level })

			const props = LevelProps.propsForLevel(level),
				bgProps = props.bg;

			this.rotateBg = !(bgProps.rotate === false);

			if(props.statusBarColor)
				setStatusBarColor(props.statusBarColor);

			// this.bgSprite.texture = res.texture;
			this.setBackgroundTexture(bgProps.bg);
			
		}

		// Hide if not enabled
		if(!this.welcomeDone) {
			globalText.alpha = 0;
			scoreText.alpha = 0;
		}


		updateUiFromScore();

		this.box.on(SassyBox.POINT_EVENT, async () => {
			score ++;
			SoundManager.play(SoundManager.POSITIVE);
			// level = Math.ceil(score / 10);

			if (TutorialModeflag) {
				gtag('event', 'tutorial_complete', { });
				TutorialModeflag = false;

				window.localStorage.setItem('TutorialModeDone', 'true');

				showWelcome('Good job! Now get 9 more to get to the next level!');
			}

			if(score % 10 === 0) {
				calcLevel();
				if(process.env.NODE_ENV === 'production') {
					gtag('event', 'level_up', {
						event_label: 'Level Up',
					});

					window.fbq('track', 'ViewContent', {
						content_ids: 'LevelUp',
						content_type: 'GamePlay'
					});
				}

				SoundManager.play(SoundManager.ACHIEVEMENT2);

				ServerStore.metric("game.level_up", level, { num: level });
			}

			gtag('event', 'post_score', {
				event_label: 'Post Score',
				level,
				score
			});

			this.box.currentScore = score;

			window.localStorage.setItem('score', score);

			updateUiFromScore();

			await ServerStore.PostScore(score);
		
		});

		this.box.on(SassyBox.POINT_FAIL_EVENT, async () => {
			score --;
			SoundManager.play(SoundManager.CLINK); // TODO better negative sound?

			if(score % 10 === 0) {
				calcLevel();
				if(process.env.NODE_ENV === 'production') {
					gtag('event', 'level_down', {
						event_label: 'Level Down',
					});
				}

				// SoundManager.play(SoundManager.ACHIEVEMENT2);

				ServerStore.metric("game.level_down", level, { num: level });
			}

			this.box.currentScore = score;

			window.localStorage.setItem('score', score);

			updateUiFromScore();

			await ServerStore.PostScore(score);
		
		});

		ServerStore.on('globalHighScore', ({ highScore, userForHighScore }) => {
			this.scoreData.highScore = highScore;
			this.scoreData.haveHighScore = 
				ServerStore.currentUser && 
				ServerStore.currentUser.id === (userForHighScore || {}).id;
				
			if(this.scoreData.haveHighScore && !ServerStore.currentUser.name) {
				const name = prompt("Hey! You have the high score! What's your name?");
				if(name) {
					ServerStore.UpdateSettings({ name });
					ServerStore.currentUser.name = name;
				}
			}
			this.scoreData.userForHighScore = userForHighScore;
			updateUiFromScore();
		});

		ServerStore.on('leverClicks', ({ leverClicks }) => {
			try {
				this.scoreData.leverInfo.leverClicks = leverClicks;
				updateUiFromScore();
			} catch(e) {}
		});

		ServerStore.on('globalLeverClicks', ({ globalLeverClicks }) => {
			try {
				this.scoreData.leverInfo.globalLeverClicks = globalLeverClicks;
				updateUiFromScore();
			} catch(e) {}
		});
	}

	listenForPauseResume() {
		// These events emitted by normalized handlers in Game.js
		ServerStore.on(APP_PAUSED_EVENT, this._appPausedHandler = async () => {

			// Pause matter and various timers
			// this.pauseGameplay();

			// Dump accumulators to metrics because the app could be discard in the background
			// this.actor.dumpPendingMetrics();
			
			//
			// Update: The metric logging and await is not needed here since we now handle this in Game.js
			//
			// // Notify metric server
			// ServerStore.metric("cordova.paused");

			// // Dump any pending metrics to server before letting game go to background
			// await ServerStore.postMetrics();
			
			// Stop the music *last* because the act of having active sound keeps the app running
			// in the background, so this will give the app time to persist to the server
			if(ENABLE_MUSIC) {
				this.musicTrack.stop();
			}
		});

		ServerStore.on(APP_RESUMED_EVENT, this._appResumedHandler = () => {
			//
			// Update: The metric logging is not needed here since we now handle this in Game.js
			//
			// Notify metric server
			// ServerStore.metric("cordova.resumed");

			if(ENABLE_MUSIC) {
				this.musicTrack.play();
			}
			
			// // Resume timers
			// this.resumeGameplay();
		});
	}

	setupSounds() {
		// Don't *have* to call use if we are just going to call play right away
		// .use is just a preload
		if(ENABLE_MUSIC) {
			this.musicTrack = SoundManager
				.use(SoundManager.MUSIC_FOREST)
				.setVolumeModifier(0.75);
		}

		SoundManager.use(SoundManager.ACHIEVEMENT2).setVolumeModifier(0.15);
		SoundManager.use(SoundManager.POSITIVE).setVolumeModifier(0.15);
	}

	setWelcomeMode(flag) {
		this.welcomeDone = flag;

		if(!flag) {
			// from trackingPixels, imported in ServerStore
			if (window.fbq) {
				window.fbq('track', 'ViewContent', {
					content_ids: 'WelcomePopup',
				});
				console.log(" - Added 'ViewContent' event to FB Pixel")
			}

			this.game.setReactOverlay(
				<WelcomePopup handle={handle => this.welcomePopupLoaded(handle)}/>
			);
		} else {
			// ToastOverlayPopup is used via ToastOverlayPopup.show(<>Whatever</>, { ...options });
			this.game.setReactOverlay(<ToastOverlayPopup/>);

			// const isPhoneGap = true;
			if(!isPhoneGap) {
				setTimeout(() => {
					ToastOverlayPopup.show(<>
						<h1>Welcome!</h1>
						<div style={{
							marginBottom: '1rem',
						}}>
							<AppStoreLinkWidget/>
						</div>
					</>, {
						timer: 10000
					});
				}, 100);
			} else {

				TutorialModeflag = window.localStorage.getItem('TutorialModeDone') !== 'true';
				if(TutorialModeflag) {
					gtag('event', 'tutorial_begin', { });
					showWelcome("Turn the lever on ... can you turn it off before you get hit? ")
				} else {
					showWelcome(<>👋 Welcome Back! 🎉</>, 3000);
				}
			}

			// if (window.fbq) {
			// 	window.fbq('track', 'ViewContent', {
			// 		content_ids: 'SassyBoxGame',
			// 		conent_type: 'GamePlay'
			// 	});
			// 	console.log(" - Added 'ViewContent' event to FB Pixel")
			// }
		}

		if (this.globalText)
			this.globalText.alpha = flag ? 1:0;

		if (this.scoreText)
			this.scoreText.alpha = flag ? 1:0;

		if (this.box)
			this.box.container().alpha = flag ? 1:0;
	}


	welcomePopupLoaded(handle) {
		handle.onComplete(async name => {
			window.localStorage.setItem(welcomeDoneCacheKey, 'true');
			this.setWelcomeMode(true);
			gtag('event', 'signup', { });

			// from trackingPixels, imported in ServerStore
			if (window.fbq) {
				window.fbq('track', 'CompleteRegistration');
				console.log(" + Added 'CompleteRegistration' event to FB Pixel")
			}

			await ServerStore.WelcomeDone(name);

			// Re-login to update Sentry, etc
			ServerStore.anonymousLogin(name);

			// Download high-score info
			await this.downloadScoreData();
			
			// Update UI
		this.updateUiFromScore();
		});
	}

	destroy() {
		window.removeEventListener("resize", this._resizeHandler);
		this.game.app.ticker.remove(this._tick);
		super.destroy();
	}

	async addMeters() {
		const { game: { gameContainer }, resources } = this;
		
		// let highScore = window.localStorage.getItem('kitty-highscore') || 0;
		// const remainingStars = (this.objectsToClearLevel || []).length;
		
		let startLayoutY = 12, layoutY = startLayoutY, lastMeter = null;
		const paddingX = startLayoutY, paddingY = paddingX / 2;

		// Adjust for notch
		if(mobileDetect.hasNotch) {
			layoutY = startLayoutY = 32 + paddingY;
		}

		this.meters = {};
		this.meters.health = this.addObject(lastMeter = new BasicMeter({ resources,
			color: "red",
			label: "Energy",
			icon: "health",
			value: 100, //this.actor.currentHealth,
			maxValue: 100, //MAX_HEALTH,
			position: {
				x: paddingX,
				y: layoutY,
			}
		}), gameContainer);

		this.box.rewardMeter = lastMeter;
		
		layoutY += paddingY + lastMeter.height;
	}
}

function showWelcome(text, timer=10000) {
	setTimeout(() => {
		ToastOverlayPopup.show(<>
			<div style={{
				borderRadius: '1rem',
				background: 'rgba(0,0,0,0.67)',
				fontSize:   '1.5rem',
				padding:    '1.25rem 1rem 10rem',
				fontWeight: 500,
			}}>
				{text}
			</div>
		</>, {
			timer
		});
	}, 100);
}