import EasingFunctions from './easing';

import { defer } from 'utils/defer';

//https://github.com/mattdesl/lerp/blob/master/index.js
function lerp(v0, v1, t) {
	return v0*(1-t)+v1*t
}

export const DEFAULT_EASING = EasingFunctions.easeInOutQuad;

function interpolateDataTimeLocations(defn=[]) {
	const [d0, dn] = [ defn[0], defn[defn.length-1] ];
	// Fill in the 'obvious' at values
	if(!d0.at)
		d0.at = 0;
	if(!dn.at)
		dn.at = 1.0;

	let lastAt = 0;
	for(let i=0; i<defn.length; i++) {
		const d = defn[i];
		
		if(d.at === null || d.at === undefined) {
			let nextAt, nextAtIdx = null;
			for(let j=i; j<defn.length; j++) {
				const jd = defn[j];
				if(jd.at && nextAtIdx === null) {
					nextAt = jd.at;
					nextAtIdx = j;
				}
			}

			if(nextAtIdx === null) {
				// We should have at LEAST had .at=1.0 for the last element...
				throw new Error("Why didn't we find an .at value before the end??")
			}

			const stepsBetweenValues = nextAtIdx - i + 1;
			// Example:
			//  0  1  2   3
			// [0, x, x, 0.75, ...] 
			// nextAtIDx = 3
			// i = 1
			// steps... = 3-1+1 = 3
			
			const atDistance = nextAt - lastAt,
				atSteps = atDistance / stepsBetweenValues;

			// Continuing the xample:
			// nextAt = 0.75
			// lastAt = 0
			// atDistance = .75
			// atSteps = .75 / 3 = .25

			// console.log({
			// 	i,
			// 	lastAt,
			// 	nextAt,
			// 	nextAtIdx,
			// 	stepsBetweenValues,
			// 	atDistance,
			// 	atSteps
			// })

			for(let k=i; k<nextAtIdx; k++) {
				defn[k].at = lastAt + atSteps * (k - i + 1);
			}
			
		} else {
			lastAt = d.at;
		}
	}
	
	return defn;
}

// console.log("------------------");
// console.log(interpolateDataTimeLocations([
// 	// { },
// 	// { at: 0.3 },
// 	// { },
// 	// { at: 0.5 },
// 	// { }

// 	{ },
// 	{ },
// 	{ },
// 	{ at: 0.75 },
// 	{ }
// ]).map(d => d.at));
// console.log("------------------");


export default class AnimatorUtility {
	constructor(defn=[], opts={
		stepCallback:  null,
		onUpdate: null,
		easing: DEFAULT_EASING,
	}) {
		this.defn = defn;

		this.defnIdx = {};
		defn.forEach((d, idx) => {
			if(!d.id)
				d.id = '#' + idx;
			this.defnIdx[d.id] = d;
		});

		interpolateDataTimeLocations(defn);

		if(!opts)
			opts = {};

		this.onUpdate = opts.onUpdate;
		this.computeCallback = opts.computeCallback;

		this.easing = opts.easing || DEFAULT_EASING;

		this.currentKeyframeId = null;
		this.currentTime = null;
	}

	update(time=0, animObject) {
		if(animObject)
			this.animObject = animObject;
		
		this._applyTime(time);
	}

	_applyTime(time) {
		if(time > 1) {
			time = 1;
		}
		if(time < 0) {
			time = 0;
		}
		this.currentTime = time;
		
		const values = this._interpolatedDataForTime(time);
		this._apply(values);
	}

	_keyframeForTime(time=0) {

		for(let i=0; i<this.defn.length; i++) {
			const d = this.defn[i];
			
			// Find at
			if(time <= d.at) {
				return d;
			}
		}

		return null;
	}

	_interpolateBetweenKeyframes(kf1, kf2, time) {
		const kf = {};

		Object.keys(kf2).forEach(key => {
			const v1   = kf1[key],
				v2     = kf2[key],
				lerped = lerp(v1, v2, time);
			
			kf[key] = lerped;
		});

		return kf;
	}

	_interpolatedDataForTime(time=0) {
		const kf = this._keyframeForTime(time);
		if(!kf) {
			throw new Error("No keyframe for time index " + time);
		}	

		this.currentKeyframe = kf;

		if(kf.at > 0) {

			const previousKeyframe = this.defn[this.defn.indexOf(kf) - 1];

			// Set starting data to previous keyframe
			this.startingData = previousKeyframe.data;

			// If kf.at === 1.0 and time = 0.8 and previousKeyFrame.at = 0.5 ...
			const relativeTime = time - previousKeyframe.at; // 0.8 - 0.5 = 0.3
			const relativeTimeDist = kf.at - previousKeyframe.at; // 1.0 - 0.5 = 0.5
			const relativeTimePercent = relativeTime / relativeTimeDist; // 0.3 / 0.5 = 0.6

			const interpolatedData = this._interpolateBetweenKeyframes(
				previousKeyframe.data,
				kf.data,
				relativeTimePercent
			);

			return interpolatedData;

		} else {
			return { ...kf.data };
		}


	}

	_apply(data) {
		if(this.animObject) {
			// console.log(`[AnimationUtility:_apply] applying`, data, " at current kf ", this.currentKeyframeId)
			Object.keys(data).forEach(key => {
				this.animObject[key] = data[key];
			})
		}

		if (this.onUpdate) {
			this.onUpdate(data, this);
		}
	}

	// dataAt(time) {
	// 	// Reset starting data to equal first keyframe
	// 	this.startingData = this.defn[0].data;
	// 	return this._interpolatedDataForTime(time);
	// }

	_step() {
		if(!this.startingTime)
			this.startingTime = Date.now();
		const delta = Date.now() - this.startingTime;
		// const time = delta / this.animLength;

		// const timeRange = {
		// 	start: 0,
		// 	end:   1,
		// };

		const timeRange = this.timeRange || { start: 0, end: 1 };

		timeRange.length = Math.abs(timeRange.end - timeRange.start);

		// time = this.easing(time);

		
		const absTime = delta / this.animLength,
		// const absTime = this.easing(delta / this.animLength),
			// rangeTraversed = absTime * timeRange.length,
			rangeTraversed = this.easing(absTime) * timeRange.length,
			time = 
				timeRange.start > timeRange.end ?
					timeRange.start - rangeTraversed :
					timeRange.start + rangeTraversed;

		this._applyTime(time);

		// console.log(`[AnimationUtility:_step] `, { time, absTime, rangeTraversed, timeRange  })

		if(absTime >= 1.0) { 
			this._applyTime(timeRange.end);
			this.stop({ completed: true });
			// console.log(" -- stop");
		} else {
			this._af = window.requestAnimationFrame(() => this._step());
		}
	}

	start(opts = {
		data: null,
		length: 333,
		end: null,
		// reverse: false
		// at: 1
	}) {
		// If no starting data given, 
		// clone first keyframe so animObject doesn't mudge it
		if(!opts.data) {
			opts.data = { ...this.defn[0].data }
		}

		// Set length;
		this.animLength = opts.length || 333;

		// Hack for easy reverse to start
		if(!opts.end && opts.reverse) {
			opts.end = this.defn[0].id;
		}
		
		if(opts.end !== undefined && opts.end !== null) {
			if(isNaN(opts.end)) {
				const d = this.defnIdx[opts.end];
				this.timeRange = {
					start: this.currentTime || 0,
					end: d.at,
				};

				// throw new Error(JSON.stringify(this.timeRange));
			} else {
				this.timeRange = {
					start: this.currentTime,
					end: opts.end,
				}
				// throw new Error(JSON.stringify(this.timeRange));

			}
		} else
		if(opts.range) {
			this.timeRange = opts.range;
			// throw new Error(JSON.stringify(this.timeRange));

		}
		else {
			this.timeRange = {
				start: this.currentTime || 0,
				end: opts.at !== undefined && opts.at !== null ? opts.at : 1
			}
			// throw new Error(JSON.stringify(this.timeRange));

		}

		// console.trace("starting again with range:", this.timeRange);

		// Clone the data to preserve the values
		this.startingData = {...opts.data};

		// Reuse the actual reference incase it's a PIXI object
		this.animObject = opts.data;

		// Start the timer
		// this.startingTime = Date.now();
		this.startingTime = null; // set first time in _step incase start delayed by OS
		// const fpsTime = 1000 / 60;
		// this.tid = setInterval(() => this._step(), fpsTime);
		this._af = window.requestAnimationFrame(() => this._step());

		// Create the promise so they can await
		this.promise = defer();
		this.promise.applyTo = object => this.animObject = object;

		return this.promise;
	}

	// Both manual and automatic stopping hits this
	stop({ completed } = {}) {
		clearInterval(this.tid);
		window.cancelAnimationFrame(this._af);
		this.tid = null;
		this._af = null;
		if(this.promise) {
			this.promise.resolve({ isCompleted: !!completed, isCanceled: !completed });
			this.promise = null;
		}
	}

	isRunning() {
		return this.tid || this._af;
	}
}