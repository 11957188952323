import React  from 'react';
import TWEEN  from '@tweenjs/tween.js';
import Hammer from 'hammerjs';

// import bg_img_settings     from 'assets/simplebackgrounds/burst/burst_background_large_screen_purple.png';
// import bg_img_friends      from 'assets/simplebackgrounds/burst/burst_background_large_screen_orange.png';
import { ServerStore }     from 'utils/ServerStore';
import { PixiUtils }       from 'utils/PixiUtils';
import { buttonHaptic }    from 'utils/ButtonHaptic';
import {setStatusBarColor} from 'utils/MobileStatusBarColor';
import SoundManager        from 'utils/SoundManager';

import './welcome-widget.scss';

import { FriendsWidget }  from './FriendsWidget';
import { SettingsWidget } from './SettingsWidget';
// import { LevelsList }   from './LevelsList';
import { PlayMenu   }     from './PlayMenu';
import { HomeNavBar }     from './HomeNavBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes }         from '@fortawesome/free-solid-svg-icons';

export class WelcomeWidget extends React.Component {
	state = {
		showCloseAdButton: false
	};
	
	setupScroller(el) {
		this.el = el;
		
		window.scrollerEl = el;
		window.widget = this;
		
		if(!el)
			return;
		
		el.addEventListener('scroll', this._scroller = () => {
			// console.log("el scrolled:", el.scrollLeft, el);

			const paneWidth = window.innerWidth,
				centerPoint = el.scrollLeft + paneWidth/2,
				leftPoint   = paneWidth,
				rightPoint  = paneWidth * 2,
				centerLeftDist  = centerPoint - leftPoint,
				centerRightDist = rightPoint - centerPoint,
				minDist = Math.min(centerLeftDist, centerRightDist),
				alpha   = minDist / paneWidth * 2;


			let currentPane;
			if(alpha < .33) {
				if(centerLeftDist < centerRightDist) {
					currentPane = 'settings';
				} else {
					currentPane = 'friends';
				}
			} else {
				currentPane = 'game';
			}

			this.setCurrentPane(currentPane);

			if(this.disableAlphaHelper)
				return;

			// if(currentPane === 'game')
			if(currentPane === 'friends')
				this.props.alphaHelper(alpha);
			else
				this.props.alphaHelper(1);

			if(!this.setupMode)
				this.hideLevelsList();

			if(this.disableSnap)
				return;

			clearTimeout(this.snapTid);
			this.snapTid = setTimeout(() => {
				if(this.el && this.el.scrollLeft !== this[this.currentPane+'Pane'].offsetLeft)
					this.showPane(this.currentPane, {scroll:true});
			}, 200);
		});

		if(this.gamePane) {
			this.setupMode = true;
			el.scrollLeft = this.gamePane.offsetLeft;
			setTimeout(() => this.setupMode = false, 500);
		}
	}

	setCurrentPane(pane) {
		const paneColors = {
			friends:  '#FF8122', // orange
			game:     '#16B6B6', // blue
			settings: '#A21AC4', // purple
		};
		// We removed other backgrounds to save memory,
		// so use one header color to rule them all
		setStatusBarColor(paneColors.game);

		if(this.currentPane !== pane) {
			// setStatusBarColor(paneColors[pane]);
			
			this.currentPane = pane;

			if (this.navRef)
				this.navRef.setCurrentPane(pane);

			// if(pane !== 'game')
			if(pane === 'friends')
				this.props.modeHelper('none');
			else
			if(pane === 'settings')
				this.props.modeHelper(true);
			else
				this.props.modeHelper(false);
			// else
			// 	this.props.modeHelper('welcome');
		}
	}

	adjustPaneWidth(el) {
		if(!el)
			return;

		el.style.setProperty('--pane-width', window.innerWidth+'px');
		this.scrollTo(this.gamePane.offsetLeft);
		this.cssVarRoot = el;

		// throw new Error("Sample error");

		// Give the scrollTo time to finish
		setTimeout(() => {
			if(el)
				el.style.opacity = 1;

			// just for debugging
			// this.showLevelsList();
		}, 200);
	}

	_updatePanelWidth() {

		// TODO: This resets scrolling on window resizes, not good on mobile when keyboard opens

		// if(!this.cssVarRoot)
		// 	return;
			
		// this.cssVarRoot.style.opacity = 0;
		// this.adjustPaneWidth(this.cssVarRoot);
	}
	
	scrollTo(left) {
		if(!this.el)
			return;

		this.disableSnap = true;
		const value = { left: this.el.scrollLeft };

		if ((this.currentPane === 'settings' && left > window.innerWidth) ||
			(this.currentPane === 'friends'  && left < window.innerWidth))
			this.disableAlphaHelper = true;

		new TWEEN.Tween(value)
			.to({ left }, 150)
			.onUpdate(() => this.el && (this.el.scrollLeft = value.left))
			.onComplete(() => this.disableSnap = this.disableAlphaHelper = false)
			.start();
		
		PixiUtils.touchTweenLoop();
	}

	componentWillUnmount() {
		this.el.removeEventListener('scroll', this._scroller);
		window.removeEventListener('resize', this._resizer);
		WelcomeWidget.handle = null;
	}

	componentDidMount() {
		window.addEventListener('resize', this._resizer = () => this._updatePanelWidth());

		ServerStore.metric("game.home_screen.shown");
		
		const ext = this.props.externalOptions || {};
		this.externalOptions = ext;
		if(ext.fromResult)
			this.showLevelsList();

		// console.log("[WelcomeWidget] externalOptions=", ext)

		// Just while developing
		if(false) {
			this.showLevelsList();
			console.warn("[debug] calling showLevelsList just for debugging new layout")
		}
		else
		if(false) {
			this.showPane('settings')
			console.warn("[debug] calling showPane('settings') just for debugging new layout")
		}

		if (this.props.handle)
			this.props.handle(this);

		WelcomeWidget.handle = this;
	}

	showCloseAdButton() {
		this.setState({ 
			showCloseAdButton: true
		});
	}

	hideCloseAdButton() {
		this.setState({ 
			showCloseAdButton: false
		});
	}

	showPane(name, srcData={button:false,scroll:false,swipe:false}) {
		const pane = this[name+'Pane'];
		if(!pane)
			return;

		// console.log("[showPane] ", { name, srcData, isActive: this.playMenuHandle.isActive });

		const offset = pane.offsetLeft;

		// 16px ~= 1rem, this is necessary because there are subpixel rounding errors
		// especially on mobile that prevent exact scrollLeft===offset matching
		if(!srcData.scroll && Math.abs(this.el.scrollLeft - offset) < 16 && name === 'game') {
			// SoundManager.use(SoundManager.BTN1).setVolumeModifier(0.5).play();
			if(srcData.button && this.playMenuHandle.isActive) {
				ServerStore.metric("game.home_screen.start_game.play_double_tap");
				this.props.startGame();
			} else {
				ServerStore.metric("game.home_screen.toggle_play_menu.play_button");
				this.togglePlayMenu();
			}
		} else {
			this.scrollTo(offset);
		}

		if(srcData.button || srcData.swipe) {
			buttonHaptic();
			SoundManager.use(SoundManager.BTN2).setVolumeModifier(0.5).play();

			ServerStore.metric("game.home_screen.panel_activated." + ( srcData.button ? 'clicked' : 'swiped' ) + "." + name);
		}

		if(name !== 'game') {
			this.hideLevelsList();
		}

		// console.log("show pane "+ name+", pane=", pane,", offset:", offset);
	}

	setPlayMenuHandle(handle) {
		this.playMenuHandle = handle;
		handle.onActiveChange = flag => this.props.modeHelper(this.currentPane === 'game' ? flag : 'none');
	}

	showLevelsList() {
		if (this.playMenuHandle) {
			// console.log("[WelcomeWidget.showLevelsList] got handle, setActive");
			this.playMenuHandle.setActive(true, this.externalOptions);
		}
		else {
			// console.log("[WelcomeWidget.showLevelsList] no handle, _playMenuShowPending");
			this._playMenuShowPending = true;
		}
	}

	hideLevelsList() {
		if (this.playMenuHandle && this.playMenuHandle.isActive)
			this.playMenuHandle.setActive(false);
	}

	togglePlayMenu() {
		// console.log("[togglePlayMenu] ", this.playMenuHandle)
		if (this.playMenuHandle)
			this.playMenuHandle.toggleActive();
	}

	setGamePane(el) {
		this.gamePane = el;
		if(!el)
			return;

		const mc  = new Hammer.Manager(el),
			Swipe = new Hammer.Swipe({
				direction: Hammer.DIRECTION_DOWN | Hammer.DIRECTION_UP
			});
			
		mc.add(Swipe);
		mc.on('swipedown', () => {
			this.playMenuHandle.setActive(false);
		});
		mc.on('swipeup', () => {
			this.playMenuHandle.setActive(true);
		});
	}

	render() {
		return <>
			<div id="welcome-widget" ref={el => this.adjustPaneWidth(el)}>
				<div className="welcome-scroller" ref={el => this.setupScroller(el)}>
					{/* Left screen */}
					<div className="pane settings-pane" 
						ref={el => this.settingsPane = el}
						// style={{ backgroundImage: 'url(' + bg_img_settings +')' }}
					>
						<div className="inner">
							<SettingsWidget/>
						</div>
					</div>

					{/* Center/default - rendered using PIXI text and cat actor, above */}
					<div className="pane center-pane" ref={el => this.setGamePane(el)} onClick={() => {
						// SoundManager.use(SoundManager.BTN1).setVolumeModifier(0.5).play();
						this.togglePlayMenu();
					}}>
						{/* <LevelsList
							actor={this.props.actor}
							playMenuHandle={playMenuHandle => this.playMenuHandle = playMenuHandle}
							closeSelf={() => this.togglePlayMenu()}
							welcomeWidget={this}/> */}

						<PlayMenu
							actor={this.props.actor}
							handle={handle => this.setPlayMenuHandle(handle)}
							closeSelf={() => this.togglePlayMenu()}
							welcomeWidget={this}/>
					</div>

					{/* Left screen */}
					<div className="pane friends-pane" 
						ref={el => this.friendsPane = el}
						// style={{ backgroundImage: 'url(' + bg_img_friends +')' }}
					>
						<div className="inner">
							<FriendsWidget/>
						</div>
					</div>
				</div>

				{/* HomeNavBar is in it's own widget because this component can't do state updates
				without messing up the scrolling functionality */}
				<HomeNavBar
					navRef={navRef => this.navRef = navRef}
					welcomeWidget={this}
				/>

				{/* onAdDismiss is handled in MarketUtils because it also is *supposedly* emitted by the cordova admob plugin,
				but in practice, only seems to be emitted if user clicks ad then "clicks back" */}
				{this.state.showCloseAdButton &&
					<div className='close-ads-button-overlay' onClick={() =>
						document.dispatchEvent(new Event('onAdDismiss'))
					}>
						<FontAwesomeIcon icon={faTimes}/>
					</div>
				}
			</div>
		</>;
	}
}