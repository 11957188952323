import React from 'react';
import * as PIXI from 'pixi.js-legacy';
// import { PixiUtils } from '../../utils/PixiUtils';
import Scene from '../Scene';

import { KittyActor, KITTY_ITEMS } from '../../KittyActor';

import { DefaultTextStyles as TextStyles } from '../../../utils/TextStyles';
import { LoaderUtil } from '../../../utils/LoaderUtil';

import bg_img_center   from '../../../assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';

import { PixiUtils } from '../../../utils/PixiUtils';
// import bg_img from '../../assets/simplebackgrounds/bubbles/bubble_bg_large_screen_blue.png';
// import bg_img from '../../assets/simplebackgrounds/wave/wave_bg_large_screen_blue.png';

import { ServerStore } from '../../../utils/ServerStore';
import SoundManager from '../../../utils/SoundManager';

import { WelcomeWidget } from './widgets/WelcomeWidget';
// import ServerUtil from '../../../utils/ServerUtil';
import ShopPopup from '../KittyFlySleep/ShopPopup';

import LevelEndOverlay from '../KittyFlySleep/LevelEndOverlay';
import { MarketUtils } from 'utils/MarketUtils';
import { AdMobUtil } from 'utils/AdMob';

export default class WelcomeScene extends Scene {
	userFirstName() {
		const { name } = ServerStore.currentUser;
		const [ firstName ] = (name + "").split(/\s+/);
		if(firstName) {
			return firstName;
		} else {
			return "there";
		}
	}

	setWidgetHandle(handle) {
		const req = this.externalOptions.notify;
		if(req) {
			if(req.includes("notifyFriend")) {
				handle.showPane('friends');
			}
		}
		this.widgetHandle = handle;

		// If user hasn't purchased sub_no_ads, show banner
		if(!this.actor.itemAmount(KITTY_ITEMS.sub_no_ads)) {
			AdMobUtil.showBanner();
		}

		// Only show close button if ad ACTUALLY present, not just if we don't have a subscription
		document.addEventListener('onAdLoaded', this._onAdLoadedHandler = event => {
			// https://github.com/floatinghotpot/cordova-admob-pro/wiki/1.5-Events
			if(event && event.adType === 'adType') {
				handle.showCloseAdButton();
			}
		});			
	}

	async init(externalOptions={fromResult: null}) {
		const game = this.game;

		if(game.pushService.enableBackgroundClickAcceptance()) {
			console.log("[WelcomeScene] not booting because pending background alert click");
			return;
		}
		
		// Don't auto-tune fps in welcome scene...
		game.fpsAutoTuner.stop();
		game.setFpsTarget(60);

		this.externalOptions = externalOptions;

		const { level, levelState, worldState } = await ServerStore.getLevelState();
		this.currentLevelInfo = { level, levelState, worldState };
		
		// Going to try leaving this disabled in production
		// // just for debugging right now
		if(true) {
			WelcomeScene.disableAutoStart = true;
			// console.warn("[WelcomeScene] disabling autostart just for debugging");
		} else {
			console.warn("[WelcomeScene] ENABLING autostart just for debugging");
		}

		if(externalOptions.notify) {
			// Clicked a notification, don't go right into game
			WelcomeScene.disableAutoStart = true;
			
			// .notify is actually handled in setWidgetHandle, just logged here
			console.log("[WelcomeScene] Received notification:", externalOptions);
		}

		// Until tutorial level done, go right in
		// Suggestion from Brian P - show welcome screen before tutorial. I happen to agree...
		// Maybe show some sort of special pre-tut welcome...?
		// if(!WelcomeScene.disableAutoStart && level.bg === 'tutorial' && !levelState.isCompleted) {
		// 	return game.setScene('KittyFlySleep');
		// }

		// If resuming game from cold start, go right into any active levels
		if(!WelcomeScene.disableAutoStart && levelState && levelState.isStarted && !levelState.isCompleted) {
			return game.setScene('KittyFlySleep');
		}

		// Nex time thru welcome menu, don't go right into level
		WelcomeScene.disableAutoStart = false;

		this.loadResources({
			bg_img_center
		}).then(async resources => {

			LoaderUtil.loaded();

			const unitSize = 128;

			// Add background texture first
			const bgSprite = new game.PIXI.Sprite(resources.bg_img_center.texture);
			if(true) {
				bgSprite.anchor.x = 0.5;
				bgSprite.anchor.y = 0.5;

				// sprite.tint = 0x00c000;

				bgSprite.x = window.innerWidth / 2;
				bgSprite.y = window.innerHeight / 2;

				this.addObject(bgSprite, this.game.gameContainer);

				game.app.ticker.add(this._tick = (time) => {
					bgSprite.rotation += 0.001;
				});

				bgSprite._layout = () => {
					bgSprite.x = window.innerWidth / 2;
					bgSprite.y = window.innerHeight / 2;

					const scale = 
						Math.max(window.innerWidth, window.innerHeight) 
						/ Math.min(bgSprite.texture.orig.width, bgSprite.texture.orig.height)
						* 2; // ensure coverage of corners as we rotate

					bgSprite.scale = new PIXI.Point(scale, scale);
				};
				bgSprite._layout();
			}

			// Create the kitty actor
			const actor = new KittyActor({
				scale: .75,
				// Set to static, not "in game" mode basically
				isStatic: true,
			});

			// Declare here outside of _layout for relative text layout 
			const actorInitialY = window.innerHeight / 2 - unitSize * .85;
			
			if(true) {
				// Prevent matter from messing with this since this is just a welcome screen decoration
				actor.obj.matterRemove();

				actor._layout = (animate=true) => {
					if(actor.welcomeMode === 'levels') {
						// was .5 scale and 1.625 y mult
						const targetScale = .825/*, xMin = unitSize * targetScale * 1.2*/;
						PixiUtils.tweenXY(actor.obj, actor.obj.scale, {x: targetScale, y: targetScale});
						PixiUtils.tweenXY(actor.obj, actor.obj.position, { 
							// x: Math.max(window.innerWidth/2 - 576/2 + xMin, xMin),
							x: window.innerWidth/2, 
							y: unitSize * targetScale * 1.33, //* Math.PI / 2, //1.625,
						}, 'position')
						// actor.scale = targetScale;

						// actor.obj.x = window.innerWidth / 4;
						// actor.obj.y = window.innerHeight / 4 - unitSize * .85;
						
					} else {
						const pos = { 
							x: window.innerWidth  / 2,
							y: actorInitialY
						};
						if(!animate) {
							actor.obj.x = pos.x;
							actor.obj.y = pos.y;
							return;
						}
						PixiUtils.tweenXY(actor.obj, actor.obj.scale, {x:1, y:1});
						PixiUtils.tweenXY(actor.obj, actor.obj.position, pos, 'position')
						// actor.scale = 1;
						// actor.obj.x = window.innerWidth / 2;
						// actor.obj.y = window.innerHeight / 2 - unitSize * .85;
						// actor.obj.alpha = this.anims._isLoaded ? 1:0;
					}
				};
				actor._layout();
			
				actor.onLoad = () => {
					// Some properties can only be set after the actor finishes it's own resource loading
					// actor.sprites.kitty_eyes_closed.scale.x = .6;
					// actor.sprites.kitty_eyes_closed.scale.x =  .7;
					// actor.sprites.kitty_eyes_closed.scale.y = 1.1;
					
					// Not normally setup right now to reduce CPU, but show it on welcome screen for flare
					// actor.breathFocus = [0.42, .85];
					// actor.setupBreathAnim();
					// Note: not working right now - obj.filters not working...?

					const bg = (level ||{}).bg || '';

					if(bg === 'water')
						actor.setUnderwaterMode(true, 3);
					else
					if(bg === 'stars')
						actor.setSpaceMode(true);
					
					setTimeout(() => {

						const bootEffects = () => {
							// Store sparkle effects for use in setCenterUiMode
							(this.actor.sparkleEffects = (withSound=true) => {
								actor.setSleeping(true);

								// Show heats first time
								actor.anims.heart.play();

								// Sparkle the first time
								actor.anims.sparkle.play();

								if(withSound) {
									SoundManager.use(SoundManager.POSITIVE).setVolumeModifier(0.5).play();
									// SoundManager.play(SoundManager.POSITIVE);
								}
							})();

							// Show hearts every ~10 sec
							this.heartInterval = setInterval(() => {
								actor.anims.heart.play();
							}, 10000);

							// Show sparkle every ~7 sec
							this.sparkleInterval = setInterval(() => {
								actor.anims.sparkle.play();
							}, 7000);
						};

						// If slow network, not all assets may be loaded yet,
						// so wait on actor to notify secondary assets completed loading
						if(actor.anims._isLoaded)
							bootEffects();
						else
							actor.onSecondaryLoad = bootEffects;
					}, 500);
					
					actor._layout(false);
					this.welcomeText._layout();

					PixiUtils.fadeIn(actor.obj);
					
					this.welcomeText.text = this.welcomeText.textAfterLoad();
					actor.db.on('patched', this._actorNameChangeListener = data => {
						if(data.name)
							this.welcomeText.text = this.welcomeText.textAfterLoad();
					});
				};
				actor.obj.alpha = 0;
				this.actor = actor;
				this.addObject(actor.obj, this.game.gameContainer);
			}


			const { isAnonymous } = ServerStore.currentUser || {};
			const welcomeText = new PIXI.Text(
				(isAnonymous ? "" : "Hey " + this.userFirstName() + "! ") +
				"Almost ready ....", {
					...TextStyles, 
					fontSize: 32, 
					align: "center", 
					wordWrap: true, 
					wordWrapWidth: window.innerWidth * 0.85,
					lineHeight: 28,
				});
			this.addObject(welcomeText, this.game.gameContainer);

			// Function instead of active text so that when the name is edited in settings, we can re-render
			welcomeText.textAfterLoad = () => {
				const catName  = ServerStore.currentCat.name,
					secondParagraph = 'Help ' + catName + ' collect stars and get to the flag to finish each level';

				if(ServerStore.currentUser.isAnonymous || this.userFirstName() === 'null') {
					return 'This is ' + catName + '!\n\n' + secondParagraph;
				}
				
				return "Hey " + this.userFirstName() + ', this is ' + catName + '!\n\n' + secondParagraph;
			};

			welcomeText.anchor.x = 0.5;
			welcomeText.anchor.y = 0;
			welcomeText._layout = () => {
				// the __x/__y are used for tweening for levels
				welcomeText.__x = welcomeText.x = window.innerWidth / 2;
				welcomeText.__y = welcomeText.y = actorInitialY + unitSize; //this.actor.sprite.height * 2; //window.innerHeight / 2;
			}; 
			welcomeText._layout();

			// Re-layout these things when window size changes
			const itemsNeedLayoutOnResize = [
				actor,
				bgSprite,
				welcomeText
			];

			window.addEventListener('resize', this._resizeHandler = () => {
				itemsNeedLayoutOnResize.forEach(item => item._layout(false));
			});

			// Just for debugging
			window.kitty = actor;

			// Store for scroll fading by react UI
			this.welcomeText = welcomeText;
		
			// If overlay active (from previous level), hide overlay
			if(LevelEndOverlay.handle)
				await LevelEndOverlay.handle.requestClose();

			this.game.setReactOverlay(
				<WelcomeWidget
					handle={handle => this.setWidgetHandle(handle)}
					actor={actor} 
					alphaHelper={alpha => this.setCenterUiAlpha(alpha)}
					modeHelper={mode => this.setCenterUiMode(mode === 'none' ? 'none' :  (mode ? 'levels' : 'welcome'))}
					externalOptions={externalOptions}
					startGame={resetLevel => this.startGame(resetLevel)}
				/>
			);

			this.game.pushService.ifPermissionNeeded(async () => {
				ServerStore.metric("game.push_service.perm_request_game_ui.requested");
				if((await window.AlertManager.fire({
					title: ServerStore.currentCat.name + ' wants to notify you!',
					text:  `When you get friend requests, new high scores, or your kitty wants attention, ${ServerStore.currentCat.name} wants to be able to send you push notifications. Is that okay?`,
					type:  'info',
					showCancelButton:  true,
					confirmButtonText: `Let ${ServerStore.currentCat.name} Send Notifications!`,
					cancelButtonText:  'No thanks',
					// reverseButtons:    true
				})).value) {
					ServerStore.metric("game.push_service.perm_request_game_ui.success");
					return true;
				} else {
					ServerStore.metric("game.push_service.perm_request_game_ui.rejected");
					return false;
				}
			});

		})
	}

	startGame(resetLevel, extra={}) {
		const worldState = this.currentLevelInfo.worldState || {};

		console.log("[startGame]", this.currentLevelInfo); //{ level, worldState });
		// alert("console")
		if(!worldState.isUnlocked && this.currentLevelInfo.level.world.bg !== 'tutorial')
			return this.unlockWorld();

		SoundManager.play(SoundManager.START2);
		if (ShopPopup.activeHandle)
			ShopPopup.activeHandle.closeAction();
			
		this.game.setScene('KittyFlySleep', { resetLevel, ...extra });
	}

	async unlockWorld() {
		const { level } = this.currentLevelInfo;
		if(!level || !level.world) {
			throw new Error("No this.currentLevelInfo.level.world");
		}

		const result = await MarketUtils.unlockWorld(level.world);
		if(result) {
			this.startGame(false, { levelId: result });
		} 
	}

	setCenterUiAlpha(alpha, animate) {
		// if(this.centerUiMode === 'levels')
		// 	return;

		const { actor, welcomeText } = this;
		if(animate) {
			PixiUtils.fadeAlpha(actor.obj, actor.obj.alpha, alpha);
			if(this.centerUiMode !== 'levels')
				PixiUtils.fadeIn(welcomeText, welcomeText.alpha, alpha);
		} else {
			actor.obj.alpha = alpha;
			if(this.centerUiMode !== 'levels')
				welcomeText.alpha = alpha;
		}
	}

	setCenterUiMode(mode='welcome') {
		const { actor, welcomeText } = this;
		this.centerUiMode = mode;
		
		actor.setSleeping(false);
		setTimeout(() => actor.sparkleEffects && actor.sparkleEffects(false), 1250);

		if (this._textFade)
			this._textFade.canceled = true;

		if (this._actorFade)
			this._actorFade.canceled = true;

		// if (this._textAnim)
		// 	this._textAnim.canceled = true;

		if(mode === 'levels') {
			actor.welcomeMode = mode;
			actor._layout();
			this._actorFade = PixiUtils.fadeAlpha(actor.obj, actor.obj.alpha, 1);
			// PixiUtils.fadeAlpha(actor.obj, actor.obj.alpha, 0);
			this._textFade = PixiUtils.fadeAlpha(welcomeText, welcomeText.alpha, 0);
			this._textAnim = PixiUtils.tweenXY(welcomeText, welcomeText.position, { 
				x: welcomeText.x, 
				y: welcomeText.__y + 256
			}, 'position')			
		} else
		if(mode === 'welcome') {
			actor.welcomeMode = mode;
			actor._layout();
			this._actorFade =PixiUtils.fadeAlpha(actor.obj, actor.obj.alpha, 1);
			if (ShopPopup.activeHandle)
				ShopPopup.activeHandle.closeAction();
			// PixiUtils.fadeAlpha(actor.obj, actor.obj.alpha, 1);
			this._textFade = PixiUtils.fadeAlpha(welcomeText, welcomeText.alpha, 1);
			this._textAnim = PixiUtils.tweenXY(welcomeText, welcomeText.position, { 
				x: welcomeText.x, 
				y: welcomeText.__y
			}, 'position')
		} else 
		if(mode === 'none') {
			this._textFade = PixiUtils.fadeAlpha(welcomeText, welcomeText.alpha, 0);
			this._actorFade = PixiUtils.fadeAlpha(actor.obj, actor.obj.alpha, 0);
		} else {
			throw new Error("Invalid mode");
		}
	}

	destroy() {
		// if(!this.actor.itemAmount(KITTY_ITEMS.sub_no_ads)) {
			AdMobUtil.hideBanner();
		// }

		if(this._onAdLoadedHandler)
			document.removeEventListener('onAdLoaded', this._onAdLoadedHandler);

		// We stopped it, so we must start it
		this.game.fpsAutoTuner.start();

		window.removeEventListener("resize", this._resizeHandler);
		this.game.app.ticker.remove(this._tick);
		if (this.actor) {
			if(this._actorNameChangeListener)
				this.actor.off('patched', this._actorNameChangeListener);
			this.actor.destroy(); // doesn't seem to get destroyed properly....
		}
		clearInterval(this.heartInterval);
		clearInterval(this.sparkleInterval);
		super.destroy();
	}
}

