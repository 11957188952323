let seqNum = 0;
const MarketItemList = {
	// The rest of these items get offered in market list and shopwidget
	tiara: {
		seqNum: ++seqNum,
		name: 'Tiara',
		starCost:       500,
		dollarCost:     2.99,
		iapId: 'sassybox.big_fancy', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	bowtie: {
		seqNum: ++seqNum,
		name: 'Bowtie',
		starCost:       100,
		dollarCost:     .99,
		iapId: 'sassybox.small_fancy', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	cowbell: {
		seqNum: ++seqNum,
		name: 'More Cowbell',
		// starCost:       1337,
		// dollarCost:     2.99,

		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:       2,
		maxItems:       5,
		starCost:       1337,
		dollarCost:    .99,
		dollarUnitSize: 5,

		// iapId: 'sassybox.big_fancy', // NB ALL 'iapId's MUST BE SAME dollarCost
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	lasers: {
		seqNum: ++seqNum,
		name: 'Lasers',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:       10,
		maxItems:       50,
		starCost:       15,
		dollarCost:    .99,
		dollarUnitSize: 25,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},
	explosion: {
		seqNum: ++seqNum,
		name: 'Explosion',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        1,
		maxItems:       10,
		starCost:       50,
		dollarCost:    .99,
		dollarUnitSize:  5,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},
	implosion: {
		seqNum: ++seqNum,
		name: 'Implosion',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        1,
		maxItems:        5,
		starCost:       50,
		dollarCost:    .99,
		dollarUnitSize:  5,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},
	shield: {
		seqNum: ++seqNum,
		name: 'Shield',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        2,
		maxItems:       20,
		starCost:      100,
		dollarCost:    .99,
		dollarUnitSize: 10,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},
	go_big: {
		seqNum: ++seqNum,
		name: 'Super-Sizer',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        2,
		maxItems:        5,
		starCost:      500,
		dollarCost:    .99,
		dollarUnitSize:  5,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	floater: {
		seqNum: ++seqNum,
		name: 'Anti-Gravity',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        1,
		maxItems:       10,
		starCost:      100,
		dollarCost:    .99,
		dollarUnitSize:  3,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	speed_boost: {
		seqNum: ++seqNum,
		name: 'Speed Boost',
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        2,
		maxItems:       10,
		starCost:       50,
		dollarCost:    .99,
		dollarUnitSize: 10,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	grenade: {
		seqNum: ++seqNum,
		name: "Holy Hand\nGrenade",
		isConsumable: true, // if isConsume, user can-repurchase and IAP must notify provider of consumption
		unitSize:        2,
		maxItems:       25,
		starCost:      100,
		dollarCost:    .99,
		dollarUnitSize: 10,
		iapId: 'sassybox.booster', // NB ALL 'iapId's MUST BE SAME dollarCost
	},

	sub_no_ads: {
		seqNum: 999,
		
		// iapId: 'sassybox.sub.no_ads',// NB ALL 'iapId's MUST BE SAME dollarCost
		iapId: 'sassybox.no_ads_forever', 

		// Displayed as name of "item" in ShopWidget/PlayMenu, 
		// but actual purchase popup text is config'd in KittyActor
		// Text that appears in IAP config'd via Google Play Console/Apple iTunes
		name:  'Play Without Ads',

		// Not used in actual purchasing (since IAP is config'd via Google Play/Apple iTunes)
		// but this is displayed in the UI in ShopWidget/PlayMenu
		dollarCost:  .99,
		// dollarCost:  1.99,
		// period: 'mo',
		// isSubscription: true,
		starCost: -1, // when using purchaseItem(), don't offer stars
	},
};

Object.keys(MarketItemList).forEach(itemId => {
	const def = MarketItemList[itemId];
	// Set the .id prop automatically so we can guarantee it's the same id as it's corresponding key in KITTY_ITEMS
	def.id = itemId;

	if(!def.unitSize)
		def.unitSize = 1;
	if(!def.maxItems)
		def.maxItems = 1;
});

module.exports = MarketItemList;