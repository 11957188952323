import React from 'react';
import Hammer from 'hammerjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faUserFriends, faGamepad, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { PENDING_COUNT_CHANGE_EVENT } from './FriendsWidget';

import { ServerStore } from 'utils/ServerStore';
import { mobileDetect } from 'utils/mobile-detect';

import './home-navbar.scss';

export class HomeNavBar extends React.Component {
	static handle = null;

	constructor(props) {
		super(props);

		this.state = {
			currentPane: null,
			pending: 0,
		};

		this.updatePendingCount();

		this.constructor.handle = this;

		ServerStore.on(PENDING_COUNT_CHANGE_EVENT, this._pendingHandler = () => this.updatePendingCount());
	}

	componentWillUnmount() {
		this.unmounted = true;
		HomeNavBar.handle = null;
		ServerStore.off(PENDING_COUNT_CHANGE_EVENT, this._pendingHandler);
	}

	updatePendingCount() {
		ServerStore.server().get('/friends/pending_count', null, { autoRetry: true })
			.then(result => !this.unmounted && this.setState(result)); // result is {pending: Number}
	}

	componentDidMount() {
		if (this.props.navRef)
			this.props.navRef(this);
	}

	setCurrentPane(pane) {
		!this.unmounted && this.setState({ currentPane: pane });
	}

	showPane(pane, data) {
		this.props.welcomeWidget.showPane(pane, data);
	}

	setPlayBtn(el) {
		if(!el)
			return;
	
		const mc  = new Hammer.Manager(el),
			Swipe = new Hammer.Swipe({
				direction: Hammer.DIRECTION_DOWN | Hammer.DIRECTION_UP
			});
			
		mc.add(Swipe);
		mc.on('swipedown swipeup', () => {
			this.showPane('game', {swipe:true});
		});
	}

	static showBackBtn(cb) {
		if (this.handle) {
			this.handle.backBtnCb = cb;
			this.handle.setState({ showBackBtn: true });
		}
	}

	static hideBackBtn() {
		if (this.handle) {
			this.handle.backBtnCb = null;
			this.handle.setState({ showBackBtn: false });
		}
	}

	backBtnClicked() {
		if (this.backBtnCb)
			this.backBtnCb();

		HomeNavBar.hideBackBtn();
	}

	render() {
		return <div className={
				(mobileDetect.hasNotch ? "--has-notch " : "") + 
				"welcome-nav" + 
				(this.state.showBackBtn ? " --back-btn-visible" : "")
			}>

			<div className="inner --back">
				<abbr onClick={() => this.backBtnClicked()}>
					<FontAwesomeIcon icon={faArrowLeft} />
					{/* <label>Back</label> */}
				</abbr>
			</div>
			
			<div className="inner --main">
				<abbr onClick={() => this.showPane('settings', {button:true})} className={this.state.currentPane === "settings" ? 'active' : ''}>
					<FontAwesomeIcon icon={faSlidersH} />
					<label>Settings</label>
				</abbr>
				<abbr onClick={() => this.showPane('game', {button:true})} className={this.state.currentPane === "game" ? 'active' : ''} 
					ref={el => this.setPlayBtn(el)}>
					<FontAwesomeIcon icon={faGamepad} />
					<label>Play</label>
				</abbr>
				<abbr onClick={() => this.showPane('friends', {button:true})} className={this.state.currentPane === "friends" ? 'active' : ''}>
					<span className='icon-wrap'>
						<FontAwesomeIcon icon={faUserFriends} />
						{this.state.pending > 0 ? 
							<span className="badge" title={this.state.pending + " New Friend Requests"}>{this.state.pending}</span>
							:<></>
						}
					</span>
					<label>Friends</label>
				</abbr>
			</div>
		</div>	;
	}

};

