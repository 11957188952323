
import BasicBorderedScene from './BasicBorderedScene';

import * as PIXI from 'pixi.js-legacy';
import { PixiUtils } from '../../utils/PixiUtils';

import pbg_sun from '../../assets/parallaxingskybg/parallax_parts/sun.png';
import face_red from '../../assets/faceballs/red/smile_big.png';
import face_blue from '../../assets/faceballs/blue/smile_big.png';
import face_green from '../../assets/faceballs/green/smile_big.png';
import face_yellow from '../../assets/faceballs/yellow/smile_big.png';

import { LoaderUtil } from '../../utils/LoaderUtil';

import { KittyActor } from '../KittyActor';
// import { BreakableBlock } from '../../utils/BreakableBlock';

export default class BasicKittyScene extends BasicBorderedScene {
	init() {
		this.setupBasicKittyScene();
	}

	setupBasicKittyScene(options={
		addSun: true,
		addKitty: true,
		addBalls: true,
		kittyScale: 1.0,
		kittyRestitution: 0.625, 
	}) {
		this.basicKittySceneOptions = options;
		return this.setupBasicBorders({
			overrideDebugRender: true,
			...options,
			otherResources: {
				pbg_sun,
				face_red,
				face_blue,
				face_green,
				face_yellow,
				...options.otherResources
			}}).then(resources => {
				
				// if(options.addSun)
				// 	this.addSun();

				if(options.addKitty)
					this.addKittyActor();
				
				if(options.addBalls)
					this.addBouncyBalls();

				// const { actor, viewport } = this;
				// // Not attached by default - will have to detach in destroy
				// viewport.follow(actor.obj); //, { radius: tileSize * 1  } );
				// viewport.removePlugin('clamp');
				// viewport.removePlugin('clampZoom');
				// viewport.clamp({ direction: 'all' });
				// viewport.clampZoom({
				// 	minWidth: 256,
				// 	minHeight: 256,
				// 	maxWidth:  viewport.worldWidth, // * 2,
				// 	maxHeight: viewport.worldHeight,// * 2,
				// });
				// // viewport.removePlugin('drag');
		
				// // physics engine sometimes lets kitty go thru walls and out of bounds - reset kitty if that happens
				// // actor.checkBounds = this._checkActorBounds.bind(this);
		
				// viewport.fit(false, window.innerWidth * 3, window.innerHeight * 3);
				// 		// viewport.fit(false, this.tileSize * 10, this.tileSize * 10);
		
				return resources;
			}).then(() => {
				LoaderUtil.loaded();
			})

	}

	addSun() {
		const { game, tileSize, scale, resources } = this;

		// Create the sun
		const sunTexture = resources.pbg_sun.texture;
		const sun = new game.PIXI.Sprite(sunTexture);
		sun.scale = new PIXI.Point( scale * 2, scale * 2 );
		sun.anchor.x = 0.5;
		sun.anchor.y = 0.5;
		sun.x = this.game.viewport.worldWidth - (sunTexture.orig.width * scale) - tileSize;
		sun.y = sunTexture.height * scale + tileSize * 3; // / 2;
		this.addObject(sun);
		this.sunSprite = sun;
		
	}

	addKittyActor(startingPosition) {
		const { tileSize, scale } = this;
		// const kittyScale = (tileSize / KittyActor.baseBlockSize) * (this.basicKittySceneOptions.kittyScale || 1.0);
		// console.log("[BasicKittyScene.addKitty]", { kittyScale, scale, opts: this.basicKittySceneOptions, _this: this });
		// Create the kitty actor
		const actor = new KittyActor({
			scale: scale * (this.basicKittySceneOptions.kittyScale || 1.0),
			physicalOptions: {
				restitution: this.basicKittySceneOptions.kittyRestitution || 0.625,
				// frictionAir: 0.001,
				// density: 0.125,
			}
		});

		actor.setPosition(actor.startingPosition = startingPosition || {
			x: tileSize * 2,
			y: tileSize * 2,
		});

		this.actor = actor;

		// Just for debugging
		window.kitty = actor;

		this.addObject(actor.obj);

		document.body.addEventListener('keydown', this._keyListener = event => {
			if(this.destroyed)
				return;
				
			// console.log(event.key, event.keyCode, event);
			// console.log(event);

			if(this.onKeyPress && this.onKeyPress(event) === false)
				return;

			if(event.keyCode === 32 || event.keyCode === 13) {
				// actor.shoot();
				if (this.shootIntercept)
					this.shootIntercept();
				// else
				// 	actor.activeItem && actor.activeItem.action(this);
			}
			else
			if(event.key === 'u' )
				actor.jump('left');
			else
			if(event.key === 'o')
				actor.jump('right');
			else
			if(event.key === 'j' || event.keyCode === 37 || event.key === 'a')
				actor.move('left');
			else
			if(event.key === 'l' || event.keyCode === 39 || event.key === 'd')
				actor.move('right');
			else
			if(event.key === 'i' || event.keyCode === 38 || event.key === 'w')
				actor.jump();
			else
			if(event.key === 'k' || event.keyCode === 40 || event.key === 's')
				actor.jump('down');
				// return; // TODO something else
		});
	}

	addBouncyBalls(factor=1) {

		const { tileSize, scale, worldTileWidth, resources } = this;

		// Load the bouncy balls
		// const blocks = [];
		const balls = 'face_red,face_blue,face_green,face_yellow'.split(',');
		let ballNum = 0;
		for(let i=0; i<worldTileWidth-3; i += 1/factor) {
			for(let j=0;j<6*factor; j++) {
				if(ballNum < 99999) {
					const block = PixiUtils.createPhysicalTexture(resources[balls[ballNum++%balls.length]].texture, 
						{ x: tileSize * i + 1, y: tileSize * 2 },
						{ scale }, {
							isStatic: false,
							restitution: 0.75,
							friction:    0.00001,
							frictionAir: 0.0001,
							density: 0.01,
							shape: 'circle',
							shapeArgs: [
								tileSize * (i + 2), tileSize * 2, tileSize * .45,
							]
						});

					// block.sprite.interactive = true;
					// block.sprite.click = block.sprite.tap = () => {
					// 	block.setVelocity({ x: (Math.random() * 100 - 25), y: (Math.random() * 100 - 25) });
					// };

					block.label = "[bouncyBall]";

					this.addObject(block);

					// blocks.push(block);
				}
			}
		}

		// const fudge = () => {
		// 	const block = blocks[Math.floor(Math.random() * blocks.length)];
		// 	if(block) {
		// 		// block.sprite.click();
		// 		block.setVelocity({ x: 10, y: 10 });
		// 	}

		// 	this.fudgeTid = setTimeout(fudge, 50);
		// };

		// fudge();
	}

	destroy() {
		if(!this.destroyed) {
			this.destroyed = true;

			super.destroy();

			this.destroyObjects();
			clearTimeout(this.fudgeTid);
			document.body.removeEventListener('keypress', this._keyListener);
			
			// Allow the actor to remove it's resources from PIXI.loader since it loads resources independent of Scene
			if (this.actor)
				this.actor.destroy();
		}
	}
}