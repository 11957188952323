import React from 'react';

import clsx from 'clsx';
import styles from './WelcomePopup.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ButtonBase } from '@material-ui/core';
import { useRemoteData } from 'utils/useRemoteData';
import { ServerStore } from 'utils/ServerStore';

import { isPhoneGap } from 'utils/isPhoneGap';
import AppStoreLinkWidget from '../AppStoreLinkWidget/AppStoreLinkWidget';

export default function WelcomePopup(props) {

	const settings = useRemoteData(() => ServerStore.GetSettings()); //, 'settingsChanged');

	let onComplete = () => {};
	if(props.handle) {
		props.handle({
			onComplete: cb => onComplete = cb
		});
	}

	const playBtnClicked = async () => {
		if(!settings.name) {
			window.Alert("Please put in a name - any name!");
		} else {
			onComplete(settings.name);
		}
	}


	const patchSettings = async (patch, options={instant: false}) => {
		if(WelcomePopup.tid) {
			clearTimeout(WelcomePopup.tid);
		}
		if(!WelcomePopup.patch) {
			WelcomePopup.patch = {};
		}
		WelcomePopup.patch = {
			...WelcomePopup.patch,
			...patch
		};

		// For testing logic
		Object.assign(settings, patch);

		if(options.instant) {
			return await ServerStore.UpdateSettings(WelcomePopup.patch);
		}
		// Use setTimeout to buffer updates
		WelcomePopup.tid = setTimeout(async () => {
			/*const res = */await ServerStore.UpdateSettings(WelcomePopup.patch);
			// console.log("[patchSettings]", res, WelcomePopup.patch);
		}, 1000);
	};

	return (<>
		<div className={styles.WelcomePopup}>
			<div className={styles.content}>
				<div className={styles.contentPadding}>
					<h1>SassyBox</h1>

					<div className={styles.prompt}>
						{/* <label>Your Name</label> */}
						<input 
							type="text" 
							className={"form-control"} 
							ref={e => {
								WelcomePopup.refName = e;
								if(e)
									setTimeout(() => e.focus(), 10);
							}}
							placeholder="Name" 
							aria-label="Name"
							defaultValue={settings.name}
							onKeyPress={e => {
								if(e.which === 13)
									playBtnClicked();
							}}
							onChange={e => patchSettings({
								name: e.target.value
							})}
						/>
						<small className={clsx("form-text", styles.hintText)}>
							Put the name you want people to see when you get the high score!
						</small>
					</div>

					<ButtonBase className={clsx(styles.smallButton, styles.greenBackground)}
						onClick={playBtnClicked}>
						Play SassyBox <FontAwesomeIcon icon={faChevronRight}/>
					</ButtonBase>

					{!isPhoneGap ? <>
						<div className={styles.appStoreLinks}>
							<AppStoreLinkWidget/>
						</div>
					</> : ""}

				</div>
			</div>
		</div>
	</>);
}


