import React from 'react';

import styles from './AppStoreLinkWidget.module.scss';

import appStoreApple from 'assets/app-store-icons/apple.png';
import appStoreGoogle from 'assets/app-store-icons/google.png';

export default function AppStoreLinkWidget() {
	return (<div className={styles.appStoreLinks}>
		<label>Play SassyBox online or install the SassyBox app</label>
		<div>
			<a href='https://apps.apple.com/us/app/sassybox/id1490895715' target='_blank' rel="noopener noreferrer">
				<img src={appStoreApple} alt="Apple App Store"/>
			</a>

			<a href='https://play.google.com/store/apps/details?id=com.josiahbryan.sassybox' target='_blank' rel="noopener noreferrer">
				<img src={appStoreGoogle} alt="Google Play Store"/>
			</a>
		</div>
	</div>);
}