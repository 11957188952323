export class LoaderUtil {
	static _el() {
		const d = window.document,
			e = d ? d.querySelector('#basic-loading-indicator') : null;
		if(!e || !e.classList)
			return null;
		return e;
	}

	static loaded() {
		const e = this._el();
		e && e.classList.add('loaded');
	}

 	static show() {
 		const e = this._el();
		e &&e.classList.remove('loaded');
 	}
}