import Scene from './Scene';

import kitty_blue from '../../assets/kitty-v1/kittyblock-blue.png';

export default class SampleBasic extends Scene {
	init() {
		const game = this.game;
		this.loadResources({ kitty_blue }).then(resources => {

			const sprite = new game.PIXI.Sprite(resources.kitty_blue.texture);

			sprite.anchor.x = 0.5;
			sprite.anchor.y = 0.5;

			sprite.x = window.innerWidth / 2;
			sprite.y = window.innerHeight / 2;

			this.addObject(sprite);

			game.app.ticker.add(this._tick = (time) => {
				sprite.rotation += 0.01;
			});
		})
	}
	destroy() {
		this.destroyObjects();
		this.game.app.ticker.remove(this._tick);
	}
}