import * as Matter from 'matter-js'

import { FpsAutoTuner } from '../utils/FpsAutoTuner';

export const COLLISION_ELEMENT_SIZE = 2;
export const UPDATE_ELEMENT_SIZE = 6;

// just for debugging
// window.Matter = Matter;

export class MatterSimulation {
	constructor() {
		this.initMatter();
	}

	_engine = null;
	initMatter() {
		const world = Matter.World.create({ gravity: {
			x: 0,
			y: 1,
			scale: 0.001,
		} });

		this._engine = Matter.Engine.create({ world });
		
		// Matter MUST be updated at 60fps
		this._updateTid = setInterval(this._updateMatter, 1000/60);

		Matter.Events.on(this._engine, 'afterUpdate', this._matter_afterUpdate);
		Matter.Events.on(this._engine, 'collisionStart', this._matter_collisionHandler);

		// We tried to use intervals to rate-limit data flowing out of our simulation
		// so as to reduce CPU usage. However, this seemed to have negligible, if any, effect.
		// Therefore, to reduce complexity, disabled the intervals for now. However,
		// should we want to try that again, just uncomment the setInterval() calls below,
		// and the respective handlers will route data accordingly

		this.fpsAutoTuner = new FpsAutoTuner({
			tuningInterval: 15000,
			debug:     false,
			debugTag:  "MatterSimulation",
			fpsTarget: 30,
			callback:  fps => this.setFpsTarget(fps),

			// document not available in web workers
			enableCordovaPausing: false, 
		});

		// this._updatePostingTimer    = setInterval(this._postUpdates,    1000/30);
		// this._collisionPostingTimer = setInterval(this._postCollisions, 1000/15);

	}

	destroy() {
		if(this.isDestroyed)
			return;

		this.isDestroyed = true;
		Matter.Events.off(this._engine, 'afterUpdate',    this._matter_afterUpdate);
		Matter.Events.off(this._engine, 'collisionStart', this._matter_collisionHandler);
		this._latestFrameRequest    && cancelAnimationFrame(this._latestFrameRequest);
		this._updateTid             && clearInterval(this._updateTid);
		this._collisionPostingTimer && clearInterval(this._collisionPostingTimer);
		this._updatePostingTimer    && clearInterval(this._updatePostingTimer);
		this.fpsAutoTuner           && this.fpsAutoTuner.destroy();
		
		this._engine = null;
	}

	_postCollisions = () => {
		if(this.collisionBuffer) {
			const elementsNeeded = this.collisionBuffer.length;
			const buffer = new ArrayBuffer(elementsNeeded * Uint16Array.BYTES_PER_ELEMENT);
			const data = new Uint16Array(buffer);
			for(let i=0; i <this.collisionBuffer.length; i++) {
				data[i] = this.collisionBuffer[i];
			}
			this.collisionBuffer = null;

			// console.warn("[collisionStart]", event, data);

			this.matterChangeCallback && this.matterChangeCallback({
				msg: 'collisionStart',
				data
			}
			// This second arg enables 'transferrable' mode in WebWorkers for the array buffer
			// See https://developer.mozilla.org/en-US/docs/Web/API/Worker/postMessage#Parameters
			// Basically, the array is passed to the window something like by a pointer, instead of copying every byte
			, [data.buffer]
			);
		}
	}

	_matter_collisionHandler = event => {
		if(this.isDestroyed || this._freezeMatter)
			return;

		// console.warn("[collisionStart]", event);

		const list = event.source.pairs.list || [];
		if(list.length > 0) {
			if(this._collisionPostingTimer) {
				if(!this.collisionBuffer) {
					this.collisionBuffer = [];
				}

				for(let i=0; i <list.length; i++) {
					const { bodyA, bodyB } = list[i];
					this.collisionBuffer.push(bodyA.__simulationId, bodyB.__simulationId);
				}

			} else {
				// const data = new Array(list.length * COLLISION_ELEMENT_SIZE);
				const elementsNeeded = list.length * COLLISION_ELEMENT_SIZE;
				const buffer = new ArrayBuffer(elementsNeeded * Uint16Array.BYTES_PER_ELEMENT);
				const data = new Uint16Array(buffer);
				for(let i=0; i <list.length; i++) {
					const { bodyA, bodyB } = list[i],
						x = i * COLLISION_ELEMENT_SIZE;
					data[x]   = bodyA.__simulationId;
					data[x+1] = bodyB.__simulationId;
				}

				// console.warn("[collisionStart]", event, data);

				this.matterChangeCallback && this.matterChangeCallback({
					msg: 'collisionStart',
					data
				}
				// This second arg enables 'transferrable' mode in WebWorkers for the array buffer
				// See https://developer.mozilla.org/en-US/docs/Web/API/Worker/postMessage#Parameters
				// Basically, the array is passed to the window something like by a pointer, instead of copying every byte
				, [data.buffer]
				);
			}
		}
	}

	_postUpdates = () => {
		this.fpsAutoTuner && this.fpsAutoTuner.countFrame();
		
		this._matter_afterUpdate({ doItNow: true })
	}

	_matter_afterUpdate = (event={ doItNow: false }) => {
		if(this._updatePostingTimer && (!event || !event.doItNow))
			return;

		// console.log(event)
		
		// const has = {};
		const bodies =  Matter.Composite.allBodies(this._engine.world)
			.filter(body => 
				// !has[body.__simulationId] &&
				// (has[body.__simulationId] = true) &&
				!body.isStatic
			);

		const elementsNeeded = bodies.length * UPDATE_ELEMENT_SIZE;
		const buffer = new ArrayBuffer(elementsNeeded * Float64Array.BYTES_PER_ELEMENT);
		const data = new Float64Array(buffer)
		// const data = []; //new Array(bodies.length * UPDATE_ELEMENT_SIZE);
		for(let i=0; i<bodies.length; i++) {
			const body = bodies[i];
			let x = i * UPDATE_ELEMENT_SIZE;
			data[  x] = body.__simulationId;
			data[++x] = body.position.x;
			data[++x] = body.position.y;
			data[++x] = body.velocity.x;
			data[++x] = body.velocity.y;
			data[++x] = body.angle;
		}

		this.matterChangeCallback && this.matterChangeCallback({
			msg: 'afterUpdate',
			data
		}
		// This second arg enables 'transferrable' mode in WebWorkers for the array buffer
		// See https://developer.mozilla.org/en-US/docs/Web/API/Worker/postMessage#Parameters
		// Basically, the array is passed to the window something like by a pointer, instead of copying every byte
		, [data.buffer]
		);
	}

	_freezeMatter = false;
	_updateMatter = () => {
		// this._latestFrameRequest = requestAnimationFrame(this._updateMatter);

		if(this._freezeMatter)
			return;
			
		const currTime = 0.001 * Date.now();
		Matter.Engine.update(
			this._engine,
			1000 / 60,
			this.__lastTime ? currTime / this.__lastTime : 1
		);
		this.__lastTime = currTime;
	}

	setFpsTarget(fps) {
		// console.log("[MatterSimulation] fps target set to:", fps, "fps");
		this.fpsTarget = fps;
		this._updatePostingTimer    && clearInterval(this._updatePostingTimer);
		this._collisionPostingTimer && clearInterval(this._collisionPostingTimer);
		this._updatePostingTimer     = setInterval(this._postUpdates,    1000/fps);
		this._collisionPostingTimer  = setInterval(this._postCollisions, 1000/(fps / 2));
	}

	processCommand(packet={cmd:"", data:{}}) {
		// console.log("[MatterSimulation.processCommand] DEBUG: Received:", packet);
		packet = JSON.parse(JSON.stringify(packet));
		
		switch(packet.cmd) {
			case "freezeMatter":
				this._freezeMatter = packet.data;
				this.fpsAutoTuner && this.fpsAutoTuner[packet.data ? "stop" : "start"]();
				break;
			case "setGravity":
				this._engine.world.gravity.x = (packet.data||{}).x || 0;
				this._engine.world.gravity.y = (packet.data||{}).y || 1;
				break;
			// case "setFps":
			// 	const fps = parseFloat(packet.data) || 30;
			// 	this.setFpsTarget(fps);
			// 	break;
			default:
				if(typeof(this[packet.cmd]) === 'function') {
					this[packet.cmd](packet);
				} else {
					console.error("MatterSimulation.processCommand: Unknown cmd: ", packet.cmd, ", received with data:", packet.data);
				}
				break;
		}
	}

	_bodyIndex = {};
	addBody(packet) {
		const { data: { 
			id, _existing, shape='rectangle', size=[0,0,100,100], options={ label: null }
		} } = packet;

		if(!id) {
			throw new Error("Must provide data.id to 'addBody' cmd so you can remove it later");
		}
		
		if(_existing) {
			const body = this._bodyIndex[id];
			if(!body)
				throw new Error("Invalid data.id given to '" + packet.cmd + "' cmd - body doesn't exist in internal index with id=" + id);
			
			// console.warn("[MatterSimulation.addBody] *EXISTING* ", options.label, { id, body, packet, args: { shape, size, options } })
			Matter.World.addBody(this._engine.world, body);
		} else {
			if(this._bodyIndex[id]) {
				throw new Error("Body already exists in the world with ID:" + id);
			}

			const body = Matter.Bodies[shape](...size, options);
			this._bodyIndex[id] = body;

			body.__simulationId = id;

			// console.warn("[MatterSimulation.addBody]", options.label, { id, body, packet, args: { shape, size, options } })
			if(!options.label) {
				console.warn("[MatterSimulation.addBody] Recommended to add label to body for easier debugging ***");
			}
			
			Matter.World.addBody(this._engine.world, body);
		}
	}


	_getBody(packet, failGracefully=false) {
		const data = packet.data || {},
			{ id } = data;
		if(!id) {
			if(failGracefully)
				return null;
			throw new Error("Must provide data.id to '" + packet.cmd + "' cmd");
		}
	
		const body = this._bodyIndex[id];
		if(!body) {
			if(failGracefully)
				return null;
			throw new Error("Invalid data.id given to '" + packet.cmd + "' cmd - body doesn't exist in internal index with id=" + id);
		}

		return { ...data, id, body };
	}

	removeBody(packet) {
		// Allow remove to fail, pass true
		const d = this._getBody(packet, true);
		if(!d)
			return;
		const { body, id, destroy } = d;
		// console.warn("[MatterSimulation.removeBody]", body.label, { id, body, packet })
		Matter.World.remove(this._engine.world, body);
		destroy && delete this._bodyIndex[id];
	}

	destroyBody(packet) {
		// Allow destroy to fail, pass true
		const d = this._getBody(packet, true);
		if(!d)
			return;
		const { body, id } = d;
		body && Matter.World.remove(this._engine.world, body);
		this._bodyIndex[id] && delete this._bodyIndex[id];
	}

	/**
	 * Set arbitrary `MatterJS` values on the underlying body
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_set}
	 * From the docs: [You should] use the actual setter functions in performance critical situations.
	 * Also, some of our `PixiMatterContainer` setters also sync the underlying PIXI object, so use setters on `PixiMatterContainer` if they exist.
	 *
	 * @param {string} data.option
	 * @param {any} data.value
	 * @memberof PixiMatterContainer
	 */
	setMatter(packet) {
		const { body, option, value } = this._getBody(packet);
		Matter.Body.set(body, option, value);
	}

	/**
	 * Calls `Matter.Body.setPosition` and sets `this.x` and `this.y` to the given position.
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setPosition} for more information.
	 *
	 * @param {object} data.position
	 * @param {number} data.position.x
	 * @param {number} data.position.y
	 * @memberof PixiMatterContainer
	 */
	setPosition(packet) {
		const { body, position } = this._getBody(packet);
		Matter.Body.setPosition(body, position);
	}
	
	/**
	 * Calls `Matter.Body.setAngle` and sets `this.rotation` to the given `angle`
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setAngle} for additional info
	 *
	 * @param {number} data.angle
	 * @memberof PixiMatterContainer
	 */
	setAngle(packet) {
		const { body, angle } = this._getBody(packet);
		Matter.Body.setAngle(body, angle);
	}

	
	/**
	 * Shortcut for `Matter.Body.setAngularVelocity` with `this.body` as the body.
	 * @param {number} data.velocity
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setAngularVelocity}
	 */
	setAngularVelocity(packet) {
		const { body, velocity } = this._getBody(packet);
		Matter.Body.setAngularVelocity(body, velocity);
	}

	/**
	 * Shortcut for `Matter.Body.setVelocity` with `this.body` as the body. ()
	 */
	setVelocity(packet) {
		const { body, velocity } = this._getBody(packet);
		Matter.Body.setVelocity(body, velocity);
	}

	/**
	 * Shortcut for `Matter.Body.setInertia` with `this.body` as the body. 
	 * @param {number} [inertia=Infinity]
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setInertia}
	 */
	setInertia(packet) {
		let { body, inertia=Infinity } = this._getBody(packet);
		if(inertia === null)
			inertia = Infinity;
		Matter.Body.setInertia(body, inertia);
	}

	/**
	 * Shortcut for `Matter.Body.applyForce` with `this.body` as the body.
	 */
	applyForce(packet) {
		const { body, a, b } = this._getBody(packet);
		Matter.Body.applyForce(body, a, b);
	}

	/**
	 * Shortcut for `Matter.Body.scale` with `this.body` as the body.
	 */
	scale(packet) {
		const { body, scaleX, scaleY, scale=1 } = this._getBody(packet);
		Matter.Body.scale(body, scaleX || scale, scaleY || scale);
	}
}